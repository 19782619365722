@import './../marso-icons/style.css';
// @import 'semantic.less';
@import '../css/semantic.css';
@import "themes/default/globals/site.variables";
@import "site/globals/site.variables";
@import '~vanilla-cookieconsent/src/cookieconsent.css';
@import '~snazzy-info-window/dist/snazzy-info-window.css'; // service-sites.twig, service-site.twig

/*
some special class
*/

.stored-wheels {
    background: white;
    width: 75%;
    margin: 15px auto;
    padding: 2rem;
    font-weight: bold;
    border-radius: 5px;
}

.wheel-dropdown {
    width: 30% !important;
    margin-left: 10px;
}

.wheel-dropdown .field-label {
    padding: 10px !important;
}

#car_id {
    //height: 50px;
}

#wheel-table {
    margin: 15px;
    padding: 1px;
}

.subbox-background {
    position: absolute;
    right: 0;
}

.handwritten {
    font-family: 'Lobster', cursive !important;
    font-weight: normal !important;
}

.invisible {
    display: none !important;
}

.ninja {
    visibility: hidden !important;
}

hr {
    border: none;
    border-top: 1px solid @marso-gray-10;
    margin: 2rem 0;
    width: 100%;
}

hr.dark {
    border-color: darken(@marso-gray-10, 10%);
}

hr.ninja {
    border: none;
    padding: 0;
}

hr.half {
    margin: 0.5rem 0 !important;
}

hr.narrow {
    margin: 1rem 0 !important;
}

hr.wide {
    margin: 3rem 0 !important;
}

hr.vertical {
    display: inline-block;
    height: 2em;
    padding: 0;
    margin: 0 1rem;
    border: 1px solid @marso-gray-20;
    vertical-align: middle;
}

hr.vertical.dot {
    border-radius: 100%;
    height: 0;
    width: 0;
    border-width: 3px;
}

.text-center {
    text-align: center;
}

.serviceDetailsBtn {
    width: 100%;
}

.ui.button.text-left,
.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-nowrap {
    white-space: nowrap;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}
.clear-both {
    clear: both;
}
.right.floated {
    float: right;
    margin-right: 0em;
    margin-left: 0.25em;
}

.cursor-pointer {
    cursor: pointer;
}

.mw-100 {
    max-width: 100% !important;
}

.ui.message.icon>.icon:not(.close) {
    font-size: 2em !important;
}

.ui.button {
    &.tertiary {
        box-shadow: none !important;
        background: none !important;
    }
}

input[type=checkbox]~.on,
input[type=radio]~.on,
input[type=checkbox]:checked~.off,
input[type=radio]:checked~.off {
    display: none;
}

input[type=checkbox]~.off,
input[type=radio]~.off,
input[type=checkbox]:checked~.on,
input[type=radio]:checked~.on {
    display: inline-block;
}

.radio-label,
.checkbox-label {
    display: block;
    cursor: pointer;

    .on,
    .off {
        .icon {
            margin-right: 0 !important;
        }
    }
}

.ui.label {
    border-radius: 0.5rem;
}

.ui.labeled.textarea {
    font-size: @relativeLarge;

    >.label {
        width: 100%;
        margin: 0;
        font-size: 1.5rem;
        line-height: 1.4em;
        display: block;
    }

    .label:first-child {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .textarea {
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}

.ui.label.dark-gray {
    background-color: @marso-gray-80;
    color: @white;
}

.product .labels {
    position: absolute;
    display: block;
    width: 100%;
    bottom: 0;
    left: 0;
    text-align: left;

    .ui.important.label {
        position: relative;
        margin: 0.5rem 1rem;
        display: block;
        padding-left: 40px !important;
        text-align: left;
        text-transform: uppercase;

        .icon {
            position: absolute;
            left: -1px;
            top: -0.2em;
            font-size: 1.7em;
        }
    }
}

.result-counter {
    font-size: 1.25rem;
}

.pager-wrapper {
    min-height: 3rem;

    &.with-total .pager-column {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    &.with-ordering .pager-column {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .total-column {
        border-radius: 0.5rem 0 0 0.5rem !important;

        .result-counter {
            display: inline-block;
            height: 3rem;
            color: @marso-gray-80  !important;
            margin-left: 1rem;
            padding: 0.5rem 1rem;
            line-height: 2rem;
            font-weight: bold;
            white-space: nowrap;
        }
    }

    .ordering-column {
        border-radius: 0 0.5rem 0.5rem 0 !important;

        .ordering-select {
            position: relative;
            left: -1rem;
            font-weight: bold !important;
            font-size: 1.25rem;
            height: 3rem;
            padding: 0.5rem 1rem;
            line-height: 2rem;
            background-color: @marso-gray-20;
            white-space: nowrap;

            .icon {
                margin-left: 0.5rem;
                line-height: inherit;
            }

            .menu {
                width: 100%;
                border-top-left-radius: 0;
                border-top-right-radius: 0;

                .item {
                    height: 3rem;
                    padding: 0.5rem 1rem;
                    line-height: 2rem;
                    font-size: 1.25rem;
                    text-transform: uppercase;
                }
            }
        }
    }

    &.pager-wrapper-footer {
        padding-top: 3rem;
    }
}

.ui.form {
    font-size: 1.5rem;
}

.ui.grid.compact {
    margin: 0 !important;

    >.column {
        padding: 0 !important;
    }

    >.row {
        padding: 0 !important;

        >.column {
            padding: 0 !important;
        }
    }
}

.ui.grid.topless {
    >.row:not(:first-child) {
        padding-top: 0 !important;
    }
}

.upper {
    text-transform: uppercase !important;
}

.lower {
    text-transform: lowercase !important;
}

.selectable.table tbody tr {
    cursor: pointer;
}

.ui.table {
    font-size: 1.3rem;
}

.ui.mini.message {
    font-size: 1.1rem;
}

.ui.tiny.message {
    font-size: 1.3rem;
}

.ui.small.message {
    font-size: 1.4rem;
}

.ui.message {
    font-size: 1.5rem;
    margin-top: 2rem !important;
}

.ui.large.message {
    font-size: 1.7rem;
}

.ui.big.message {
    font-size: 2rem;
}

.ui.huge.message {
    font-size: 3rem;
}

.ui.massive.message {
    font-size: 4rem;
}

.ui.buttons.two-part-button {
    .button {
        width: 80%;
        text-align: left;
        padding: 1rem 1rem 1rem 2rem;
        line-height: 4rem;
    }

    .button.icon {
        width: 20%;
        text-align: center;
        padding: 1rem;

        .icon {
            line-height: 4rem;
        }
    }
}

.ui.buttons.two-part-button.small {
    .button {
        line-height: 2rem;
    }

    .button.icon {
        .icon {
            line-height: 2rem;
        }
    }
}

.ui.mini.buttons .button,
.ui.mini.buttons .or,
.ui.mini.button {
    font-size: 0.9rem;
}

.ui.tiny.buttons .button,
.ui.tiny.buttons .or,
.ui.tiny.button {
    font-size: 1.1rem;
}

.ui.small.buttons .button,
.ui.small.buttons .or,
.ui.small.button {
    font-size: 1.3rem;
}

.ui.buttons .button,
.ui.buttons .or,
.ui.button {
    font-size: 1.5rem;
}

.ui.large.buttons .button,
.ui.large.buttons .or,
.ui.large.button {
    font-size: 1.7rem;
}

.ui.big.buttons .button,
.ui.big.buttons .or,
.ui.big.button {
    font-size: 2rem;
}

.ui.bigger.buttons .button,
.ui.bigger.buttons .or,
.ui.bigger.button {
    font-size: 2.5rem;
}

.ui.huge.buttons .button,
.ui.huge.buttons .or,
.ui.huge.button {
    font-size: 3rem;
}

.ui.massive.buttons .button,
.ui.massive.buttons .or,
.ui.massive.button {
    font-size: 4rem;
}

.ui.button.clear {
    border-color: transparent;
    background-color: transparent;

    &:hover,
    &:focus,
    &:active {
        background-color: transparent;
        color: @marso-gray-06;
    }
}

.ui.buttons.angular {
    border-radius: 0 !important;

    .ui.button {

        &:first-child,
        &:last-child {
            border-radius: 0 !important;
        }
    }
}

.ui.button.link {
    border-color: transparent !important;
    background-color: transparent !important;
    color: @marso-gray-80;

    &:hover,
    &:focus,
    &:active {
        background-color: transparent;
        color: @marso-gray-50;
    }
}

.ui.button.dark-gray {
    color: @white;
    background-color: @marso-gray-50;
}

.ui.button.darkest-gray {
    color: @white;
    background-color: @marso-gray-80;
}

.ui.button.dark.red {
    background-color: @marso-dark-red  !important;
}

.ui.button.red {

    &:hover,
    &:focus {
        background-color: @marso-dark-red;
    }
}

.ui.button.primary {
    background-color: @marso-red;

    &:hover,
    &:focus {
        background-color: @marso-dark-red;
    }
}

.ui.button.white {
    color: @marso-gray-50;
    background-color: @white;
}

.ui.button.angular {
    border-radius: 0;
}

.ui.button.image {
    padding: 0;
}

.ui.bordered.button {
    border: 0.2rem solid @white;
}

.ui.button.weight {
    font-weight: bold;
}

i.tiny.icon,
i.tiny.icon {
    font-size: 1.1rem;
}

i.mini.icon,
i.mini.icon {
    font-size: 1.3rem;
}

i.icon,
i.icons {
    font-size: 1.5rem;
}

i.large.icon,
i.large.icon {
    font-size: 1.7rem;
}

i.big.icon,
i.big.icon {
    font-size: 2rem;
}

i.bigger.icon,
i.bigger.icon {
    font-size: 2.5rem;
}

i.huge.icon,
i.huge.icon {
    font-size: 3rem;
}

i.massive.icon,
i.massive.icon {
    font-size: 4rem;
}

.ui.segments .segment,
.ui.segment {
    font-size: 1.5rem;
}
.ui.segment.flat {
    box-shadow: none;
    border: none;
}

i.icon.inverted.dark.red {
    background-color: @marso-dark-red  !important;
}

.ui.labeled.icon.button>.icon {
    background: none;
}

.page-title {
    text-transform: uppercase;
    font-size: 4.2rem;
    font-weight: normal;
    padding-top: 2rem;
    padding-bottom: 2rem;
    height: 9rem;
    line-height: 5rem;
    margin-bottom: 0;

    .label {
        vertical-align: middle;
    }
}

.page-title-nice {
    font-size: 6rem;
    font-weight: normal;
    color: @marso-brand;
    margin-top: 4rem !important;
    margin-bottom: 1.5rem !important;
}

h2.page-title-nice {
    font-size: 4rem;
}

.bordered-title {
    padding-bottom: 1rem;
    border-bottom: 2px solid @marso-brand;
}

.box-title {
    font-size: 4.2rem;
    font-weight: normal;
}

.ui.header.tyreprint-title {
    background: url(../images/tyreprint.svg) repeat-x top left;
    height: 4rem;
    line-height: 4rem;
    font-size: 4.2rem;
    font-weight: normal;

    .text {
        background-color: @white;
        display: inline-block;
        padding: 0 2rem;
        line-height: 4rem;
    }
}

//
//.content-title {
//  font-size: 6rem;
//  font-weight: normal;
//  color: @marso-brand;
//  padding-bottom: 1rem;
//  border-bottom: 2px solid @marso-brand;
//}

.important-header {
    z-index: -1;
    position: relative;
    font-size: 2.5rem;
    padding: 3rem 0 0 0;
    height: 27rem;

    .icon.marso-icon-tyre {
        color: @marso-gray-20;
        font-size: 21rem;
    }

    .text {
        z-index: 10;
        color: @marso-brand;
        font-size: 8.5rem;
        line-height: 10rem;
        position: absolute;
        top: 8rem;
        height: 10rem;
        width: 100%;
        display: block;
        margin: 0;
    }

    .text:after {
        z-index: -1;
        content: "";
        width: 100%;
        background-color: @white;
        height: 9rem;
        position: absolute;
        top: 20px;
        left: 0;
    }
}

.ui.popup {
    font-size: 1.25rem;
}

.info-block {
    font-size: 2.5rem;
    line-height: 3rem;
    border-top: 1px solid @marso-gray-20;
    border-bottom: 1px solid @marso-gray-20;
    margin: 0 0 3rem 0 !important;
    padding: 1.5rem 0 !important;

    .icon {
        font-size: 6rem !important;
    }

    .error-number {
        font-size: 5rem;
        font-weight: bold;
        color: @marso-gray-50;
    }
}

.ui.menu {
    font-size: 1.5rem;
}

.ui.dropdown .menu {
    .item {
        font-size: 1.5rem;
    }
}

.ui.card .content {
    font-size: 1.6rem;
}

.ui.large.input {
    font-size: 1.6rem;
}

/* more less trick
*/
.more-less-switch {
    .switch:checked {
        ~.switch-label {
            .on {
                display: block;
            }

            .off {
                display: none;
            }
        }

        ~.on-content {
            max-height: 999px;
            transition: max-height 1s linear;
        }
    }

    .switch {
        ~.switch-label {
            .on {
                display: none;
            }

            .off {
                display: block;
            }
        }

        ~.on-content {
            overflow: hidden;
            max-height: 0;
            transition: max-height 0.1s linear;
        }
    }

    .switch-label {
        cursor: pointer;
        color: @marso-red;

        :hover {
            color: @marso-dark-red;
        }
    }
}

/* manufacturer colors
*/
.bg-mfc-default {
    background-color: @mfc-default;
    color: @mfc-default-pair;
}

.text-mfc-default {
    color: @mfc-default-pair  !important;
}

// headers
.ui.header:first-child {
    margin-top: 0;
}

.ui.header.hero {
    font-size: 10rem;
    font-weight: bold;
}

.ui.header.goodboy {
    font-size: 6rem;
}

.ui.header.onimage {
    color: @white;
    text-shadow: 5px 1px 0px fade(@marso-gray-85, 95%),
        -1px -1px 5px fade(@marso-gray-85, 90%);
}

.ui.header.brand {
    color: @marso-brand;
}

.ui.header.thin {
    font-weight: normal !important;
}

/* card */
.ui.card.flat {
    box-shadow: none;
}

.ui.card.flat:hover {
    box-shadow: none !important;
    transform: none !important;
}

.ui.card.rounded {
    border-radius: 10px;

    > :first-child {
        border-top-left-radius: 10px !important;
        border-top-right-radius: 10px !important;
    }

    > :last-child {
        border-bottom-left-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
    }
}

/* image */
.ui.image.rounded {
    border-radius: 10px;
}

.ui.image.shadow {
    box-shadow: 0px 0px 10px 5px fade(@marso-gray-80, 50%);
}

/* info label */
.info-labels-wrapper {
    position: absolute;
    width: 100%;
    z-index: 5;
    bottom: 1rem;

    .info-label {
        color: @white;
        position: relative;
        min-height: 3rem;
        margin-top: 0.25rem;

        .info-label-icon {
            position: absolute;
            left: 1px;
            z-index: 5;
            background-color: darken(@marso-gray-50, 15);
            border-radius: 50%;
            width: 3rem;
            height: 3rem;

            .icon {
                box-shadow: none;
                width: 3rem !important;
                height: 3rem !important;
                font-size: 2rem;
                line-height: 2rem;
                padding: 0.5rem !important;
            }
        }

        .info-label-text {
            display: inline-block;
            max-width: 90%;
            font-size: 1.3rem;
            font-weight: bold;
            border-radius: 0 1.5rem 1.5rem 1.5rem;
            background-color: @marso-gray-50;
            margin: 0.25rem 0 0.25rem 1.5rem;
            padding: 0.5rem 1rem 0.5rem 2rem;
            line-height: 1;
        }
    }

    .info-label.closed {
        .info-label-text {
            display: none;
        }
    }

    .info-label.big {
        min-height: 5rem;
        margin-top: 0.5rem;

        .info-label-icon {
            width: 5rem;
            height: 5rem;
            line-height: 3rem;

            .icon {
                width: 5rem !important;
                height: 5rem !important;
                font-size: 3rem;
                line-height: 3rem;
                padding: 1rem !important;
            }
        }

        .info-label-text {
            font-size: 2rem;
            margin: 0.5rem 0 0.5rem 2.5rem;
            padding: 1rem 2rem 1rem 3rem;
            border-radius: 0 2.5rem 2.5rem 2.5rem;
        }
    }

    .info-label.red {
        .info-label-icon {
            background-color: darken(@marso-red, 15);
        }

        .info-label-text {
            background-color: @marso-red;
        }
    }

    .info-label.green {
        .info-label-icon {
            background-color: darken(@marso-green, 15);
        }

        .info-label-text {
            background-color: @marso-green;
        }
    }

    .info-label.blue {
        .info-label-icon {
            background-color: darken(@marso-blue, 15);
        }

        .info-label-text {
            background-color: @marso-blue;
        }
    }

    .info-label.cetelem {
        .info-label-icon {
            background-color: @cetelem-orange;
        }

        .info-label-text {
            background-color: @marso-green;
        }
    }
}

/* main elements
 */
html {
    height: auto;
}

body {
    height: auto;
    position: relative;
    display: flex;
    flex-direction: column;

    &.simple-header {
        margin-top: 6rem;
    }

    .main.container {
        flex: 1;
        flex-basis: auto; // ie11 fix
    }
}

button,
input,
optgroup,
select,
textarea {
    font-family: @fontName, 'Lato', Arial, Helvetica, sans-serif;
}

@import "header.less";

@import "footer.less";

@import "product.less";

/*
modal logic */
.ui.modals {
    z-index: 10000;
}

.ui.modal {
    .content {
        .default {
            display: block;
        }

        .working {
            display: none;
        }
    }

    &.working {
        .content {
            .default {
                display: none;
            }

            .working {
                display: block;
            }
        }
    }
}

.question-box {
    position: relative;
    background-color: @marso-green;
    color: @white;
    display: flex;
    flex: auto;
    align-items: center;
    justify-content: space-between;

    &.point-up:before,
    &.point-down:after {
        content: " ";
        position: absolute;
        height: 0;
        width: 0;
        left: 8.5rem;
    }

    &.point-up:before {
        top: -1.5rem;
        border-top: none;
        border-right: 1.5rem solid transparent;
        border-left: 1.5rem solid transparent;
        border-bottom: 1.5rem solid @marso-green;
    }

    &.point-down:after {
        bottom: -1.5rem;
        border-top: 1.5rem solid @marso-green;
        border-right: 1.5rem solid transparent;
        border-left: 1.5rem solid transparent;
        border-bottom: none;
    }

    .image {
        height: 7rem;
        line-height: 7rem;
        width: 7rem;
        display: block;

        .icon {
            height: 7rem;
            font-size: 5rem;
            line-height: 7rem;
            text-align: left;
        }
    }

    .yes-or-no {
        min-width: 16rem;
        height: 4rem;
        background-color: @white;
        border-radius: 0.5rem;
        margin-left: 1rem;

        .radio-field {
            height: 100%;
            width: 100%;

            .radio-label {
                height: inherit;
                width: inherit;
                padding: 0.5rem;

                .ui.labeled.button {
                    width: 100%;
                    margin-right: 0;

                    .label,
                    .ui.button {
                        display: inline-block;
                        width: 50%;
                        height: 3rem;
                        border: none;
                        padding: 0.5rem;
                        line-height: 2rem;
                        font-size: 2rem;
                    }

                    .label {
                        color: @marso-gray-30;
                    }
                }

                .on,
                .off {
                    height: inherit;
                    width: inherit;

                    .icons {
                        .icon {
                            font-size: 3rem;
                        }
                    }
                }
            }
        }
    }

    .question {
        font-size: 1.8rem;
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        width: 100%;
    }

    &.padded {
        padding: 1rem 3rem;
    }

    &.transparent {
        background-color: transparent;

        .yes-or-no {
            background-color: transparent;
        }
    }
}

@import "marso-booking.less";

@import "cart.less";

@import "marso-checkout.less";

@import "marso-form.less";

.scroller-rail {
    .scroller-train {
        &.moving-train {
            position: fixed;
            top: 70px;
        }

        &.stopped-train {
            position: relative;
            top: 100%;
            transform: translateY(-100%);
        }
    }
}

.pager-wrapper {
    &>.column {
        background-color: @marso-gray-10;
        padding: 0 1rem !important;
        border-radius: 0.5rem;

        .pager {
            border: none;

            .button {
                padding: 0.5rem 1rem;
                font-size: 1.5rem;
                font-weight: bold;
                line-height: 2rem;
                background-color: @marso-gray-20  !important;

                .icon {
                    margin: 0 !important;
                }
            }

            .button.active {
                background-color: @marso-red  !important;
                color: @white  !important;
            }
        }
    }

}

// car form styling
.car-form {
    .autocomplete-field {
        .results {
            max-height: 200px;
            overflow-y: scroll;
        }
    }

    .select-field {
        .dropdown {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;

            &:hover {
                border-color: @white;
            }

            .default.text {
                color: @marso-gray-30;
            }
        }
    }

    .alcar-car-image {

        >.grid {
            height: 100%;
            background-color: @white;
        }

        .status-basic {
            display: block;

            .icon {
                font-size: 15rem;
            }
        }

        .status-loading,
        .status-image {
            display: none !important;
        }
    }

    .alcar-car-image.waiting {
        .status-loading {
            display: block !important;
        }

        .status-basic {
            display: none !important;
        }

        .status-image {
            display: none !important;
        }
    }

    .alcar-car-image.ready {
        .status-image {
            display: block !important;
        }

        .status-basic {
            display: none !important;
        }

        .status-loading {
            display: none !important;
        }
    }
}

// search pages
.detailed-search-panel {
    padding-top: 3rem !important;
    padding-bottom: 6rem !important;

    .form-panel {
        padding-right: 1.5rem !important;
    }

    .results-panel {
        border-left: 1px solid @marso-gray-20;
        padding-left: 3rem !important;

        .featured-manufacturers {
            padding-bottom: 3rem;
        }
    }
}

.ui.form.product-search-sidebar-form {
    .attached-label-field-wrapper {
        padding: 0;
        border-radius: 0.5rem;
        box-shadow: none;
        border-color: @marso-gray-06;
        border-width: 1px;

        .select-field .dropdown {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }

    >.ui.grid>.row {
        padding-top: 0.5rem;

        &.form-data {
            padding-top: 0;

            .widths,
            .profiles,
            .diameters,
            .holes,
            .bolt-patterns,
            .center-bores,
            .wheel-offsets {
                .dropdown {
                    max-height: 3rem;
                    //background-color: @marso-gray-06;
                    border-color: @marso-gray-06;
                    font-size: 1.5rem;
                    padding: 0.5rem;
                }
            }

            .widths,
            .profiles,
            .holes,
            .center-bores {
                padding-right: 1rem !important;
            }
        }
    }

    .header {
        margin-bottom: 0 !important;
    }

    .manufacturers {
        max-height: 250px !important;
    }
}

.ui.form.product-search-form {
    .search-status {
        display: none;
        position: fixed !important;
        bottom: 1rem;
        z-index: 10;

        &.visible {
            display: block;
        }

        .column {
            padding-right: 1.5rem;
        }
    }

    .sizes {
        padding-bottom: 3rem;
    }

    .fuel-efficiencies {
        padding-top: 0.5rem;
    }

    .wet-grip-classes {
        padding-top: 0.5rem;
    }

    .header {
        font-weight: normal;
        font-size: 20px;
        border-color: @marso-red;
        border-width: 2px;
        margin-bottom: 0.5rem;
    }

    .show-results {
        .results-text {
            font-size: 1.5rem;
            text-transform: lowercase;
        }
    }

    .select-field {
        .dropdown.icon {
            padding: 1.5rem;
        }

        .dropdown.selection {
            background-color: @marso-gray-06;
            border-color: @marso-gray-06;
        }

        .default.text {
            color: @marso-gray-85;
        }
    }

    .checkbox-field {
        .checkbox-label {
            font-weight: normal;
            text-transform: uppercase;
            font-size: 1.4rem;
        }
    }

    .manufacturers {
        max-height: 600px;
        overflow-y: scroll;
        border-bottom: 1px solid @marso-gray-50;
    }

    .fuel-efficiencies,
    .wet-grip-classes {
        .checkbox-label {

            .off,
            .on {
                padding-right: 10px;
            }

            &:before {
                color: @white;
                padding-left: 10px;
                position: absolute;
                left: 25px;
                font-weight: bold;
            }

            &:after {
                position: absolute;
                content: "";
                left: 25px;
                padding-left: 10px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 11px 0 11px 11px;
            }

            &.value-A:before {
                content: "A";
                width: 20%;
                background-color: #016701;
            }

            &.value-A:after {
                margin-left: 20%;
                border-color: transparent transparent transparent #016701;
            }

            &.value-B:before {
                content: "B";
                width: 25%;
                background-color: #0D9C0D;
            }

            &.value-B:after {
                margin-left: 25%;
                border-color: transparent transparent transparent #0D9C0D;
            }

            &.value-C:before {
                content: "C";
                width: 30%;
                background-color: #98CB00;
            }

            &.value-C:after {
                margin-left: 30%;
                border-color: transparent transparent transparent #98CB00;
            }

            &.value-D:before {
                content: "D";
                width: 35%;
                background-color: #E8E31F;
            }

            &.value-D:after {
                margin-left: 35%;
                border-color: transparent transparent transparent #E8E31F;
            }

            &.value-E:before {
                content: "E";
                width: 40%;
                background-color: #FFC001;
            }

            &.value-E:after {
                margin-left: 40%;
                border-color: transparent transparent transparent #FFC001;
            }

            &.value-F:before {
                content: "F";
                width: 45%;
                background-color: #F98605;
            }

            &.value-F:after {
                margin-left: 45%;
                border-color: transparent transparent transparent #F98605;
            }

            &.value-G:before {
                content: "G";
                width: 50%;
                background-color: #FE0000;
            }

            &.value-G:after {
                margin-left: 50%;
                border-color: transparent transparent transparent #FE0000;
            }
        }
    }

    .noise-measurement-classes {
        .checkbox-label {
            height: 50px;
            line-height: 50px;

            &.noise-1 {
                background: url(../images/noise-1.svg) no-repeat 25px center;
            }

            &.noise-2 {
                background: url(../images/noise-2.svg) no-repeat 25px center;
            }

            &.noise-3 {
                background: url(../images/noise-3.svg) no-repeat 25px center;
            }
        }
    }
}

.autocomplete-field {
    .results {
        display: none;
        padding: 5px 0;
        position: absolute;
        top: 50px;
        right: 1px;
        left: 1px;
        background-color: @white;
        z-index: 999;
        box-shadow: 0px 4px 5px 0px fade(@marso-gray-85, 30%), 0px 4px 5px 0px fade(@white, 30%);
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        .message {
            padding: 5px 10px;
        }

        .result {
            color: @marso-gray-60;
            cursor: pointer;
            padding: 5px 10px;
            display: block;

            &:hover {
                color: @marso-gray-80;
                background-color: @marso-gray-06;
            }

            .title {
                display: inline-block;
                padding-right: 10px;
            }

            .description {
                display: inline-block;
            }
        }
    }
}

@import "manufacturer.less";

@import "news.less";

@import "discount.less";

@import "rim-search.less";

@import "service-site.less";

.account-page {
    .avatar {
        margin-bottom: 2rem;
    }

    .ui.vertical.menu {
        >.active.item {
            background-color: @marso-red;
            color: @white;

            &:hover {
                background-color: saturate(darken(@marso-red, 5), 10, relative);
                color: @white;
            }
        }
    }

    .orders-table {
        tbody {
            tr {
                cursor: pointer;
            }
        }
    }

    .basic.button {
        box-shadow: none;
        font-weight: bold;

        &:hover {
            background: none !important;
            box-shadow: none;
        }
    }
}

#order-view-modal {
    label {
        font-weight: bold;
    }
}

#order-view-modal,
#address-edit-modal,
#cars-edit-modal {
    >.content {
        padding: 0 !important;

        .form-block {
            margin-bottom: 0;

            &.active>.top.attached.header {
                border-radius: 0;
            }

            >.content {
                padding: 1rem;
            }
        }
    }
}

.login-page {
    .login-block {
        .ui.form {
            ::-webkit-input-placeholder {
                color: @marso-gray-60;
            }

            :-moz-placeholder {
                color: @marso-gray-60;
                opacity: 1;
            }

            ::-moz-placeholder {
                color: @marso-gray-60;
                opacity: 1;
            }

            :-ms-input-placeholder {
                color: @marso-gray-60;
            }

            ::-ms-input-placeholder {
                color: @marso-gray-60;
            }

            ::placeholder {
                color: @marso-gray-60;
                opacity: 1;
            }
        }

        .form-block.active {
            border-radius: 10px;
            padding: 1rem;
        }

        .reset-group {
            display: none;
        }

        .login-group,
        .reset-group {

            .button.login-forgot,
            .login-remembered {
                box-shadow: none;

                &:hover {
                    background: none !important;
                }
            }
        }

        #login-forgot-password {
            box-shadow: none;

            &:hover {
                background: none !important;
            }
        }
    }

    .lead-text {
        font-size: 2rem;
        line-height: 5rem;
        margin-bottom: 1rem;

        .handwritten {
            display: block;
            color: @marso-red;
            font-size: 3.75rem;
        }
    }

    .ui.grid {
        .social-login-row {
            margin-bottom: 2rem;

            .button.social-login {
                width: 100%;
            }
        }

        .registrationless-checkout,
        .registration-block {
            padding-left: 0;
            padding-right: 0;

            .ui.grid>.column {
                padding-top: 2rem;
                padding-bottom: 2rem;
            }

            .ui.form input {
                background-color: @marso-gray-10;
                border-color: @marso-gray-10;
                color: @marso-gray-80;

                &::placeholder {
                    color: @marso-gray-60;
                    opacity: 1;
                }
            }

            .basic.button {
                font-weight: bold;
            }
        }
    }

    .appreciation {
        background: @marso-gray-06;
        padding-top: 2rem;

        .lead-text {
            font-size: 2.75rem;
            margin-bottom: 0;

            .handwritten {
                font-size: 6rem;
                line-height: 6rem;
            }
        }

        .appreciation-image-block {
            min-height: 320px;
        }

        .appreciation-image {
            position: absolute;
            bottom: 0;
            left: 1rem; // adjust to container's padding

            @media all and (max-width: 767px) {
                display: none;
            }
        }

        ul {
            position: relative;
            padding-left: 3rem;

            li {
                list-style-type: none;
                padding-bottom: 1rem;

                &:before {
                    display: inline-block;
                    opacity: 1;
                    margin: 0.2em 0.25rem 0em 0em;
                    width: 1.18em;
                    height: 1em;
                    font-family: 'Icons';
                    font-style: normal;
                    font-weight: normal;
                    text-decoration: inherit;
                    text-align: center;
                    speak: none;
                    font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    -webkit-font-smoothing: antialiased;
                    -webkit-backface-visibility: hidden;
                    backface-visibility: hidden;
                    font-size: 1.8rem;
                    color: #19a531 !important;
                    content: "\f058";
                    position: absolute;
                    left: 0;
                }
            }
        }
    }
}

.ui.social-login {
    overflow: hidden;
}

.ui.google.plus.social-login {
    background: @white;
    border: 1px solid @googlePlusColor;
    color: @googlePlusColor;

    &.button>.icon {
        color: @white;
        background: @googlePlusColor;
        border-radius: 0;
    }
}

.ui.facebook.social-login {
    background: @white;
    border: 1px solid @facebookColor;
    color: @facebookColor;

    &.button>.icon {
        color: @white;
        background: @facebookColor;
        border-radius: 0;
    }
}

.ui.apple.button {
    background-color: @black;
    color: @white;
    text-shadow: none;
    background-image: none;
    box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;

    &:hover {
        background-color: lighten(@black, 10%);
    }
}

.ui.apple.social-login {
    background: @white;
    border: 1px solid @black;
    color: @black;

    &.button>.icon {
        color: @black;
        background: @white;
        border-radius: 0;
    }

    &.button:hover {
        background-color: @black;
        color: @white;
        text-shadow: none;

        >.icon {
            color: @white;
            background-color: lighten(@black, 10%);
        }
    }
}

@import 'unique-pages.less';

.highlighted-offers-panel {
    padding-top: 6rem;

    .products {
        padding-top: 3rem;
    }
}

.news-box-panel {
    padding-top: 6rem;

    .news>.ui.grid {
        padding-top: 6rem;
    }
}

.home {
    .main.container {
        padding-bottom: 6rem;
    }
}

#map-gui {
    display: none;
}

#map {
    #map-gui {
        display: block;
        position: absolute !important;
        top: 2rem !important;
        right: 2rem !important;
        width: 50%;
        max-width: 300px;
        border-radius: 5px;

        .field {
            width: 100%;

            input {
                box-shadow: 1px 1px 5px @marso-gray-20;

                &::placeholder {
                    color: @marso-gray-60;
                    opacity: 1;
                }
            }
        }

        .checkbox-field {
            .ui.segment {
                height: 40px;

                label {
                    position: absolute;
                    top: 50%;
                    transform: perspective(1px) translateY(-50%);
                    padding-top: 2px;

                    .on,
                    .off {
                        vertical-align: text-bottom;
                        margin-right: 0.5rem;
                        margin-top: -1px;
                        font-size: 1.4rem;
                    }
                }
            }
        }
    }
}

.si-float-wrapper {
    .si-wrapper-top {
        .si-content-wrapper {
            border-radius: 25px;
            border-top-right-radius: 0;
            padding: 0;
            overflow: visible;

            .si-close-button {
                top: -15px;
                right: -15px;
                background-color: #fff;
                color: #333;
                opacity: 1;
                box-shadow: 0 1px 3px 0 #000;
                border-radius: 30px;
                width: 30px;
                height: 30px;
            }

            .si-content {
                border-radius: 25px;
                border-top-right-radius: 0;

                .cover-image {
                    max-width: 400px;
                    max-height: 91px;
                }

                .wrapper {
                    padding: 20px;
                    width: 400px;

                    .title {
                        color: @marso-red;
                        margin-bottom: 5px;
                    }

                    .content {
                        font-size: 1.35rem;

                        ul {
                            margin-top: 0;
                            margin-bottom: 0;
                            list-style: none;
                            padding-left: 0;
                        }

                        a {
                            color: @marso-red;
                            font-weight: bold;
                        }
                    }
                }
            }
        }

        .si-frame.si-shadow-frame {
            border-radius: 25px;
            border-top-right-radius: 0;
            box-shadow: 0px 5px 10px 2px @black;
        }
    }
}

@import "banners.less";

@import "service.less";

.informations-menu-panel {
    padding-top: 6rem;

    .menu {
        border-top: 2px solid @marso-gray-06  !important;
        border-bottom: 2px solid @marso-gray-06  !important;

        .item {
            padding: 1.5rem 0;
            width: 25%;
            font-size: 1.7rem;

            .icon {
                font-size: 3.6rem;
            }
        }
    }
}

@import "contact.less";

@import "coupons.less";

.tab-menu-panel {
    padding-top: 3rem;

    .tab-menu-panel-header {
        >.column {
            border-left: 1px solid @white;

            a.button {
                padding-top: 2.4rem;
            }

            .button {
                padding: 1rem 1rem;
                height: 10rem;

                .icon {
                    opacity: 1;
                }

                font-weight: normal;

                .label {
                    padding-top: 0.5rem;
                    font-size: 1.7rem;
                    line-height: 1.8rem;
                    display: block;
                }
            }

            .button.active {
                font-weight: bold;
                position: relative;
                background-color: @marso-gray-10  !important;
                //outline: 10px solid @marso-gray-10;
                box-shadow: 0 0 0 10px @marso-gray-10;
                color: @marso-gray-50;
                z-index: 1;

                &:hover {
                    color: @marso-gray-60;
                }

                &:after {
                    content: "";
                    position: absolute;
                    bottom: -30px;
                    left: 50%;
                    border-bottom: 20px solid transparent;
                    border-right: none;
                    border-left: 20px solid @marso-gray-10;
                    border-top: none;
                }

                &:before {
                    content: "";
                    position: absolute;
                    bottom: -30px;
                    right: 50%;
                    border-bottom: 20px solid transparent;
                    border-right: 20px solid @marso-gray-10;
                    border-left: none;
                    border-top: none;
                }
            }
        }
    }

    .tab-menu-panel-contents {
        padding-top: 4rem;
        padding-bottom: 3rem;
    }
}

.eu-tyre-label-container {
    .tyre-label-image {
        width: 338px;
        height: 495px;
        margin: auto;
        position: relative;
        font-family: 'Verdana', 'sans-serif';

        #qr-code-panel {
            position: absolute;
            right: 0;
            top: 0;
        }

        .product-information-panel {
            position: absolute;
            top: 68px;
            left: 15px;
            width: 100%;
            .manufacturer-name {
                padding-top: 2px;
                font-weight: bold;
                #eprel-id {
                    position: absolute;
                    font-weight: normal;
                    right: 30px;
                    top: 3px;
                    float: right;
                }
            }
            .size {
                padding-top: 8px;
                font-weight: lighter;
            }
            #tyre-class {
                position: absolute;
                right: 30px;
                top: 35px;
                float: right;
            }
        }
        .fuel-panel,
        .wet-grip-panel {
            position: absolute;
            top: 83px;
            left: 125px;
            width: 37px;
            height: 120px;

            .value {
                width: 37px;
                height: 25px;
                position: absolute;
                font-weight: bold;
                color: @white;
                text-align: center;
                padding-left: 10px;
                padding-top: 2px;

                .value-absolute {
                    position: absolute;
                    left: 0;
                }

                .value-relative {
                    position: relative;
                    z-index: 1;
                    top: 2px;
                }
            }

            .value-a {
                top: 124px;
            }

            .value-b {
                top: 159px;
            }

            .value-c {
                top: 196px;
            }

            .value-d {
                top: 232px;
            }

            .value-e {
                top: 269px;
            }
        }

        .wet-grip-panel {
            left: 290px !important;
        }

        .product-pictogram-panel {
            position: absolute;
            top: 393px;
            width: 94%;
            text-align: center;
            margin-left: 10px;
            margin-right: 10px;

            .value {
                height: 100%;
                width: 100%;
            }
        }

        .noise-measurement-value-panel {
            position: absolute;
            top: 236px;
            left: 140px;
            width: 50px;
            height: 26px;

            .value {
                color: @white;
                font-weight: bold;
                text-align: center;
                vertical-align: middle;
                line-height: 26px;
            }
        }

        .noise-measurement-class-panel {
            position: relative;
            display: inline-block;
            left: 10px;
            width: 100px;
            height: 76px;
            margin-left: 10px;
            margin-right: 10px;
        }
        .noise-measurement-class-value-panel {
            position: absolute;
            bottom: -4px;
            left: 40px;
            .noise-measurement-class-value {
                font-weight: bold;
                padding: 1px;
            }

        }
        .noise-measurement-value {
            position: absolute;
            top: 19px;
            left: 26px;
            font-weight: bold;
        }
        .pmsf-notation-panel {
            position: relative;
            display: inline-block;
            margin-left: 10px;
            margin-right: 10px;
            width: 65px;
            height: 57px;
        }
        .ig-notation-panel {
            position: relative;
            display: inline-block;
            margin-left: 10px;
            margin-right: 10px;
            width: 65px;
            height: 69px;
        }
    }
}

.recommended-products-panel,
.similar-products-panel {
    padding-top: 5rem;
    padding-bottom: 5rem;

    .header-wrapper.ui.grid {
        position: relative;
        height: 100%;
        background-color: @marso-gray-80;
        color: @white;
        margin-right: 3rem !important;
        padding: 3rem;

        .arrow {
            top: 0;
            padding: 0;
            position: absolute;
            right: -3rem;
            height: 100%;
            min-height: 100%;
            width: 3rem;
            display: block;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .header {
            .icon {
                font-size: 10rem;
            }

            .text {
                margin-top: 1.5rem;
                padding-top: 1.5rem;
                border-top: 1px solid @marso-red;
                font-size: 2.5rem;
            }
        }
    }
}
.sibling-brands-products-panel {
    padding-top: 5rem;
    padding-bottom: 5rem;

    .header-wrapper.ui.grid {
        position: relative;
        height: 100%;

        .header {
            .logo-image {
                width: 100%;
                display: block;
            }

            .text {
                margin-top: 1.5rem;
                padding-top: 1.5rem;
                border-top: 3px solid @marso-red;
                font-size: 2.5rem;
            }
        }
    }
}
.error-404-header-panel {
    min-height: 50rem;
    padding-top: 30rem;
    background: transparent url(../images/error-404.png) no-repeat center 4rem;

    h1 {
        color: @marso-red;
        font-size: 11rem !important;
        line-height: 7rem;

        &:after {
            display: none;
        }
    }

    h2 {
        color: @marso-red;
        font-size: 6rem;
    }
}

.menu-error-404 {
    padding-bottom: 6rem;

    h1 {
        color: @marso-red;
    }

    .grid {
        padding-top: 1rem;
    }

    .item {
        display: block;
        min-height: 20rem;
        background-color: @marso-gray-06;
        padding: 2rem;
        border-radius: 1rem;
        color: @marso-red;

        .icons {
            display: block;
            margin: auto;
            background-color: @white;
            width: 10rem;
            height: 10rem;
            border-radius: 50%;
            line-height: 10rem;

            .icon {
                font-size: 7rem;
                vertical-align: middle;
            }
        }

        .text {
            padding-top: 1.5rem;
            color: @marso-gray-85;
            font-weight: bold;
            font-size: 1.8rem;
            display: block;
        }
    }
}

#error-404-page {
    #contact-form {
        .close-button {
            display: none;
        }
    }
}

.page-thank-you {
    .main.container {
        padding-bottom: 3rem;
    }
}

#quick-messages,
.quick-messages {
    display: none;

    &.active {
        display: block;
        position: fixed;
        bottom: 5rem;
        left: 50%;
        margin-left: -150px;
        max-width: 90%;
        z-index: 99999;
    }

    .message {
        width: 300px;
        margin: auto;
        padding: 0.5rem 0.5rem 0.5rem 1rem;
        box-shadow: none;
        font-weight: bold;
        color: @white;

        .icon {
            opacity: 1;
        }

        .close.icon {
            position: relative;
            bottom: 0;
            top: 0;
            margin-bottom: 0.5rem;
            right: 0;
        }

        &.info {
            background-color: @marso-blue;
            border-color: @marso-blue;

            .close.icon {
                color: @marso-red;
            }
        }

        &.error {
            background-color: @marso-red;
            border-color: @marso-red;

            .close.icon {
                color: @marso-blue;
            }
        }

        &.white {
            background-color: @white;
            border-color: @white;
            color: @black;

            .close.icon {
                color: @black;
            }
        }

        &.info,
        &.error,
        &.white {
            border-radius: 0.5rem;
            border-width: 2px;
            border-style: solid;
        }
    }
}

.cetelem-box {
    margin-bottom: 5%;
    display: inline-block;
}

#cetelem-calculator {
    display: inline-block;
}

.cetelem-icon {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: none;
    background-image: url(../../../../resources/cetelem/cetelem-badge.png);
}

.product-list {
    .cetelem-icon {
        margin-top: 5px;
        margin-left: -7px;
    }
}

.product-page {
    .cetelem-icon {
        position: absolute;
        top: 2px;
        left: 2px;
    }
}

#cetelem-modal {
    .modal-dialog {
        width: 980px;
        height: 95%;
    }

    .modal-content {
        height: 100%;

        .modal-body {
            height: 90%;
        }
    }

    iframe {
        width: 100%;
        height: 100%;
        border: none;
    }
}

#forgot-password-modal {
    >.content {
        font-size: 2rem;
    }
}

.pex-mounting-coupon-block {
    background-color: @marso-gray-06;
    padding: 3rem 2rem;
    font-size: 2rem;

    .pex-button {
        background-color: #FFA500;
        font-weight: bold;
        display: inline-block;
        height: 5rem;
        font-size: 1.9rem;
        line-height: 3rem;
        padding: 1rem 2rem;
        border-radius: 2.5rem;
        color: #000;
        text-decoration: none;

        .icon {
            font-weight: normal;
            font-size: 1.6rem;
        }
    }
}

html.desktop {
    #ak-widget {
        z-index: 99998 !important;

        &.akw-bottom-right {
            will-change: bottom;
        }
    }

    .cc_banner-wrapper {
        z-index: 99999;
        opacity: 0.95;
    }
}

.hs-frame {
    position: relative;
    width: 100%;
    background-color: inherit;

    .hs-button-wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;
        z-index: 10;
        background-color: inherit;

        .hs-shadow {
            height: 100%;
            width: 100%;
            position: relative;
            background-color: inherit;
        }

        .hs-button {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            width: 4rem;
            height: 4rem;
            padding: 0;
            margin: 0;
        }

        .hs-shadow::before {
            content: "";
            position: absolute;
            z-index: -1;
            width: 1rem;
            top: 0.5rem;
            bottom: 0.5rem;
            border-radius: 5rem;
            box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.3);
        }

        .hs-shadow::after {
            content: "";
            position: absolute;
            z-index: -1;
            width: 1rem;
            top: 30%;
            bottom: 30%;
            border-radius: 5rem;
            box-shadow: 0 0 1.75rem rgba(0, 0, 0, 0.2);
        }
    }

    .hs-button-wrapper.left {
        left: 0;

        .hs-shadow::before {
            right: 0;
        }

        .hs-shadow::after {
            right: 0;
        }
    }

    .hs-button-wrapper.right {
        right: 0;

        .hs-shadow::before {
            left: 0;
        }

        .hs-shadow::after {
            left: 0;
        }
    }

    .hs-items {
        white-space: nowrap;
        overflow: hidden;
        margin: 0 4rem;
        z-index: 0;

        .hs-item {
            // white space is inherited, we do not need this
            white-space: normal;
            display: inline-block;
            position: relative;
            padding: 0 2%;
        }

        .garage-list-app-icons .item {
            display: inline-block;
            padding-right: 10px;
        }

        .garage-list-app-icons .item img {
            height: 50px;
        }
    }
}

@import "wheel-wizard.less";

@import 'marso-unique-visitors-counter.less';
@import 'newsletter.less';
@import 'marso-profile-page.less';
@import 'service-offer-request.less';
@import 'dot-tyres.less';

.sidebar {
    margin-top: 4rem !important;

    .sidebar-block {
        margin-bottom: 3rem;

        .header,
        .sidebar-block-header {
            font-weight: normal;
            font-size: 2rem !important;
            border-bottom: 2px solid @marso-light-red  !important;
            a {
                color: @marso-gray-80  !important;
            }
            a:hover {
                color: @marso-red  !important;
            }
        }

        .url-list,
        .sidebar-block-menu {
            .item {
                color: @marso-gray-80  !important;

                &:hover {
                    color: @marso-red  !important;
                }
            }
        }
    }
}

.env-strip {
    position: fixed;
    z-index: 99999;
    right: 0;
    height: 100%;
    width: 3rem;
    background-color: #EC4E1C;
    color: #fff;
    writing-mode: vertical-lr;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    line-height: 2rem;
    padding: 0.5rem;
    opacity: 0.7;
}

.product-ratings-panel {
    width: 100%;

    .ui.segment {
        border: 0;
        border-radius: 0;
    }

    .data-column {
        background-color: @marso-gray-80;
        color: @white;
        margin: 0;

        .rating-bottom {
            position: absolute;
            bottom: 1rem;
        }

        .name {
            text-transform: uppercase;
            font-weight: bold;
        }

        .empty-name {
            text-transform: uppercase;
            font-style: italic;
            color: @marso-gray-20;
        }
    }

    .header {
        margin-bottom: 3rem;
    }

    .span {
        display: inline-block;
    }

    .rating-column {
        height: auto;
        background-color: @marso-gray-10;
        margin: 0;

        padding: 1rem !important;

        .rating {
            line-height: 2rem;
        }

        .rating-right {
            text-align: right;
        }
    }

    .comment-column {
        height: auto;
        background-color: @marso-gray-06;
        margin: 0;

        padding: 1rem !important;
    }

    .ui.button {
        width: 100%;
        margin: 0;
        padding: 1rem;
        margin-top: 1rem;
    }

    .personal-rating {
        display: inline-block;
        padding-top: 2rem;
    }
}

.rating {
    display: flex;
    justify-content: space-between;
    line-height: 3rem;

    .details {
        font-size: 2rem;

        .score {
            display: inline;
            font-size: 3rem;
            padding-inline: 1rem;
        }

        .votes {
            font-size: 1.5rem;
        }
    }
}

.rating-stars {
    column-gap: 0.25rem;
    white-space: nowrap;

    .icon {
        color: @marso-gray-30;
        font-size: 1.5rem;
        width: 1.5rem;
        margin: 0;
    }

    .icon::before {
        width: 1.5rem;
        display: inline-block;
    }

    .filled {
        color: @marso-light-orange;
    }

    .half-filled {
        position: relative;

        .half.star {
            position: absolute;
            left: 0;
        }

        .half.star::before {
            position: absolute;
            left: 0;
            width: auto;
        }
    }
}

.score {
    display: inline-block;
    font-size: 1.5rem;
    width: 1rem;
}

.ui.grid.compact > .data-column {
    padding: 1rem !important;
}

.ui.grid.compact > .rating-column {
    padding: 1rem !important;
}

.ui.grid.compact > .comment-column {
    padding: 1rem !important;
}
