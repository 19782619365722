@import '../marso-icons/style.css';
@import '../css/semantic.css';
@import '~vanilla-cookieconsent/src/cookieconsent.css';
@import '~snazzy-info-window/dist/snazzy-info-window.css';
#debug {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 998;
  background-color: rgba(51, 51, 51, 0.2);
}
#debug .ui.container {
  height: 100%;
  outline: 1px solid red;
}
#debug .ui.container .grid {
  height: 100%;
}
#debug .ui.container .grid .column {
  position: relative;
  height: 100%;
  background-color: rgba(51, 51, 51, 0.1);
  outline: 1px solid blue;
}
#debug .ui.container .grid .column:before {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  width: 1rem;
  height: 100%;
  background-color: rgba(0, 0, 255, 0.2);
}
#debug .ui.container .grid .column:after {
  content: " ";
  position: absolute;
  right: 0;
  top: 0;
  width: 1rem;
  height: 100%;
  background-color: rgba(0, 0, 255, 0.3);
}
.debug-buttons {
  top: 0;
  left: 0;
  z-index: 99999;
  position: fixed;
}
body.show-grids .ui.grid {
  outline: 3px solid lightgreen;
}
body.show-grids .ui.grid .column {
  outline: 1px dashed orange;
}
body.show-grids .ui.grid.column {
  outline: 3px dashed lightgreen !important;
}
body.show-images img {
  outline: 1px dotted darkblue;
}
/*******************************
         Site Settings
*******************************/
/*-------------------
       Fonts
--------------------*/
/*-------------------
      Base Sizes
--------------------*/
/* This is the single variable that controls them all */
/* The size of page text  */
/*-------------------
    Border Radius
--------------------*/
/* See Power-user section below
   for explanation of @px variables
*/
/*-------------------
    Brand Colors
--------------------*/
/*--------------
  Page Heading
---------------*/
/*--------------
   Form Input
---------------*/
/* This adjusts the default form input across all elements */
/* Input Text Color */
/* Line Height Default For Inputs in Browser (Descendors are 17px at 14px base em) */
/*-------------------
    Focused Input
--------------------*/
/* Used on inputs, textarea etc */
/* Used on dropdowns, other larger blocks */
/*-------------------
        Sizes
--------------------*/
/*
  Sizes are all expressed in terms of 14px/em (default em)
  This ensures these "ratios" remain constant despite changes in EM
*/
/*-------------------
        Page
--------------------*/
/*-------------------
      Paragraph
--------------------*/
/*-------------------
       Links
--------------------*/
/*-------------------
    Scroll Bars
--------------------*/
/* Inverted */
/*-------------------
  Highlighted Text
--------------------*/
/*-------------------
       Loader
--------------------*/
/*-------------------
        Grid
--------------------*/
/*-------------------
     Transitions
--------------------*/
/*-------------------
     Breakpoints
--------------------*/
/*-------------------
      Site Colors
--------------------*/
/*---  Colors  ---*/
/*---  Light Colors  ---*/
/*---   Neutrals  ---*/
/*--- Colored Backgrounds ---*/
/*--- Colored Headers ---*/
/*--- Colored Text ---*/
/*--- Colored Border ---*/
/*-------------------
     Alpha Colors
--------------------*/
/*-------------------
       Accents
--------------------*/
/* Differentiating Neutrals */
/* Differentiating Layers */
/*******************************
           Power-User
*******************************/
/*-------------------
    Emotive Colors
--------------------*/
/* Positive */
/* Negative */
/* Info */
/* Warning */
/*-------------------
        Paths
--------------------*/
/* For source only. Modified in gulp for dist */
/*-------------------
       Em Sizes
--------------------*/
/*
  This rounds @size values to the closest pixel then expresses that value in (r)em.
  This ensures all size values round to exact pixels
*/
/* em */
/* rem */
/*-------------------
       Icons
--------------------*/
/* Maximum Glyph Width of Icon */
/*-------------------
     Neutral Text
--------------------*/
/*-------------------
     Brand Colors
--------------------*/
/*-------------------
      Borders
--------------------*/
/*-------------------
    Derived Values
--------------------*/
/* Loaders Position Offset */
/* Rendered Scrollbar Width */
/* Maximum Single Character Glyph Width, aka Capital "W" */
/* Used to match floats with text */
/* Header Spacing */
/* Minimum Mobile Width */
/* Positive / Negative Dupes */
/* Responsive */
/*-------------------
  Exact Pixel Values
--------------------*/
/*
  These are used to specify exact pixel values in em
  for things like borders that remain constantly
  sized as emSize adjusts

  Since there are many more sizes than names for sizes,
  these are named by their original pixel values.

*/
/* Columns */
/*******************************
             States
*******************************/
/*-------------------
      Disabled
--------------------*/
/*-------------------
        Hover
--------------------*/
/*---  Shadows  ---*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
        Focus
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
    Down (:active)
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
        Active
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*******************************
     User Global Variables
*******************************/
/* marso colors */
/* This is the single variable that controls them all */
/* The size of page text  */
/*
some special class
*/
.stored-wheels {
  background: white;
  width: 75%;
  margin: 15px auto;
  padding: 2rem;
  font-weight: bold;
  border-radius: 5px;
}
.wheel-dropdown {
  width: 30% !important;
  margin-left: 10px;
}
.wheel-dropdown .field-label {
  padding: 10px !important;
}
#wheel-table {
  margin: 15px;
  padding: 1px;
}
.subbox-background {
  position: absolute;
  right: 0;
}
.handwritten {
  font-family: 'Lobster', cursive !important;
  font-weight: normal !important;
}
.invisible {
  display: none !important;
}
.ninja {
  visibility: hidden !important;
}
hr {
  border: none;
  border-top: 1px solid #e5e5e5;
  margin: 2rem 0;
  width: 100%;
}
hr.dark {
  border-color: #cccccc;
}
hr.ninja {
  border: none;
  padding: 0;
}
hr.half {
  margin: 0.5rem 0 !important;
}
hr.narrow {
  margin: 1rem 0 !important;
}
hr.wide {
  margin: 3rem 0 !important;
}
hr.vertical {
  display: inline-block;
  height: 2em;
  padding: 0;
  margin: 0 1rem;
  border: 1px solid #cccccc;
  vertical-align: middle;
}
hr.vertical.dot {
  border-radius: 100%;
  height: 0;
  width: 0;
  border-width: 3px;
}
.text-center {
  text-align: center;
}
.serviceDetailsBtn {
  width: 100%;
}
.ui.button.text-left,
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-nowrap {
  white-space: nowrap;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.clear-both {
  clear: both;
}
.right.floated {
  float: right;
  margin-right: 0em;
  margin-left: 0.25em;
}
.cursor-pointer {
  cursor: pointer;
}
.mw-100 {
  max-width: 100% !important;
}
.ui.message.icon > .icon:not(.close) {
  font-size: 2em !important;
}
.ui.button.tertiary {
  box-shadow: none !important;
  background: none !important;
}
input[type=checkbox] ~ .on,
input[type=radio] ~ .on,
input[type=checkbox]:checked ~ .off,
input[type=radio]:checked ~ .off {
  display: none;
}
input[type=checkbox] ~ .off,
input[type=radio] ~ .off,
input[type=checkbox]:checked ~ .on,
input[type=radio]:checked ~ .on {
  display: inline-block;
}
.radio-label,
.checkbox-label {
  display: block;
  cursor: pointer;
}
.radio-label .on .icon,
.checkbox-label .on .icon,
.radio-label .off .icon,
.checkbox-label .off .icon {
  margin-right: 0 !important;
}
.ui.label {
  border-radius: 0.5rem;
}
.ui.labeled.textarea {
  font-size: 1.1em;
}
.ui.labeled.textarea > .label {
  width: 100%;
  margin: 0;
  font-size: 1.5rem;
  line-height: 1.4em;
  display: block;
}
.ui.labeled.textarea .label:first-child {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.ui.labeled.textarea .textarea {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.ui.label.dark-gray {
  background-color: #333333;
  color: #ffffff;
}
.product .labels {
  position: absolute;
  display: block;
  width: 100%;
  bottom: 0;
  left: 0;
  text-align: left;
}
.product .labels .ui.important.label {
  position: relative;
  margin: 0.5rem 1rem;
  display: block;
  padding-left: 40px !important;
  text-align: left;
  text-transform: uppercase;
}
.product .labels .ui.important.label .icon {
  position: absolute;
  left: -1px;
  top: -0.2em;
  font-size: 1.7em;
}
.result-counter {
  font-size: 1.25rem;
}
.pager-wrapper {
  min-height: 3rem;
}
.pager-wrapper.with-total .pager-column {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.pager-wrapper.with-ordering .pager-column {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.pager-wrapper .total-column {
  border-radius: 0.5rem 0 0 0.5rem !important;
}
.pager-wrapper .total-column .result-counter {
  display: inline-block;
  height: 3rem;
  color: #333333 !important;
  margin-left: 1rem;
  padding: 0.5rem 1rem;
  line-height: 2rem;
  font-weight: bold;
  white-space: nowrap;
}
.pager-wrapper .ordering-column {
  border-radius: 0 0.5rem 0.5rem 0 !important;
}
.pager-wrapper .ordering-column .ordering-select {
  position: relative;
  left: -1rem;
  font-weight: bold !important;
  font-size: 1.25rem;
  height: 3rem;
  padding: 0.5rem 1rem;
  line-height: 2rem;
  background-color: #cccccc;
  white-space: nowrap;
}
.pager-wrapper .ordering-column .ordering-select .icon {
  margin-left: 0.5rem;
  line-height: inherit;
}
.pager-wrapper .ordering-column .ordering-select .menu {
  width: 100%;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.pager-wrapper .ordering-column .ordering-select .menu .item {
  height: 3rem;
  padding: 0.5rem 1rem;
  line-height: 2rem;
  font-size: 1.25rem;
  text-transform: uppercase;
}
.pager-wrapper.pager-wrapper-footer {
  padding-top: 3rem;
}
.ui.form {
  font-size: 1.5rem;
}
.ui.grid.compact {
  margin: 0 !important;
}
.ui.grid.compact > .column {
  padding: 0 !important;
}
.ui.grid.compact > .row {
  padding: 0 !important;
}
.ui.grid.compact > .row > .column {
  padding: 0 !important;
}
.ui.grid.topless > .row:not(:first-child) {
  padding-top: 0 !important;
}
.upper {
  text-transform: uppercase !important;
}
.lower {
  text-transform: lowercase !important;
}
.selectable.table tbody tr {
  cursor: pointer;
}
.ui.table {
  font-size: 1.3rem;
}
.ui.mini.message {
  font-size: 1.1rem;
}
.ui.tiny.message {
  font-size: 1.3rem;
}
.ui.small.message {
  font-size: 1.4rem;
}
.ui.message {
  font-size: 1.5rem;
  margin-top: 2rem !important;
}
.ui.large.message {
  font-size: 1.7rem;
}
.ui.big.message {
  font-size: 2rem;
}
.ui.huge.message {
  font-size: 3rem;
}
.ui.massive.message {
  font-size: 4rem;
}
.ui.buttons.two-part-button .button {
  width: 80%;
  text-align: left;
  padding: 1rem 1rem 1rem 2rem;
  line-height: 4rem;
}
.ui.buttons.two-part-button .button.icon {
  width: 20%;
  text-align: center;
  padding: 1rem;
}
.ui.buttons.two-part-button .button.icon .icon {
  line-height: 4rem;
}
.ui.buttons.two-part-button.small .button {
  line-height: 2rem;
}
.ui.buttons.two-part-button.small .button.icon .icon {
  line-height: 2rem;
}
.ui.mini.buttons .button,
.ui.mini.buttons .or,
.ui.mini.button {
  font-size: 0.9rem;
}
.ui.tiny.buttons .button,
.ui.tiny.buttons .or,
.ui.tiny.button {
  font-size: 1.1rem;
}
.ui.small.buttons .button,
.ui.small.buttons .or,
.ui.small.button {
  font-size: 1.3rem;
}
.ui.buttons .button,
.ui.buttons .or,
.ui.button {
  font-size: 1.5rem;
}
.ui.large.buttons .button,
.ui.large.buttons .or,
.ui.large.button {
  font-size: 1.7rem;
}
.ui.big.buttons .button,
.ui.big.buttons .or,
.ui.big.button {
  font-size: 2rem;
}
.ui.bigger.buttons .button,
.ui.bigger.buttons .or,
.ui.bigger.button {
  font-size: 2.5rem;
}
.ui.huge.buttons .button,
.ui.huge.buttons .or,
.ui.huge.button {
  font-size: 3rem;
}
.ui.massive.buttons .button,
.ui.massive.buttons .or,
.ui.massive.button {
  font-size: 4rem;
}
.ui.button.clear {
  border-color: transparent;
  background-color: transparent;
}
.ui.button.clear:hover,
.ui.button.clear:focus,
.ui.button.clear:active {
  background-color: transparent;
  color: #f0f0f0;
}
.ui.buttons.angular {
  border-radius: 0 !important;
}
.ui.buttons.angular .ui.button:first-child,
.ui.buttons.angular .ui.button:last-child {
  border-radius: 0 !important;
}
.ui.button.link {
  border-color: transparent !important;
  background-color: transparent !important;
  color: #333333;
}
.ui.button.link:hover,
.ui.button.link:focus,
.ui.button.link:active {
  background-color: transparent;
  color: #808080;
}
.ui.button.dark-gray {
  color: #ffffff;
  background-color: #808080;
}
.ui.button.darkest-gray {
  color: #ffffff;
  background-color: #333333;
}
.ui.button.dark.red {
  background-color: #a62c2f !important;
}
.ui.button.red:hover,
.ui.button.red:focus {
  background-color: #a62c2f;
}
.ui.button.primary {
  background-color: #de3a3e;
}
.ui.button.primary:hover,
.ui.button.primary:focus {
  background-color: #a62c2f;
}
.ui.button.white {
  color: #808080;
  background-color: #ffffff;
}
.ui.button.angular {
  border-radius: 0;
}
.ui.button.image {
  padding: 0;
}
.ui.bordered.button {
  border: 0.2rem solid #ffffff;
}
.ui.button.weight {
  font-weight: bold;
}
i.tiny.icon,
i.tiny.icon {
  font-size: 1.1rem;
}
i.mini.icon,
i.mini.icon {
  font-size: 1.3rem;
}
i.icon,
i.icons {
  font-size: 1.5rem;
}
i.large.icon,
i.large.icon {
  font-size: 1.7rem;
}
i.big.icon,
i.big.icon {
  font-size: 2rem;
}
i.bigger.icon,
i.bigger.icon {
  font-size: 2.5rem;
}
i.huge.icon,
i.huge.icon {
  font-size: 3rem;
}
i.massive.icon,
i.massive.icon {
  font-size: 4rem;
}
.ui.segments .segment,
.ui.segment {
  font-size: 1.5rem;
}
.ui.segment.flat {
  box-shadow: none;
  border: none;
}
i.icon.inverted.dark.red {
  background-color: #a62c2f !important;
}
.ui.labeled.icon.button > .icon {
  background: none;
}
.page-title {
  text-transform: uppercase;
  font-size: 4.2rem;
  font-weight: normal;
  padding-top: 2rem;
  padding-bottom: 2rem;
  height: 9rem;
  line-height: 5rem;
  margin-bottom: 0;
}
.page-title .label {
  vertical-align: middle;
}
.page-title-nice {
  font-size: 6rem;
  font-weight: normal;
  color: #de3a3e;
  margin-top: 4rem !important;
  margin-bottom: 1.5rem !important;
}
h2.page-title-nice {
  font-size: 4rem;
}
.bordered-title {
  padding-bottom: 1rem;
  border-bottom: 2px solid #de3a3e;
}
.box-title {
  font-size: 4.2rem;
  font-weight: normal;
}
.ui.header.tyreprint-title {
  background: url(../images/tyreprint.svg) repeat-x top left;
  height: 4rem;
  line-height: 4rem;
  font-size: 4.2rem;
  font-weight: normal;
}
.ui.header.tyreprint-title .text {
  background-color: #ffffff;
  display: inline-block;
  padding: 0 2rem;
  line-height: 4rem;
}
.important-header {
  z-index: -1;
  position: relative;
  font-size: 2.5rem;
  padding: 3rem 0 0 0;
  height: 27rem;
}
.important-header .icon.marso-icon-tyre {
  color: #cccccc;
  font-size: 21rem;
}
.important-header .text {
  z-index: 10;
  color: #de3a3e;
  font-size: 8.5rem;
  line-height: 10rem;
  position: absolute;
  top: 8rem;
  height: 10rem;
  width: 100%;
  display: block;
  margin: 0;
}
.important-header .text:after {
  z-index: -1;
  content: "";
  width: 100%;
  background-color: #ffffff;
  height: 9rem;
  position: absolute;
  top: 20px;
  left: 0;
}
.ui.popup {
  font-size: 1.25rem;
}
.info-block {
  font-size: 2.5rem;
  line-height: 3rem;
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  margin: 0 0 3rem 0 !important;
  padding: 1.5rem 0 !important;
}
.info-block .icon {
  font-size: 6rem !important;
}
.info-block .error-number {
  font-size: 5rem;
  font-weight: bold;
  color: #808080;
}
.ui.menu {
  font-size: 1.5rem;
}
.ui.dropdown .menu .item {
  font-size: 1.5rem;
}
.ui.card .content {
  font-size: 1.6rem;
}
.ui.large.input {
  font-size: 1.6rem;
}
/* more less trick
*/
.more-less-switch .switch:checked ~ .switch-label .on {
  display: block;
}
.more-less-switch .switch:checked ~ .switch-label .off {
  display: none;
}
.more-less-switch .switch:checked ~ .on-content {
  max-height: 999px;
  transition: max-height 1s linear;
}
.more-less-switch .switch ~ .switch-label .on {
  display: none;
}
.more-less-switch .switch ~ .switch-label .off {
  display: block;
}
.more-less-switch .switch ~ .on-content {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.1s linear;
}
.more-less-switch .switch-label {
  cursor: pointer;
  color: #de3a3e;
}
.more-less-switch .switch-label :hover {
  color: #a62c2f;
}
/* manufacturer colors
*/
.bg-mfc-default {
  background-color: #de3a3e;
  color: #ffffff;
}
.text-mfc-default {
  color: #ffffff !important;
}
.ui.header:first-child {
  margin-top: 0;
}
.ui.header.hero {
  font-size: 10rem;
  font-weight: bold;
}
.ui.header.goodboy {
  font-size: 6rem;
}
.ui.header.onimage {
  color: #ffffff;
  text-shadow: 5px 1px 0px rgba(38, 38, 38, 0.95), -1px -1px 5px rgba(38, 38, 38, 0.9);
}
.ui.header.brand {
  color: #de3a3e;
}
.ui.header.thin {
  font-weight: normal !important;
}
/* card */
.ui.card.flat {
  box-shadow: none;
}
.ui.card.flat:hover {
  box-shadow: none !important;
  transform: none !important;
}
.ui.card.rounded {
  border-radius: 10px;
}
.ui.card.rounded > :first-child {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}
.ui.card.rounded > :last-child {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
/* image */
.ui.image.rounded {
  border-radius: 10px;
}
.ui.image.shadow {
  box-shadow: 0px 0px 10px 5px rgba(51, 51, 51, 0.5);
}
/* info label */
.info-labels-wrapper {
  position: absolute;
  width: 100%;
  z-index: 5;
  bottom: 1rem;
}
.info-labels-wrapper .info-label {
  color: #ffffff;
  position: relative;
  min-height: 3rem;
  margin-top: 0.25rem;
}
.info-labels-wrapper .info-label .info-label-icon {
  position: absolute;
  left: 1px;
  z-index: 5;
  background-color: #5a5a5a;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
}
.info-labels-wrapper .info-label .info-label-icon .icon {
  box-shadow: none;
  width: 3rem !important;
  height: 3rem !important;
  font-size: 2rem;
  line-height: 2rem;
  padding: 0.5rem !important;
}
.info-labels-wrapper .info-label .info-label-text {
  display: inline-block;
  max-width: 90%;
  font-size: 1.3rem;
  font-weight: bold;
  border-radius: 0 1.5rem 1.5rem 1.5rem;
  background-color: #808080;
  margin: 0.25rem 0 0.25rem 1.5rem;
  padding: 0.5rem 1rem 0.5rem 2rem;
  line-height: 1;
}
.info-labels-wrapper .info-label.closed .info-label-text {
  display: none;
}
.info-labels-wrapper .info-label.big {
  min-height: 5rem;
  margin-top: 0.5rem;
}
.info-labels-wrapper .info-label.big .info-label-icon {
  width: 5rem;
  height: 5rem;
  line-height: 3rem;
}
.info-labels-wrapper .info-label.big .info-label-icon .icon {
  width: 5rem !important;
  height: 5rem !important;
  font-size: 3rem;
  line-height: 3rem;
  padding: 1rem !important;
}
.info-labels-wrapper .info-label.big .info-label-text {
  font-size: 2rem;
  margin: 0.5rem 0 0.5rem 2.5rem;
  padding: 1rem 2rem 1rem 3rem;
  border-radius: 0 2.5rem 2.5rem 2.5rem;
}
.info-labels-wrapper .info-label.red .info-label-icon {
  background-color: #ae1d21;
}
.info-labels-wrapper .info-label.red .info-label-text {
  background-color: #de3a3e;
}
.info-labels-wrapper .info-label.green .info-label-icon {
  background-color: #0f631d;
}
.info-labels-wrapper .info-label.green .info-label-text {
  background-color: #19a531;
}
.info-labels-wrapper .info-label.blue .info-label-icon {
  background-color: #2b387c;
}
.info-labels-wrapper .info-label.blue .info-label-text {
  background-color: #3f51b5;
}
.info-labels-wrapper .info-label.cetelem .info-label-icon {
  background-color: #ff6633;
}
.info-labels-wrapper .info-label.cetelem .info-label-text {
  background-color: #19a531;
}
/* main elements
 */
html {
  height: auto;
}
body {
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
}
body.simple-header {
  margin-top: 6rem;
}
body .main.container {
  flex: 1;
  flex-basis: auto;
}
button,
input,
optgroup,
select,
textarea {
  font-family: 'Exo 2', 'Lato', Arial, Helvetica, sans-serif;
}
#page-header {
  background-color: #ffffff;
  will-change: auto;
  box-shadow: 0px 2px 4px 0px rgba(34, 36, 38, 0.12), 0px 2px 10px 0px rgba(34, 36, 38, 0.15);
  top: -200px;
  transition: top 0.5s;
}
.fixed-header #page-header {
  position: fixed;
  width: 100%;
  z-index: 999;
  top: 0;
}
/* header top */
.header-top .company-navbar {
  height: 3rem;
  margin: 0 !important;
  padding: 0;
}
.header-top .company-navbar .item {
  height: 3rem;
  font-size: 1.2rem;
  padding: 0.4rem 1rem;
  font-weight: bold;
  color: #333333;
  text-transform: uppercase;
}
.header-top .company-navbar .item.phone {
  padding-left: 0.2rem;
}
.header-top .company-navbar .item.phone:hover {
  color: #de3a3e;
}
.header-top .company-navbar .item.left {
  float: left;
}
.header-top .company-navbar .item.right {
  float: right;
}
/* simple header */
.header-simple {
  background-color: #de3a3e;
}
.header-simple > .container > .grid {
  height: 6rem;
  width: inherit;
}
.header-simple > .container > .grid > .row {
  height: inherit;
}
.header-simple .back-to-site {
  padding: 0;
}
.header-simple .back-to-site > a {
  color: #ffffff;
  height: 4rem;
}
.header-simple .back-to-site > a .column {
  padding: 0 !important;
}
.header-simple .back-to-site > a .label {
  text-align: right;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1.4rem;
}
.header-simple .back-to-site > a > .icon {
  padding-left: 0.5rem;
  line-height: 5rem;
  font-size: 1.8rem;
}
.process .column:first-child {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}
.process .column:first-child:before,
.process .column:first-child:after {
  content: none;
}
.process .column:last-child {
  border-top-right-radius: 45px;
  border-bottom-right-radius: 45px;
}
.process > .column {
  text-align: center;
  background-color: #a62c2f;
  color: #ffffff;
  padding: 0 20px 0 10px;
}
.process > .column:before {
  content: "";
  position: absolute;
  top: 0;
  left: -10px;
  border-top: none;
  border-right: 10px solid #a62c2f;
  border-left: none;
  border-bottom: 20px solid transparent;
}
.process > .column:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -10px;
  border-top: 20px solid transparent;
  border-right: 10px solid #a62c2f;
  border-left: none;
  border-bottom: none;
}
.process > .column.active {
  background-color: #ffffff;
  color: #de3a3e;
}
.process > .column.active:before,
.process > .column.active:after {
  border-right-color: #ffffff;
}
.process > .column.success {
  color: #b2b2b2;
}
.process > .column.success .icon {
  display: inline-block !important;
}
.header-center-wrapper .header-center {
  max-height: 7rem;
  background-color: #de3a3e;
  box-shadow: 0px 2px 4px 0px rgba(34, 36, 38, 0.12), 0px 2px 10px 0px rgba(34, 36, 38, 0.15);
}
.header-center-wrapper .header-center .container {
  height: 7rem;
}
.header-center-wrapper .main .row .search-box-wrapper.column {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.main-menu-button-wrapper .button {
  height: 7rem;
  padding: 0.5rem 0 !important;
  width: 6rem;
  border-radius: 0;
  background-color: #de3a3e !important;
}
.main-menu-button-wrapper .button:hover,
.main-menu-button-wrapper .button.opened {
  background-color: #3f51b5 !important;
}
.main-menu-button-wrapper .button:hover .inside-container,
.main-menu-button-wrapper .button.opened .inside-container {
  border-color: #8c97d3;
}
.main-menu-button-wrapper .button .inside-container {
  display: block;
  height: 6rem;
  padding: 1.8rem 0 1.7rem 0;
  line-height: 2.5rem;
  border-left: 1px solid #e46165;
  border-right: 1px solid #e46165;
}
.main-menu-button-wrapper .button .icon {
  font-size: 2.5rem;
}
#search-box-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.8;
  z-index: 90;
  display: none;
}
#search-box-backdrop.open {
  display: block;
}
#search-box {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  max-height: 40px;
  min-height: 40px;
  height: 40px;
  z-index: 100;
}
#search-box #search-category-dropdown {
  flex: 4;
  margin-right: 3px;
  box-shadow: 0px 4px 0px 0px rgba(38, 38, 38, 0.3);
}
#search-box #search-category-dropdown:after {
  content: " ";
  position: absolute;
  height: 0px;
  width: 0px;
  left: -7px;
  top: 11px;
  border-top: 8px solid transparent;
  border-right: 8px solid #ffffff;
  border-left: none;
  border-bottom: 8px solid transparent;
}
#search-box #search-car-dropdown {
  flex: 3;
  margin-right: 3px;
  box-shadow: 0px 4px 0px 0px rgba(38, 38, 38, 0.3);
}
#search-box #free-hand-search-wrapper {
  position: relative;
  flex: 8;
  display: flex;
  box-shadow: 0px 4px 0px 0px rgba(38, 38, 38, 0.3);
  line-height: 40px;
  float: right;
  z-index: 99;
}
#search-box #free-hand-search-wrapper #free-hand-search-tags {
  flex: 1 0 auto;
  background-color: #ffffff;
  display: inline-block;
  line-height: 37px;
}
#search-box #free-hand-search-wrapper #free-hand-search-tags .ui.label.tag {
  position: relative;
  padding: 10px 5px 10px 8px;
  font-size: 10px;
  line-height: 10px;
  border-radius: 2px;
  margin-left: 12px;
  cursor: pointer;
}
#search-box #free-hand-search-wrapper #free-hand-search-tags .ui.label.tag:before {
  transform: none;
  background-color: transparent;
  position: absolute;
  content: "";
  top: 0;
  left: -10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 10px 15px 0;
  border-color: transparent #e5e5e5 transparent transparent;
}
#search-box #free-hand-search-wrapper #free-hand-search {
  flex: 0 1 100%;
  min-width: 0;
  display: inline-block;
  border: none;
  padding-left: 10px;
}
#search-box #free-hand-search-wrapper #free-hand-search:focus {
  outline: none;
}
#search-box #free-hand-search-wrapper #free-hand-search::placeholder {
  font-size: 18px;
  color: #808080;
  opacity: 1;
}
#search-box #free-hand-search-wrapper #search-info {
  display: none;
  position: absolute;
  top: 40px;
  left: 0;
  background-color: #ffffff;
  line-height: 20px;
  z-index: 100;
  width: 100%;
  box-shadow: 0px 2px 3px 0px rgba(38, 38, 38, 0.15);
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
#search-box #free-hand-search-wrapper #search-info .results .item {
  cursor: pointer;
}
#search-box #free-hand-search-wrapper #search-info .results .item.selected {
  background-color: #e5e5e5;
}
#search-box #free-hand-search-wrapper #search-info .items {
  padding-bottom: 1rem;
  margin-bottom: 0;
}
#search-box #free-hand-search-wrapper #search-info .items .item {
  font-size: 1.5rem;
  height: 3rem;
  line-height: 2rem;
  padding: 0.5rem 2rem;
  color: #262626;
  margin: 0;
}
#search-box #free-hand-search-wrapper #search-info .items .item .icon {
  color: #b2b2b2;
}
#search-box #free-hand-search-wrapper #search-info .items .item .content {
  font-size: 1.5rem;
}
#search-box #free-hand-search-wrapper #search-info .items .item.header {
  height: 4rem;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  font-weight: bold;
  margin-bottom: 1rem;
}
#search-box #free-hand-search-wrapper #search-info .items .item.header .content {
  font-size: 1.75rem;
}
#search-box #free-hand-search-wrapper #search-info .items .detailed-search {
  border-top: 1px solid #e5e5e5;
  background-color: #de3a3e;
  color: #ffffff;
  font-weight: bold;
  text-align: right;
}
#search-box #free-hand-search-wrapper #search-info .mass-tags .header {
  height: 4rem;
  line-height: 3rem;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  font-weight: bold;
  font-size: 1.75rem;
  margin-bottom: 1rem;
  padding: 0.5rem 2rem;
}
#search-box #free-hand-search-wrapper #search-info .mass-tags .tags {
  padding: 1rem;
}
#search-box #free-hand-search-wrapper #search-info .mass-tags .tags .add-tag-button {
  padding: 0.5rem 1rem;
  font-size: 1.5rem;
}
#search-box #free-hand-search-wrapper #search-info .mass-tags .tags .add-tag-button:hover {
  color: #333333;
}
#search-box #free-hand-search-wrapper #free-hand-search.focus ~ #search-info {
  display: block;
}
#search-box #free-hand-search-button {
  flex: 1;
  box-shadow: 0px 4px 0px 0px rgba(38, 38, 38, 0.3);
  border-radius: 0.5rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 40px;
  min-width: 6rem;
  float: right;
  line-height: 2rem;
  padding: 0;
  font-size: 1.5rem;
  z-index: 99;
}
#search-box #free-hand-search-button .icon {
  font-size: 2.5rem;
  vertical-align: text-bottom;
  margin: 0;
  padding: 0;
  opacity: 1;
}
#search-box #free-hand-search-button .waiting.icon {
  display: none;
}
#search-box #free-hand-search-button.waiting .icon {
  display: none;
}
#search-box #free-hand-search-button.waiting .waiting.icon {
  display: inline-block;
  animation: icon-loading 2s ease-out infinite;
}
#search-box .ui.dropdown {
  min-height: 40px;
  height: 40px;
  line-height: 20px;
  padding: 0 0 0 10px;
  margin: 0;
  z-index: 99;
  display: inline-block;
  border: 0;
  background-color: #ffffff;
}
#search-box .ui.dropdown .icon {
  font-size: 1.2rem;
}
#search-box .ui.dropdown .title {
  position: relative;
  top: 6px;
  color: #333333;
  font-size: 1.6rem;
}
#search-box .ui.dropdown .title.no-label {
  position: static !important;
}
#search-box .ui.dropdown .label {
  display: block;
  font-size: 10px;
  vertical-align: bottom;
  color: #808080;
  position: absolute;
  top: -8px;
}
#search-box .ui.dropdown > .text {
  line-height: 40px;
  height: inherit;
  overflow: hidden;
}
#search-box .ui.dropdown > .text.default {
  color: #333333;
}
#search-box .ui.dropdown .item {
  padding: 10px !important;
}
#search-box .ui.dropdown .item .label {
  display: none;
}
#search-box .ui.dropdown .item .title {
  position: static;
}
#search-box .ui.dropdown .menu {
  border: none;
  margin: 0;
}
#search-box .ui.dropdown.search-category {
  max-width: 120px;
  min-width: 120px;
  border-radius: 0.5rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
#search-box .ui.dropdown.search-category .menu {
  width: 120px;
  min-width: 120px;
}
#search-box .ui.dropdown.search-car {
  max-width: 90px;
  min-width: 90px;
  border-radius: 0;
}
#search-box .ui.dropdown.search-car .menu {
  width: 90px;
  min-width: 90px;
}
#user-menu > .grid {
  margin: 0;
  float: right;
}
#user-menu > .grid > .item {
  line-height: 10px;
  margin: 0 -1px 0 0;
  padding: 0.5rem 0;
  color: #ffffff;
  max-width: 6rem;
  min-width: 6rem;
  text-transform: uppercase;
}
#user-menu > .grid > .item:hover,
#user-menu > .grid > .item.hover {
  background-color: #3f51b5;
}
#user-menu > .grid > .item:hover:first-child .menu-content-wrapper,
#user-menu > .grid > .item.hover:first-child .menu-content-wrapper {
  border-color: #3f51b5;
}
#user-menu > .grid > .item:hover .menu-content-wrapper,
#user-menu > .grid > .item.hover .menu-content-wrapper {
  border-color: #3f51b5;
}
#user-menu > .grid > .item:first-child .menu-content-wrapper {
  border-left: 1px solid #e46165;
}
#user-menu > .grid > .item .menu-content-wrapper {
  height: 6rem;
  padding: 1rem 0;
  border-right: 1px solid #e46165;
}
#user-menu > .grid > .item .icon {
  font-size: 2.4rem;
  height: 2.8rem;
  display: block;
  margin: 0 auto;
}
#user-menu > .grid > .item small {
  font-size: 1.2rem;
}
#user-menu #menu-button-my-cart:hover .marso-icon-shopping_cart .counter,
#user-menu #menu-button-my-cart.hover .marso-icon-shopping_cart .counter {
  background-color: #de3a3e;
}
#user-menu #menu-button-my-cart .marso-icon-shopping_cart {
  position: relative;
}
#user-menu #menu-button-my-cart .marso-icon-shopping_cart .counter {
  position: absolute;
  bottom: 12px;
  right: -10px;
  margin-top: inherit !important;
  background-color: #3f51b5;
  color: #ffffff;
}
#user-menu .ui.popup.header-popup {
  border-radius: 0;
  background-color: #3f51b5;
  color: #ffffff;
  margin-top: 0;
  border: none;
  right: 1rem !important;
  padding: 1rem 0;
}
#user-menu .ui.popup.header-popup .menu {
  width: auto;
}
#user-menu .ui.popup.header-popup .menu .item {
  color: #ffffff;
  margin: 0;
  padding: 0.5rem 1rem;
}
#user-menu .ui.popup.header-popup .menu .item .header {
  text-transform: uppercase;
  border-bottom: 2px solid #20295b;
  padding-bottom: 1rem;
  margin: 0;
}
#user-menu .ui.popup.header-popup .menu a.item:hover {
  background-color: #20295b;
}
#user-menu .ui.popup.header-popup:before {
  content: none;
  display: none;
}
#user-menu .ui.popup.header-popup.my-service,
#user-menu .ui.popup.header-popup.my-car {
  padding-top: 20px;
  padding-bottom: 15px;
}
#user-menu .ui.popup.header-popup.my-service .item.description,
#user-menu .ui.popup.header-popup.my-car .item.description {
  margin-top: 15px;
}
#user-menu .ui.popup.header-popup.my-service .item.goto-button,
#user-menu .ui.popup.header-popup.my-car .item.goto-button {
  margin-top: 15px;
}
#user-menu .ui.popup.header-popup.my-service .item .button,
#user-menu .ui.popup.header-popup.my-car .item .button {
  background-color: #de3a3e;
  text-transform: uppercase;
  color: #ffffff;
  text-align: left;
}
#user-menu .ui.popup.header-popup.my-service .item.description .right.content,
#user-menu .ui.popup.header-popup.my-car .item.description .right.content {
  font-weight: bold;
}
#user-menu .ui.popup.header-popup.my-service .select-service,
#user-menu .ui.popup.header-popup.my-car .select-service,
#user-menu .ui.popup.header-popup.my-service .select-car,
#user-menu .ui.popup.header-popup.my-car .select-car {
  color: #262626 !important;
}
#user-menu .ui.popup.header-popup.my-service .select-service .default.text,
#user-menu .ui.popup.header-popup.my-car .select-service .default.text,
#user-menu .ui.popup.header-popup.my-service .select-car .default.text,
#user-menu .ui.popup.header-popup.my-car .select-car .default.text {
  color: #262626;
}
#user-menu .ui.popup.header-popup.my-service .select-service .item,
#user-menu .ui.popup.header-popup.my-car .select-service .item,
#user-menu .ui.popup.header-popup.my-service .select-car .item,
#user-menu .ui.popup.header-popup.my-car .select-car .item {
  color: #262626;
}
#user-menu .ui.popup.header-popup.my-service .select-service .item input.search::placeholder,
#user-menu .ui.popup.header-popup.my-car .select-service .item input.search::placeholder,
#user-menu .ui.popup.header-popup.my-service .select-car .item input.search::placeholder,
#user-menu .ui.popup.header-popup.my-car .select-car .item input.search::placeholder {
  color: #262626;
}
#logo-image img {
  max-height: 35px;
}
.main-menu-box {
  max-height: 45rem;
  min-height: 45rem;
}
.main-menu-box .store-menu {
  height: 5rem;
  padding: 5%;
}
.main-menu-box .store-menu .item {
  display: inline-block;
  width: 49%;
  padding: 0 4%;
}
.main-menu-box .store-menu .item img {
  width: 100%;
}
.main-menu-box .panel-left {
  z-index: 99;
  background-color: #3f51b5;
}
.main-menu-box .panel-left .menu {
  padding: 1rem 0;
  font-size: 1.6rem;
}
.main-menu-box .panel-left .menu .item {
  display: block;
  color: #ffffff;
  padding: 0.5rem 1rem 0.5rem 2rem;
  height: 3.2rem;
}
.main-menu-box .panel-left .menu .main.item {
  height: 4rem;
  font-weight: bold;
  font-size: 2rem;
  padding: 0 1.5rem;
  text-transform: uppercase;
}
.main-menu-box .panel-left .menu .main.item .text {
  height: 4rem;
  display: block;
  border-bottom: 1px solid #20295b;
  padding: 1rem 0;
}
.main-menu-box .panel-left .menu .main.item:first-child .text {
  border-top: none;
}
.main-menu-box .panel-left .menu .main.item.last .text {
  border-bottom: none;
}
.main-menu-box .panel-left .menu .item.active {
  background-color: #20295b;
}
.main-menu-box .panel-left .menu .main.item.active .text {
  border-color: #20295b;
}
.main-menu-box .panel-left .menu .item.need-subbox .icon {
  float: right;
}
.main-menu-box .right-subbox {
  z-index: 99;
  background-color: #ffffff;
  background-position: bottom right;
  background-repeat: no-repeat;
}
.main-menu-box .right-subbox .content-wrapper {
  padding: 3rem;
}
.main-menu-box .right-subbox .content-wrapper .menu {
  padding-bottom: 1rem;
}
.main-menu-box .right-subbox .content-wrapper .menu .item {
  display: block;
  padding: 0.5rem 0 0;
  color: #333333;
}
.main-menu-box .right-subbox .content-wrapper .menu a.item:hover {
  color: #20295b;
}
.main-menu-box .right-subbox .content-wrapper .menu .item.main {
  border-bottom: 1px solid #20295b;
  text-transform: uppercase;
  font-size: 1.8rem;
  line-height: 2rem;
  font-weight: bold;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}
.main-menu-box .right-subbox .ui.menu {
  margin: 0;
}
.main-menu-box .right-subbox .ui.menu .item {
  margin: 0 !important;
  padding: 0.5rem 0;
}
#page-footer {
  background-color: #333333;
  color: #ffffff;
}
#page-footer .modal {
  color: #333333;
}
#page-footer #footer-top {
  padding-top: 20px;
}
#page-footer #footer-top h3 {
  text-transform: uppercase;
  font-size: 16px;
  padding-bottom: 10px;
  border-bottom: 1px solid #de3a3e;
}
#page-footer #footer-top > .ui.container > .ui.grid {
  margin-top: 0;
  margin-bottom: 0;
}
#page-footer #footer-top .menu {
  width: 100%;
}
#page-footer #footer-top .menu .item {
  color: #ffffff;
  font-size: 15px;
  margin: 0;
  padding: 2px 0;
}
#page-footer #footer-top .menu a.item:hover {
  color: #de3a3e;
}
#page-footer #footer-top #footer-brands {
  margin-left: 0;
}
#page-footer #footer-top #footer-brands .grid {
  margin-top: 0;
  max-width: 100%;
}
#page-footer #footer-top #footer-brands .grid .column {
  padding-top: 0;
  padding-bottom: 0;
}
#page-footer #footer-top #footer-brands .grid .column .item {
  padding: 0.2rem 0.5rem 0.2rem 0;
}
#page-footer #footer-top #footer-social-icons {
  margin-left: 0;
}
#page-footer #footer-top #footer-social-icons .item:first-child {
  padding-left: 0;
}
#page-footer #footer-top #footer-social-icons .item {
  padding: 0 0.5rem;
}
#page-footer #footer-top #footer-social-icons .item .button {
  padding: 1rem;
  line-height: 2rem;
}
#page-footer #footer-top #footer-social-icons .item .button .icon {
  vertical-align: middle;
  font-size: 2rem;
  width: 2rem;
}
#page-footer #footer-top #newsletter-subscribe-form input {
  background-color: #666666;
  color: #ffffff;
  font-size: 1.6rem;
  padding: 1rem 2rem;
}
#page-footer #footer-top #newsletter-subscribe-form input::placeholder {
  text-transform: uppercase;
  color: #b2b2b2;
}
#page-footer #footer-top #newsletter-subscribe-form button {
  text-align: left;
  text-transform: uppercase;
}
#page-footer #footer-center {
  background-color: #262626;
}
#page-footer #footer-center .grid {
  margin-top: 0;
  margin-bottom: 0;
}
#page-footer #footer-center .grid .column {
  padding-top: 0;
  padding-bottom: 0;
}
#page-footer #footer-center .menu {
  margin-top: 0;
  margin-bottom: 0;
}
#page-footer #footer-center .menu .item {
  height: 50px;
  color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;
}
#page-footer #footer-center .menu a:hover {
  color: #de3a3e;
}
#page-footer #footer-bottom {
  font-size: 1.1rem;
}
#page-footer #footer-bottom .container {
  text-align: center;
  padding: 3px 0;
}
#page-footer #footer-bottom .container .item {
  color: #ffffff;
  border-right: 1px solid #ffffff;
  padding: 0 12px;
}
#page-footer #footer-bottom .container .item:last-child {
  border-right: none;
}
.product .product-name-wrapper .manufacturer-name {
  font-weight: bold;
  color: #333333;
}
.product .product-name-wrapper .name {
  font-weight: bold;
  color: #333333;
}
.product .product-name-wrapper .tread-name {
  color: #808080;
}
.product .product-name-wrapper .size {
  color: #808080;
}
.product .product-name-wrapper .service-product-name {
  font-weight: bold;
}
.product .product-name-wrapper.big .manufacturer-name {
  font-size: 2rem;
  line-height: 3rem;
}
.product .product-name-wrapper.big .tread-name {
  font-size: 2rem;
  line-height: 3rem;
}
.product .product-name-wrapper.big .size {
  font-size: 2rem;
  line-height: 3rem;
}
.product .product-name-wrapper.huge {
  font-size: 2rem;
  line-height: 3rem;
}
.product .product-name-wrapper.huge .manufacturer-name {
  font-size: 3.5rem;
  line-height: 4rem;
}
.product .storage-status {
  color: #808080;
  font-size: 1.5rem;
  line-height: 2.5rem;
  position: relative;
  cursor: pointer;
}
.product .storage-status .icon {
  font-size: 2rem;
  line-height: 2.5rem;
}
.product .storage-status.small {
  font-size: 1.25rem;
  height: 2rem;
  line-height: 2rem;
}
.product .storage-status.small .icon {
  font-size: 1.5rem;
  line-height: 2rem;
}
.product .storage-status.huge {
  font-size: 2rem;
  line-height: 3rem;
}
.product .storage-status.huge .icon {
  font-size: 2.75rem;
  line-height: 3rem;
}
.product.teaser.rim .product-name-wrapper.big .size {
  font-size: 1.5rem;
}
.storage-status.in-stock > .icon {
  color: #19a531;
}
.storage-status.low-stock > .icon {
  color: #ff9533;
}
.storage-status.out-of-stock > .icon {
  color: #de3a3e;
}
.storage-status.request-to-stock > .icon {
  color: #de3a3e;
}
/* product page
*/
.product-main {
  padding-top: 3rem;
}
.product-main .product-data-block {
  padding-left: 3rem;
  border-left: 1px solid #e5e5e5;
}
.product-main .other-sizes {
  font-size: 1.4rem;
  float: right;
  text-transform: none;
  line-height: 3rem;
}
.product-main .other-sizes .dropdown {
  border-radius: 0.5rem;
  margin-top: 0.6rem;
  padding: 0 0.5rem 0 1rem;
  color: #808080;
  background-color: #e5e5e5;
}
.product-main .other-sizes .dropdown .default.text {
  color: #333333;
}
.product-main .image-illustrative-notice {
  position: absolute;
  right: -100%;
  margin-right: 1rem;
  bottom: 0;
  width: 100%;
  font-size: 1.25rem;
  transform: rotate(-90deg);
  transform-origin: left top;
}
.product-main .product-image-wrapper {
  padding: 4rem;
}
.product-main .product-image-wrapper > .image-link {
  display: block;
  text-align: center;
}
.product-main .product-meta-wrapper .product-tyre-data-wrapper {
  padding-top: 2rem;
}
.product-main .product-meta-wrapper .product-tyre-data {
  border-radius: 0.5rem;
  border: 1px solid #cccccc;
}
.product-main .product-meta-wrapper .product-tyre-data .column {
  padding: 0.5rem 0 !important;
  height: 5rem;
}
.product-main .product-meta-wrapper .product-tyre-data .column .wrapper {
  height: 4rem;
  border-left: 1px solid #cccccc;
  padding: 0.5rem 1.5rem;
  line-height: 3rem;
  text-align: center;
  white-space: nowrap;
}
.product-main .product-meta-wrapper .product-tyre-data .column .wrapper .icon {
  color: #808080;
  vertical-align: middle;
  font-size: 2rem;
}
.product-main .product-meta-wrapper .product-tyre-data .column .wrapper .value {
  color: #808080;
  display: inline-block;
  vertical-align: middle;
}
.product-main .product-meta-wrapper .product-tyre-data .column:first-child .wrapper {
  border-left: none;
}
.product-main .product-meta-wrapper .product-tags-wrapper {
  padding-top: 1.5rem;
}
.product-main .product-meta-wrapper .product-tags-wrapper .label {
  margin-bottom: 0.75rem;
}
.product-main .add-to-cart-button.ui.button {
  font-size: 3rem;
}
.product-main .product-tags-wrapper {
  padding-bottom: 1rem;
}
.product-main .product-tags-wrapper .label {
  margin-bottom: 0.75rem;
}
.product-price-wrapper {
  padding-top: 2rem;
}
.product-price-wrapper .original-price {
  font-size: 20px;
  text-decoration: line-through;
}
.product-price-wrapper .product-price {
  padding-top: 1rem;
}
.product-price-wrapper .product-price .retail-price-brutto {
  font-size: 5rem;
  line-height: 5rem;
  font-weight: bold;
}
.product-price-wrapper .product-price .retail-price-brutto.highlighted {
  color: #de3a3e;
}
.product-price-wrapper .product-price .retail-price-brutto.small {
  font-size: 2rem;
}
.product-price-wrapper .product-price .retail-price-netto {
  padding-top: 1rem;
}
.product-price-wrapper .product-price.dismissed {
  text-decoration: line-through;
}
.product-price-wrapper .product-price.highlighted {
  color: #de3a3e;
}
.product-cart-wrapper {
  padding-top: 2rem;
}
.product-cart-wrapper .total-price {
  font-size: 1.6rem;
}
.product-cart-wrapper .total-price .label {
  padding: 0 0.5rem 0 1em !important;
  line-height: 4rem;
  font-weight: normal;
  background-color: #e5e5e5;
}
.product-cart-wrapper .total-price input {
  padding: 0 1rem !important;
  font-weight: bold;
}
.product-cart-wrapper .add-to-cart-button.ui.button {
  color: #ffffff;
}
.product-cart-wrapper .quantity-wrapper .quantity-stepper-wrapper .quantity-stepper {
  font-size: 1.6rem;
  width: 100%;
}
.product-cart-wrapper .quantity-wrapper .quantity-stepper-wrapper input {
  background-color: #e5e5e5 !important;
  border-color: #e5e5e5 !important;
}
.product-cart-wrapper .quantity-wrapper .quantity-stepper-wrapper .product-quantity-input {
  font-weight: bold;
  text-align: right;
  height: 6rem;
  padding: 0.5rem 1rem;
  width: 5rem;
  max-width: 5rem;
  font-size: 2.5rem;
}
.product-cart-wrapper .quantity-wrapper .quantity-stepper-wrapper .product-quantity-input:focus {
  outline: none;
  border-color: #e5e5e5 !important;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.product-cart-wrapper .quantity-wrapper .quantity-stepper-wrapper .quantity-label {
  position: relative;
  padding: 0.5rem 3rem 0.5rem 0 !important;
  line-height: 5rem;
  font-weight: normal;
  background-color: #e5e5e5;
  width: 6rem;
  max-width: 6rem;
  font-size: 2rem;
}
.product-cart-wrapper .quantity-wrapper .quantity-stepper-wrapper .quantity-label .buttons {
  position: absolute;
  right: 0;
  top: 0;
  width: 3rem;
  padding: 0;
}
.product-cart-wrapper .quantity-wrapper .quantity-stepper-wrapper .quantity-label .buttons .button {
  height: 3rem;
  width: 3rem;
  padding: 0;
  background-color: #b2b2b2;
  font-weight: bold;
}
.product-cart-wrapper .quantity-wrapper .quantity-stepper-wrapper .quantity-label .buttons .button .icon {
  margin: 0;
  line-height: 3rem;
  width: 3rem;
  font-size: 3rem;
}
.product-cart-wrapper .quantity-wrapper .quantity-stepper-wrapper .quantity-label .buttons .button.quantity-up {
  background-color: #cccccc;
  border-top-left-radius: 0;
  text-align: center;
}
.product-cart-wrapper .quantity-wrapper .quantity-stepper-wrapper .quantity-label .buttons .button.quantity-down {
  border-bottom-left-radius: 0;
}
.products .product.teaser {
  display: flex;
  flex-flow: column;
  height: 100%;
  padding: 1rem;
  color: #333333;
  position: relative;
}
.products .product.teaser:hover {
  box-shadow: 0px -1px 1px 3px rgba(128, 128, 128, 0.3), 0px 1px 1px 2px rgba(128, 128, 128, 0.3) !important;
  border-radius: 1rem;
}
.products .product.teaser .image-wrapper {
  background-color: transparent;
  max-height: 155px;
  height: 155px;
  overflow: hidden;
  position: relative;
}
.products .product.teaser .image-wrapper .image {
  margin: auto;
}
.products .product.teaser .image-wrapper:before {
  content: "";
  display: block;
  position: relative;
  border: 1px solid #333333;
  background-color: #333333;
  border-radius: 99rem;
  height: 5px;
  top: 150px;
  z-index: 1;
}
.products .product.teaser .data-wrapper {
  flex: 1;
  padding: 0;
  border-top: none;
}
.products .product.teaser .data-wrapper > .grid {
  height: 100%;
}
.products .product.teaser .data-wrapper .product-name-wrapper {
  padding-top: 0.5rem !important;
}
.products .product.teaser .data-wrapper .product-storage-status-wrapper {
  padding: 0.75rem 0;
  border-bottom: 2px solid #e5e5e5;
}
.products .product.teaser .data-wrapper .product-data-attribute-icons-wrapper {
  padding: 0.75rem 0;
  border-bottom: 2px solid #e5e5e5;
}
.products .product.teaser .data-wrapper .product-price-wrapper {
  padding: 1rem 0;
  flex: 1;
}
.products .product.teaser .data-wrapper .product-price-wrapper .product-price {
  font-weight: bold;
  font-size: 3rem;
}
.products .product.teaser .data-wrapper .product-price-wrapper .product-price.dismissed {
  font-size: 2.25rem;
}
.products .product.teaser .data-wrapper .vertical-data {
  padding-left: 1.5rem !important;
}
.products .product.teaser .data-wrapper .vertical-data > .grid {
  height: 100%;
}
.products .product.teaser .data-wrapper .product-tyre-data-wrapper {
  background-color: #f0f0f0;
  border-bottom-left-radius: 0.5rem;
}
.products .product.teaser .data-wrapper .product-tyre-data-wrapper .grid .wrapper .icon {
  color: #999999;
  font-size: 2.4rem;
  line-height: 2.5rem;
}
.products .product.teaser .data-wrapper .product-tyre-data-wrapper .grid .column:first-child .wrapper {
  border-left: 0;
  border-top: 0;
}
.products .product.teaser .data-wrapper .product-tyre-data-wrapper .horizontal .column .wrapper {
  margin: 4px 4px 4px 0;
  padding: 0 0 0 4px;
  height: 42px;
  border-left: 1px solid #cccccc;
  text-align: center;
}
.products .product.teaser .data-wrapper .product-tyre-data-wrapper .horizontal .column .wrapper .icon {
  margin-right: 0;
}
.products .product.teaser .data-wrapper .product-tyre-data-wrapper .horizontal .column .value {
  display: block;
  line-height: 15px;
  font-size: 1.3rem;
}
.products .product.teaser .data-wrapper .product-tyre-data-wrapper .horizontal .column.noise .value {
  white-space: nowrap;
}
.products .product.teaser .data-wrapper .product-tyre-data-wrapper .vertical {
  min-height: 18rem;
}
.products .product.teaser .data-wrapper .product-tyre-data-wrapper .vertical .column {
  height: 25%;
}
.products .product.teaser .data-wrapper .product-tyre-data-wrapper .vertical .column .wrapper {
  height: 100%;
  border-top: 1px solid #cccccc;
  margin: 0 1rem;
  padding: 1rem 0;
  color: #999999;
  white-space: nowrap;
}
.products .product.teaser .data-wrapper .product-tyre-data-wrapper .vertical .column .wrapper .icon {
  vertical-align: middle;
}
.products .product.teaser .data-wrapper .product-tyre-data-wrapper .vertical .column .wrapper .value {
  vertical-align: middle;
  font-size: 1.75rem;
  line-height: 2rem;
}
.products .product.teaser .data-wrapper .product-tyre-data-wrapper .vertical .column.season .icon {
  margin-right: 0;
}
.products .product.teaser .data-wrapper .product-tyre-data-wrapper .vertical .column.noise .icon {
  max-width: 40%;
  font-size: 2.2rem;
}
.products .product.teaser .data-wrapper .product-tyre-data-wrapper .vertical .column.noise .value {
  font-size: 1.5rem;
}
.products .product.teaser .button-wrapper {
  padding: 1rem 0 0;
  border-top: none;
  flex: inherit;
}
.products .product.teaser.truck_tyre .data-wrapper .product-tyre-data-wrapper .vertical .column {
  height: 33%;
}
.products .product.teaser.service .image-wrapper {
  position: relative;
}
.products .product.teaser.service .image-wrapper .name {
  width: 100%;
  position: absolute;
  bottom: 10px;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 1px 1px 2px rgba(38, 38, 38, 0.95), -1px -1px 2px rgba(38, 38, 38, 0.9);
}
.products .product.teaser.service .data-wrapper {
  height: 100%;
  background: linear-gradient(to right, #e5e5e5 18.75%, #ffffff 0px);
}
.products .product.teaser.service .data-wrapper .grid {
  height: 100%;
}
.products .product.teaser.service .data-wrapper .icon-wrapper {
  color: #b2b2b2;
  margin-right: 0;
}
.products .product.teaser.service .data-wrapper .icon-wrapper .icon {
  margin-right: 0;
}
.products .product.teaser.service .data-wrapper .description-wrapper {
  padding: 0.5rem 0.5rem 0 0.5rem !important;
}
.products .teaser.wide .image-wrapper {
  max-height: 205px;
  height: 205px;
}
.products .teaser.wide .image-wrapper:before {
  top: 200px;
}
/*
add to cart modal */
#add-to-cart-modal {
  border-radius: 25px;
}
#add-to-cart-modal > .content {
  padding: 2rem;
  border-radius: inherit;
}
#add-to-cart-modal .header {
  text-transform: uppercase;
  font-size: 3rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e5e5e5;
}
#add-to-cart-modal .footer {
  padding-top: 1rem;
  border-top: 1px solid #e5e5e5;
}
#add-to-cart-modal .footer .question-box {
  color: #333333;
  position: relative;
  border-top-left-radius: 7rem;
  border-bottom-left-radius: 7rem;
  background-color: #e5e5e5;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  height: 11rem;
}
#add-to-cart-modal .footer .question-box .image {
  position: absolute;
  left: 0;
  top: 0;
  height: 11rem;
  line-height: 11rem;
  width: 11rem;
  display: block;
  background-color: #19a531;
}
#add-to-cart-modal .footer .question-box .image .icon {
  font-size: 9rem;
  color: #ffffff;
  line-height: 11rem;
}
#add-to-cart-modal .footer .question-box .yes-or-no {
  min-width: 25rem;
  height: auto;
}
#add-to-cart-modal .footer .question-box .yes-or-no .button {
  text-transform: uppercase;
  font-size: 2.2rem;
  padding: 1.5rem 2.5rem;
  margin: 0 0.8rem;
}
#add-to-cart-modal .footer .question-box .question {
  text-transform: uppercase;
  font-size: 2.5rem;
  margin-left: 0;
  padding-left: 12rem;
}
#add-to-cart-modal .footer .goto-cart-url {
  margin: auto;
  width: 20rem;
  text-align: center;
  display: block;
  padding: 2rem 0 0;
  text-transform: uppercase;
  color: #333333;
  height: 4rem;
  line-height: 2rem;
  font-size: 1.5rem;
}
#add-to-cart-modal .footer .goto-cart-url .icon {
  font-size: 1.4rem;
  line-height: 2rem;
  height: 2rem;
  vertical-align: middle;
}
#add-to-cart-modal .product-price-wrapper {
  text-align: right;
  font-size: 2.5rem;
  line-height: 1.5em;
}
#add-to-cart-modal .product-price-wrapper .price-label {
  font-size: 1.5rem;
  line-height: 1.5rem;
}
#add-to-cart-modal .product-price-wrapper .total-price-brutto {
  font-weight: bold;
}
#add-to-cart-modal .product-price-wrapper .product-amount {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
#add-to-cart-modal .product-price-wrapper .product-amount .value {
  font-weight: bold;
}
.product-descriptions .tab-menu-panel {
  padding-top: 8rem;
}
.product-data-attribute-icons-wrapper .product-attribute-icon.icon {
  color: #ffffff;
  background-color: #808080;
  box-shadow: none !important;
  width: 3rem !important;
  height: 3rem !important;
  font-size: 2rem;
  line-height: 2rem !important;
  padding: 0.5rem !important;
}
.product-data-attribute-icons-wrapper .product-attribute-icon.big {
  float: left;
  clear: left;
  font-size: 3rem;
  line-height: 3rem !important;
  padding: 1rem !important;
  width: 5rem !important;
  height: 5rem !important;
  margin-bottom: 1rem;
}
.product-main .product-data-attribute-icons-wrapper {
  position: absolute;
  z-index: 50;
}
.status-sold-out .sold-out {
  position: absolute !important;
  z-index: 9;
  left: 0;
  top: 40%;
  transform: rotate(325deg);
  width: 100%;
  text-align: center;
  color: #de3a3e;
  font-weight: bold;
  font-size: 8rem;
  text-transform: uppercase;
  opacity: 0.9;
}
.status-sold-out.teaser .sold-out {
  font-size: 3.5rem;
}
.status-sold-out.wide .sold-out {
  font-size: 4rem;
}
.product-landing {
  position: relative;
  margin-top: 0 !important;
}
.product-landing .product-box-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.product-landing .product-box {
  font-size: 1em;
  position: sticky;
  left: 9em;
  top: 10em;
  width: 33em;
  height: auto;
  background-color: white;
  border-radius: 0.5em;
  margin: 2em;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  float: left;
}
.product-landing .product-box .product-price-wrapper {
  padding-top: 0rem;
}
.product-landing .product-box .product-main {
  padding-top: 0;
  padding-bottom: 3rem;
}
.product-landing .product-box .product-main .ui.grid {
  margin: -1.8rem;
}
.product-landing .product-box .product-image {
  width: max-content;
  height: 50%;
}
.product-landing .product-box .product-image-wrapper {
  top: 2rem;
  height: 50%;
  width: 100%;
  display: inline-block;
  box-sizing: content-box;
  padding: 0 !important;
}
.product-landing .product-box .manufacturer-name {
  text-transform: uppercase;
}
.product-landing .product-box .product-cart-wrapper {
  padding-right: 1rem;
}
.product-landing .product-box .product.teaser .image-wrapper {
  height: 231px;
  max-height: 231px;
}
.product-landing .product-box .products .product.teaser .image-wrapper:before {
  position: relative;
  border: none !important;
  border-bottom: 1px solid #707070 !important;
  background-color: #707070 !important;
  border-radius: 0rem;
  height: 1px;
  top: 230px;
  z-index: 1;
  width: 94%;
  left: 3%;
}
.product-landing .product-box .product-price-wrapper .product-price {
  padding-top: 0rem !important;
}
.product-landing .product-box .product-price-wrapper .product-price .retail-price-netto {
  padding-top: 0rem !important;
}
.product-landing .product-box .product-data-attribute-icons-wrapper .product-attribute-icon.big {
  float: left;
  clear: left;
  font-size: 2em;
  line-height: 1em !important;
  padding: 0.2em !important;
  width: 1em !important;
  height: 1em !important;
  margin-bottom: 0.2em;
}
.product-landing .product-box .status-sold-out .sold-out {
  top: 61%;
  font-size: 4em;
}
.product-landing .product-box .product-main .product-data-attribute-icons-wrapper {
  left: 2em;
  top: 6em;
}
@media (max-width: 1560px) {
  .product-landing .product-box {
    top: 6em !important;
    left: 5em !important;
  }
  .product-landing .product-box .product-box-data {
    margin-top: -2em !important;
  }
}
.product-landing .product .product-name-wrapper.huge .manufacturer-size {
  font-size: 3.5rem;
}
.product-landing .product-main .product-data-block {
  border-left: none;
  padding-right: 3rem;
}
.product-landing .products .product.teaser:hover {
  box-shadow: none !important;
}
.product-landing .product-box .products .product.teaser .image-wrapper.image {
  padding-top: 3rem;
}
.product-landing .product-box .products .product.teaser .info-labels-wrapper {
  box-sizing: border-box;
  padding: 0 1rem;
}
.product-landing .product-box .product-main .other-sizes {
  margin-top: 0.56rem;
}
.product-landing .product-manufacturer-logo {
  height: 6rem;
  left: 1.2rem;
  top: 1.2rem;
  position: absolute;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 161px;
  justify-content: center;
  z-index: 2;
}
.product-landing .product-manufacturer-logo img {
  width: 20rem;
}
.product-landing .extra-content-container-wrapper {
  display: grid;
  grid-template-columns: 70rem 1fr;
}
.product-landing .extra-content-container-wrapper .type-video,
.product-landing .extra-content-container-wrapper .type-image,
.product-landing .extra-content-container-wrapper .type-text {
  padding: 0px !important;
}
.product-landing .extra-content-container-wrapper .type-text {
  font-family: 'Exo 2', 'Lato', Arial, Helvetica, sans-serif;
  font-size: 24px;
  color: #000000;
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem !important;
}
.product-landing .extra-content-container-wrapper .type-text-body {
  width: 100%;
  box-sizing: border-box;
  padding: 2rem;
}
.product-landing .extra-content-container-wrapper .type-image-body {
  margin-bottom: -5px;
}
.product-landing .extra-content-container-wrapper .type-image-body img {
  width: 100%;
}
.product-landing .extra-content-container-wrapper .extra-content-container {
  background-color: #ececec;
  margin-bottom: 1rem;
}
.product-landing .extra-content-container-wrapper .main-grid {
  margin: 0px !important;
}
.product-landing .extra-content-container-wrapper .extra-content-container {
  width: 100%;
}
.product-landing .extra-content-container-wrapper .ui.grid.main-grid {
  grid-column: 2;
}
.product-landing .extra-content-container-wrapper .ui.grid > .column:not(.row) {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}
.product-landing .type-background-image-body {
  position: static;
  width: 100%;
  z-index: -1;
}
.product-landing .type-background-image-body img {
  width: 100%;
  height: auto;
  display: block;
}
.product-landing .video-box-embed .video-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
.product-landing .video-box-embed .video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.product-landing .video-background-wrapper #video-background-embed {
  height: max-content;
  margin-bottom: 1rem;
}
.product-landing .video-background-wrapper #video-background .youtube-background > iframe {
  transition: opacity 500ms ease-in-out;
  transition-delay: 3000ms;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.product-landing .video-background-wrapper #video-background .buttons {
  position: absolute;
  right: 1%;
  top: 1%;
  z-index: 1;
}
.product-landing .video-background-wrapper #video-background .button {
  padding: 1rem;
  text-align: center;
  font-size: 2rem;
}
.product-landing .video-background-wrapper #video-background .buttons .button .icon {
  margin-right: 0;
}
.product-landing .video-background-wrapper #video-background #toggle-video-volume.on .up,
.product-landing .video-background-wrapper #video-background #toggle-video.on .pause {
  display: inline-block;
}
.product-landing .video-background-wrapper #video-background #toggle-video-volume.on .off,
.product-landing .video-background-wrapper #video-background #toggle-video-volume .up,
.product-landing .video-background-wrapper #video-background #toggle-video .pause,
.product-landing .video-background-wrapper #video-background #toggle-video.on .play {
  display: none;
}
.product-landing .video-background-wrapper #video-background {
  position: static !important;
}
.product-landing .video-background-wrapper #video-background .youtube-background {
  position: inherit !important;
  width: 100%;
  margin: 0;
  overflow: inherit !important;
}
.product-landing .video-background-wrapper #video-background-embed .separator-line {
  height: 5px;
  width: 100%;
  opacity: 1;
}
.product .rating {
  display: inline;
  text-align: center;
}
.product .rating .icon {
  font-size: 3rem;
  width: 3rem;
}
.product .rating .icon::before {
  width: 3rem;
}
.product .rating.small .icon {
  font-size: 2rem;
  width: 2rem;
}
.product .rating.small .icon::before {
  width: 2rem;
}
.product .rating.small .details .score {
  font-size: 1.75rem;
  padding-inline: 0;
}
/*
modal logic */
.ui.modals {
  z-index: 10000;
}
.ui.modal .content .default {
  display: block;
}
.ui.modal .content .working {
  display: none;
}
.ui.modal.working .content .default {
  display: none;
}
.ui.modal.working .content .working {
  display: block;
}
.question-box {
  position: relative;
  background-color: #19a531;
  color: #ffffff;
  display: flex;
  flex: auto;
  align-items: center;
  justify-content: space-between;
}
.question-box.point-up:before,
.question-box.point-down:after {
  content: " ";
  position: absolute;
  height: 0;
  width: 0;
  left: 8.5rem;
}
.question-box.point-up:before {
  top: -1.5rem;
  border-top: none;
  border-right: 1.5rem solid transparent;
  border-left: 1.5rem solid transparent;
  border-bottom: 1.5rem solid #19a531;
}
.question-box.point-down:after {
  bottom: -1.5rem;
  border-top: 1.5rem solid #19a531;
  border-right: 1.5rem solid transparent;
  border-left: 1.5rem solid transparent;
  border-bottom: none;
}
.question-box .image {
  height: 7rem;
  line-height: 7rem;
  width: 7rem;
  display: block;
}
.question-box .image .icon {
  height: 7rem;
  font-size: 5rem;
  line-height: 7rem;
  text-align: left;
}
.question-box .yes-or-no {
  min-width: 16rem;
  height: 4rem;
  background-color: #ffffff;
  border-radius: 0.5rem;
  margin-left: 1rem;
}
.question-box .yes-or-no .radio-field {
  height: 100%;
  width: 100%;
}
.question-box .yes-or-no .radio-field .radio-label {
  height: inherit;
  width: inherit;
  padding: 0.5rem;
}
.question-box .yes-or-no .radio-field .radio-label .ui.labeled.button {
  width: 100%;
  margin-right: 0;
}
.question-box .yes-or-no .radio-field .radio-label .ui.labeled.button .label,
.question-box .yes-or-no .radio-field .radio-label .ui.labeled.button .ui.button {
  display: inline-block;
  width: 50%;
  height: 3rem;
  border: none;
  padding: 0.5rem;
  line-height: 2rem;
  font-size: 2rem;
}
.question-box .yes-or-no .radio-field .radio-label .ui.labeled.button .label {
  color: #b2b2b2;
}
.question-box .yes-or-no .radio-field .radio-label .on,
.question-box .yes-or-no .radio-field .radio-label .off {
  height: inherit;
  width: inherit;
}
.question-box .yes-or-no .radio-field .radio-label .on .icons .icon,
.question-box .yes-or-no .radio-field .radio-label .off .icons .icon {
  font-size: 3rem;
}
.question-box .question {
  font-size: 1.8rem;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  width: 100%;
}
.question-box.padded {
  padding: 1rem 3rem;
}
.question-box.transparent {
  background-color: transparent;
}
.question-box.transparent .yes-or-no {
  background-color: transparent;
}
.page-booking .main.container {
  padding-top: 6rem;
  padding-bottom: 2rem;
}
#service-booking #booking-choose-garage .content.horizontal-scroll {
  padding-left: 0;
  padding-right: 0;
}
#service-booking #booking-choose-garage .garage {
  background-color: #ffffff;
  box-shadow: none;
  max-width: 25rem;
  margin: 1rem;
}
#service-booking #booking-choose-garage .garage .content:first-child {
  padding-top: 1em;
}
#service-booking #booking-choose-garage .garage .content:last-child {
  padding-bottom: 1em;
}
#service-booking #booking-choose-garage .garage .content {
  border-top: none;
  padding: 0.5em 1em;
}
#service-booking #booking-choose-garage .garage .content.upperlined {
  margin: 0 1em;
  padding: 0.5em 0;
  border-top: 1px solid #e5e5e5;
}
#service-booking #booking-choose-garage .garage .content.icons .icon {
  background-color: #333333 !important;
}
#service-booking #booking-choose-garage .garage .image.logo {
  padding: 1em;
  height: 4rem;
  background-color: #ffffff;
}
#service-booking #booking-choose-garage .garage .image.logo img {
  max-height: 2.7rem;
  width: auto;
}
#service-booking #booking-choose-garage .garage .image.logo.partner img {
  max-height: 2.6rem;
}
#service-booking #booking-choose-garage .garage .name {
  text-transform: uppercase;
  font-size: 1.6rem;
}
#service-booking #booking-choose-garage .garage .name .distance {
  font-weight: bold;
}
#service-booking #booking-choose-garage .garage .opening .list {
  margin-top: 0;
}
#service-booking #booking-choose-garage .garage .go-to-map a {
  color: #de3a3e;
  text-transform: uppercase;
  font-size: 1.2rem;
  padding: 0.5em 0;
  display: block;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}
#service-booking #booking-choose-garage .garage .go-to-map a .icon {
  font-size: 2.5rem;
}
#service-booking #booking-choose-garage .garage .content.services-list {
  max-height: 140px;
  overflow: hidden;
  position: relative;
}
#service-booking #booking-choose-garage .garage .content.services-list strong {
  font-size: 1.3rem;
}
#service-booking #booking-choose-garage .garage .content.services-list ul {
  margin-top: 0.5rem;
  font-size: 1.3rem;
}
#service-booking #booking-choose-garage .garage .content.services-list .more-button {
  background-color: #ffffff !important;
  padding: 0.25rem 1rem;
  font-weight: normal;
  font-size: 1.3rem;
  text-decoration: underline;
  text-align: left;
  position: absolute;
  bottom: 0;
}
#service-booking #booking-choose-garage .garage .content.services-list .more-button i {
  text-decoration: none;
}
#service-booking #booking-choose-garage .garage .content.services-list.opened {
  max-height: none;
}
#service-booking #booking-choose-garage .garage .content.services-list.opened .more-button {
  position: relative;
}
#service-booking #booking-choose-garage .garage .selected-button .button {
  color: #de3a3e;
  background-color: #ffffff;
  border: 1px solid #de3a3e;
  text-transform: uppercase;
  text-align: left;
  font-size: 1.7rem;
  padding: 0.75em 0.5em !important;
}
#service-booking #booking-choose-garage .garage.selected {
  box-shadow: 0px 2px 0px 0px #de3a3e, 0px 0px 0px 2px #de3a3e;
}
#service-booking #booking-choose-garage .garage.selected .selected-button .button {
  color: #ffffff;
  background-color: #de3a3e;
  border: none;
}
#service-booking #booking-choose-service .content.horizontal-scroll {
  padding-left: 0;
  padding-right: 0;
}
#service-booking #booking-choose-service .service {
  width: 100%;
  background-color: #ffffff;
  box-shadow: none;
  max-width: 30rem;
  margin: 1rem;
}
#service-booking #booking-choose-service .service .content:first-child {
  padding-top: 1em;
}
#service-booking #booking-choose-service .service .content:last-child {
  padding-bottom: 1em;
}
#service-booking #booking-choose-service .service .content {
  border-top: none;
  padding: 0.5em 1em;
}
#service-booking #booking-choose-service .service .name {
  background-color: #b2b2b2;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 2rem;
  min-height: 10rem;
  vertical-align: middle;
}
#service-booking #booking-choose-service .service .name.with-image img {
  width: 7rem;
  float: left;
  margin-right: 1.5rem;
}
#service-booking #booking-choose-service .service .name.with-image .value strong {
  display: block;
  font-size: 3rem;
  line-height: 3rem;
}
#service-booking #booking-choose-service .service .name.with-image .value small {
  line-height: 2rem;
}
#service-booking #booking-choose-service .service .subname {
  font-style: italic;
  font-size: 1.4rem;
}
#service-booking #booking-choose-service .price .value {
  text-align: center;
  font-size: 3rem;
  font-weight: bold;
  padding: 0.5em 0;
  display: block;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}
#service-booking #booking-choose-service .price .value:last-child {
  border-top: none;
}
#service-booking #booking-choose-service .price .value.highlighted {
  color: #de3a3e;
}
#service-booking #booking-choose-service .price .value.original {
  text-decoration: line-through;
}
#service-booking #booking-choose-service .description .value ul {
  padding-left: 2rem;
}
#service-booking #booking-choose-service .selected-button .button {
  color: #de3a3e;
  background-color: #ffffff;
  border: 1px solid #de3a3e;
  text-transform: uppercase;
  text-align: left;
  font-size: 1.7rem;
  padding: 0.75em 0.5em !important;
}
#service-booking #booking-choose-service .service.selected {
  box-shadow: 0px 2px 0px 0px #de3a3e, 0px 0px 0px 2px #de3a3e;
}
#service-booking #booking-choose-service .service.selected .selected-button .button {
  color: #ffffff;
  background-color: #de3a3e;
  border: none;
}
#service-booking #booking-choose-datetime .datetimes-explanation {
  margin-top: 3rem;
  box-shadow: none;
  border: none;
}
#service-booking #booking-choose-datetime .datetimes-explanation .column:first-child {
  font-size: 2.5rem;
}
#service-booking #booking-choose-datetime .datetimes-explanation .column .icon.grey {
  color: #b2b2b2 !important;
}
#service-booking #booking-choose-datetime #date-selector .segment {
  border: none;
}
#service-booking #booking-choose-datetime #date-selector .header {
  background-color: #808080;
  color: #ffffff;
  height: 6rem;
  padding: 1rem;
  line-height: 4rem;
  font-size: 2rem;
  border: none;
  margin: 0;
}
#service-booking #booking-choose-datetime #date-selector .header .button {
  font-size: 2rem;
  padding: 1.2rem 1rem 1rem;
  height: 4rem;
}
#service-booking #booking-choose-datetime #date-selector .days .day {
  padding: 1rem 0.5rem;
}
#service-booking #booking-choose-datetime #date-selector .days .day button {
  outline: none;
  font-size: 1.4rem;
  line-height: 1.4em;
  padding: 1.5rem 0 !important;
}
#service-booking #booking-choose-datetime #time-selector {
  margin-top: 3rem;
}
#service-booking #booking-choose-datetime #time-selector .time .field {
  cursor: pointer;
  text-align: center;
  border-radius: 0.5rem;
  background-color: #ffffff;
  padding: 2rem 1rem;
  height: auto;
}
#service-booking #booking-choose-datetime #time-selector .time .field label {
  cursor: pointer;
  display: block;
}
#service-booking #booking-choose-datetime #time-selector .time .field label .radio-button .icon {
  margin: 0;
}
#service-booking #booking-choose-datetime #time-selector .time .field label .radio-button .selected.icon {
  display: none;
}
#service-booking #booking-choose-datetime #time-selector .time .field .date-value {
  display: block;
  font-weight: normal;
  padding-top: 1rem;
  font-size: 2.5rem;
  line-height: 3rem;
}
#service-booking #booking-choose-datetime #time-selector .time .field .time-value {
  display: block;
  font-size: 3.6rem;
  line-height: 3.5rem;
  font-weight: bold;
}
#service-booking #booking-choose-datetime #time-selector .time.selected .field {
  border: 2px solid #3f51b5;
}
#service-booking #booking-choose-datetime #time-selector .time.selected .field .radio-button .selected.icon {
  display: block;
}
#service-booking #booking-preview {
  background-color: #19a531;
  color: #ffffff;
  border-radius: 0.5rem;
}
#service-booking #booking-preview .marso-icon-tyre_date {
  font-size: 17rem;
}
#service-booking #booking-preview .list {
  margin: 1rem;
}
#service-booking #booking-preview .list .item:last-child {
  padding-bottom: 2rem;
  border-bottom: solid 1px #f0f0f0;
}
#service-booking #booking-preview .list .item {
  border-color: #f0f0f0;
}
#service-booking #booking-preview .list .item > .icon {
  font-size: 3.5rem;
}
#service-booking #booking-preview .list .item .header {
  color: #ffffff;
  font-size: 3.6rem;
  line-height: 4rem;
}
#service-booking #booking-button-block {
  background: none;
}
#service-booking #booking-button-block #booking-button {
  min-height: 8rem;
  text-align: left;
  font-size: 2.8rem;
  padding: 2rem 8rem 2rem 2rem !important;
}
#service-booking #booking-button-block #booking-button .icon {
  width: 8rem;
}
#service-booking #booking-button-block #notice {
  border-width: 1.5rem;
  border-color: #e5e5e5;
}
#service-booking #booking-button-block #notice::placeholder {
  color: #999999;
  opacity: 1;
}
#tyre-hotel .question .message {
  box-shadow: none;
  margin-bottom: 1rem;
  background-color: #fff;
}
#tyre-hotel .segment.sip {
  min-height: 10rem;
}
#no-license-plate-number-error {
  top: -100%;
  text-align: center;
}
/* cart
*/
.page-cart .page-title {
  padding-top: 3rem;
}
.page-cart .services-box {
  padding-top: 9rem;
  padding-bottom: 3rem;
}
.page-cart .services-box > .header {
  font-size: 2.5rem;
}
.page-cart .cetelem-box {
  margin-top: 3rem;
}
#cart .cart-products .segment:nth-last-child(-1) {
  border-bottom: none;
}
#cart .cart-products .segment:last-child {
  border-bottom: none;
}
#cart .cart-products .segment {
  background-color: #f0f0f0;
  border-bottom: 1px solid #808080;
  position: relative;
}
#cart .cart-products .product {
  padding: 3rem;
}
#cart .cart-products .product .remove-button {
  padding: 0.5rem;
  height: 4rem;
  width: 4rem;
  background-color: #b2b2b2;
}
#cart .cart-products .product .remove-button .icon {
  font-size: 2.5rem;
  margin-right: 0;
}
#cart .cart-products .product .product-image-wrapper {
  background-color: #ffffff;
  text-align: center;
}
#cart .cart-products .product .product-price-wrapper {
  text-align: right;
  font-size: 2.5rem;
  line-height: 2.5rem;
}
#cart .cart-products .product .product-price-wrapper .price-label {
  font-size: 1.5rem;
  line-height: 1.5rem;
  padding-bottom: 0.5rem;
}
#cart .cart-products .product .product-price-wrapper .sub-total-price {
  font-weight: bold;
}
#cart .cart-products .product .product-price-wrapper .quantity-form {
  margin: 0 -1rem;
}
#cart .cart-products .product .product-price-wrapper .quantity-stepper-wrapper {
  padding-right: 0;
}
#cart .cart-products .product .product-price-wrapper .quantity-stepper {
  max-width: 10rem;
  position: relative;
  font-size: 1.4rem;
  height: 4rem;
}
#cart .cart-products .product .product-price-wrapper .quantity-stepper .quantity {
  height: 4rem;
  border: none;
  text-align: right;
  padding-right: 5.5rem;
  font-weight: bold;
}
#cart .cart-products .product .product-price-wrapper .quantity-stepper .quantity-label {
  position: absolute;
  top: 0;
  right: 0;
  line-height: 4rem;
  padding-right: 3rem;
}
#cart .cart-products .product .product-price-wrapper .quantity-stepper .quantity-label .buttons {
  width: 20px;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
}
#cart .cart-products .product .product-price-wrapper .quantity-stepper .quantity-label .buttons .button {
  padding: 0;
  height: 2rem;
  line-height: 2rem;
  font-size: 2rem;
}
#cart .cart-products .product .product-price-wrapper .quantity-stepper .quantity-label .buttons .button .icon {
  margin: auto;
  display: block;
  text-align: center;
  height: inherit;
  width: inherit;
}
#cart .cart-products .product .product-price-wrapper .quantity-stepper .quantity-label .buttons .button.quantity-up {
  background-color: #cccccc;
  border-top-left-radius: 0;
  text-align: center;
}
#cart .cart-products .product .product-price-wrapper .quantity-stepper .quantity-label .buttons .button.quantity-down {
  background-color: #b2b2b2;
  border-bottom-left-radius: 0;
}
#cart .cart-products .question-box {
  position: relative;
  background-color: #19a531;
  color: #ffffff;
}
#cart .cart-products .question-box:before {
  content: " ";
  position: absolute;
  height: 0;
  width: 0;
  left: 8.5rem;
  top: -1.5rem;
  border-top: none;
  border-right: 1.5rem solid transparent;
  border-left: 1.5rem solid transparent;
  border-bottom: 1.5rem solid #19a531;
}
#cart .cart-products .question-box .image {
  position: absolute;
  left: 3rem;
  top: 50%;
  transform: translateY(-50%);
  height: 7rem;
  line-height: 7rem;
  width: 7rem;
  display: block;
}
#cart .cart-products .question-box .image .icon {
  height: 7rem;
  font-size: 6.3rem;
  line-height: 7rem;
  text-align: left;
}
#cart .cart-products .question-box .yes-or-no {
  position: absolute;
  right: 3rem;
  top: 50%;
  transform: translateY(-50%);
  width: 16rem;
  height: 4rem;
  background-color: #ffffff;
  border-radius: 0.5rem;
}
#cart .cart-products .question-box .yes-or-no .radio-field {
  height: 100%;
  width: 100%;
}
#cart .cart-products .question-box .yes-or-no .radio-field .radio-label {
  height: inherit;
  width: inherit;
  padding: 0.5rem;
}
#cart .cart-products .question-box .yes-or-no .radio-field .radio-label .ui.labeled.button {
  width: 100%;
  margin-right: 0;
}
#cart .cart-products .question-box .yes-or-no .radio-field .radio-label .ui.labeled.button .label,
#cart .cart-products .question-box .yes-or-no .radio-field .radio-label .ui.labeled.button .ui.button {
  display: inline-block;
  width: 50%;
  height: 3rem;
  border: none;
  padding: 0.5rem;
  line-height: 2rem;
  font-size: 2rem;
}
#cart .cart-products .question-box .yes-or-no .radio-field .radio-label .ui.labeled.button .label {
  color: #b2b2b2;
}
#cart .cart-products .question-box .yes-or-no .radio-field .radio-label .on,
#cart .cart-products .question-box .yes-or-no .radio-field .radio-label .off {
  height: inherit;
  width: inherit;
}
#cart .cart-products .question-box .yes-or-no .radio-field .radio-label .on .icons .icon,
#cart .cart-products .question-box .yes-or-no .radio-field .radio-label .off .icons .icon {
  font-size: 3rem;
}
#cart .cart-products .question-box .question {
  font-size: 1.8rem;
  margin-left: 9rem;
  margin-right: 19rem;
  padding: 1.5rem 1rem 0 1rem;
  height: 7rem;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
#cart .cart-products .warning-box {
  position: relative;
  background-color: #ff9533;
  color: #ffffff;
}
#cart .cart-products .warning-box.current-item:before {
  content: " ";
  position: absolute;
  height: 0;
  width: 0;
  left: 8.5rem;
  top: -1.5rem;
  border-top: none;
  border-right: 1.5rem solid transparent;
  border-left: 1.5rem solid transparent;
  border-bottom: 1.5rem solid #ff9533;
}
#cart .cart-products .warning-box .image {
  position: absolute;
  left: 3rem;
  top: 50%;
  transform: translateY(-50%);
  height: 7rem;
  line-height: 7rem;
  width: 7rem;
  display: block;
}
#cart .cart-products .warning-box .image .icon {
  height: 7rem;
  font-size: 6.3rem;
  line-height: 7rem;
  text-align: left;
}
#cart .cart-products .warning-box .warning {
  margin-left: 9rem;
  padding: 1.5rem 1rem 1rem 1rem;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
#cart .cart-products .warning-box .warning p {
  margin: 0 0 0.2rem;
}
#cart .cart-products .warning-box .warning a {
  font-weight: bold;
}
/* cart sidebar
*/
.sidebar .order-summary.box {
  border: 2px solid #de3a3e;
  border-radius: 0.5rem;
  padding: 3rem;
  margin-bottom: 3rem;
}
.sidebar .order-summary.box .title {
  font-size: 2rem;
}
.sidebar .order-summary.box .list .item {
  line-height: 3rem;
  padding-bottom: 1rem;
}
.sidebar .order-summary.box .list .item > .label {
  font-size: 2rem;
}
.sidebar .order-summary.box .list .item .price {
  font-size: 2rem;
  font-weight: bold;
}
.sidebar .order-summary.box .list .item .right.floated {
  margin-left: 1rem;
}
.sidebar .order-summary.box .list .item.shipping .value.free {
  background-color: #19a531;
  color: #ffffff;
  font-weight: bold;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
}
.sidebar .order-summary.box .total-price.item {
  border-top: 2px solid #e5e5e5;
  padding-top: 1.5rem;
}
.sidebar > button,
.sidebar > form > .sidebar-button.ui.button.fluid.right.labeled.icon,
.sidebar #checkout-goto-next-step-button {
  text-align: left !important;
  padding: 2rem 5rem 2rem 2rem !important;
  line-height: 2.2rem !important;
}
.sidebar > button .icon,
.sidebar > form > .sidebar-button.ui.button.fluid.right.labeled.icon .icon,
.sidebar #checkout-goto-next-step-button .icon {
  width: 5rem;
}
.page-checkout .main.container {
  padding-bottom: 5rem;
}
.page-checkout .page-title {
  padding-top: 3rem;
}
#checkout #comment {
  border-width: 15px;
  border-color: #f0f0f0;
  border: none;
}
#checkout #comment::placeholder {
  color: #999999;
  opacity: 1;
}
#checkout-summary .cart-summary .first.attached.content {
  border-top: 1px solid #de3a3e !important;
}
#checkout-summary .cart-summary .attached.content {
  border-top: none !important;
  border-bottom: 2px solid #cccccc !important;
}
#checkout-summary .cart-summary .bottom.attached.content {
  border-bottom: none !important;
}
#checkout-summary .sidebar .form .checkbox-field.field .checkbox-label {
  padding: 1rem;
}
.form-block .shipping-method-item,
.form-block .payment-method-item {
  background-color: #ffffff;
  border-radius: 5px;
  margin: 1.5rem;
  padding: 2rem !important;
  border: 2px solid #ffffff;
}
.form-block .shipping-method-item .checkbox-field,
.form-block .payment-method-item .checkbox-field {
  border-bottom: 2px solid #e5e5e5;
}
.form-block .shipping-method-item .checkbox-field .field-input.checkbox-label,
.form-block .payment-method-item .checkbox-field .field-input.checkbox-label {
  padding: 0.5rem 0;
}
.form-block .shipping-method-item .checkbox-field .field-input.checkbox-label .field-label,
.form-block .payment-method-item .checkbox-field .field-input.checkbox-label .field-label {
  font-size: 2rem;
  padding-left: 0;
}
.form-block .shipping-method-item .checkbox-field .field-input.checkbox-label .field-label .price,
.form-block .payment-method-item .checkbox-field .field-input.checkbox-label .field-label .price {
  font-weight: bold;
}
.form-block .shipping-method-item .extra-field,
.form-block .payment-method-item .extra-field {
  margin-top: 3rem;
}
.form-block .shipping-method-item .extra-field .field-input-wrapper .field-input,
.form-block .payment-method-item .extra-field .field-input-wrapper .field-input {
  border: 1px solid #e5e5e5;
}
.form-block .shipping-method-item.selected,
.form-block .payment-method-item.selected {
  border-color: #de3a3e;
}
.form-block .shipping-method-item.selected .header,
.form-block .payment-method-item.selected .header {
  color: #de3a3e;
}
.ui.form input:not([type]):focus,
.ui.form input[type="date"]:focus,
.ui.form input[type="datetime-local"]:focus,
.ui.form input[type="email"]:focus,
.ui.form input[type="number"]:focus,
.ui.form input[type="password"]:focus,
.ui.form input[type="search"]:focus,
.ui.form input[type="tel"]:focus,
.ui.form input[type="time"]:focus,
.ui.form input[type="text"]:focus,
.ui.form input[type="file"]:focus,
.ui.form input[type="url"]:focus {
  border-radius: unset;
}
.ui.form textarea:focus {
  border-radius: unset;
}
.form-block {
  margin-bottom: 3rem;
  border-radius: 0.5rem;
}
.form-block > .top.attached.header {
  background-color: #e5e5e5;
  color: #b2b2b2;
  height: 6rem;
  padding: 1rem 3rem;
  line-height: 4rem;
  text-transform: uppercase;
  font-size: 2.2rem !important;
  border: none;
  margin: 0;
  width: 100%;
  border-radius: 0.5rem;
}
.form-block > .attached.segment {
  display: none;
  margin: 0;
  width: 100%;
}
.form-block > .attached.button {
  display: none;
  padding: 1.5rem;
  box-shadow: none !important;
  opacity: 1;
}
.form-block .ui.grid > select {
  padding: 0 1rem !important;
}
.form-block .header-select {
  display: none;
}
.form-block.mobile > .top.attached.header {
  font-size: 1.75rem !important;
  border-radius: 0;
}
.form-block.mobile > .bottom.attached {
  border-radius: 0;
}
.form-block.active {
  margin-bottom: 3rem;
  background-color: #e5e5e5;
}
.form-block.active > .top.attached.header {
  border-radius: 0.5rem 0.5rem 0 0;
  color: #ffffff;
  background-color: #333333;
}
.form-block.active > .attached.content {
  display: block;
  border-top: 2px solid #de3a3e !important;
  padding-top: 2rem;
}
.form-block.active > .attached.segment {
  display: block;
  background-color: #e5e5e5;
  border: none;
}
.form-block.active > .attached.button {
  display: block;
}
.form-block.active .header-select {
  display: block;
}
.form-block.mobile.active > .top.attached.header {
  border-radius: 0;
}
.form-block > .content {
  background-color: #f0f0f0;
}
.form-block .field {
  position: relative;
}
.form-block .field .field-label-wrapper {
  display: block;
  float: left;
  width: 25%;
}
.form-block .field .field-label-wrapper .field-label {
  display: inline-block;
  width: 100%;
  line-height: 4rem;
  padding: 0.5rem 1rem;
  font-size: 1.6rem;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #b2b2b2;
  color: #ffffff;
  border-radius: 0.5rem 0 0 0.5rem;
}
.form-block .field .field-label-wrapper .field-label:after {
  display: none !important;
}
.form-block .field .field-input-wrapper {
  display: block;
  margin-left: 25%;
  width: 75%;
}
.form-block .field .field-input-wrapper .field-input {
  display: inline-block;
  border-radius: 0 0.5rem 0.5rem 0;
  border: none;
  line-height: 4rem;
  padding: 0.5rem 1rem;
  height: 5rem;
}
.form-block .field .field-input-wrapper .field-input:focus {
  border-radius: 0 0.5rem 0.5rem 0;
}
.form-block .field .field-input-wrapper .field-input::placeholder {
  font-size: 1.2rem;
  color: #b2b2b2;
  opacity: 1;
  text-align: right;
}
.form-block .field .field-input-wrapper .field-input:disabled {
  cursor: not-allowed;
  pointer-events: auto;
}
.form-block .field .prompt.label {
  position: absolute;
  top: -3rem;
  left: 20%;
}
.form-block .field.no-label .field-label-wrapper {
  display: none;
}
.form-block .field.no-label .field-input-wrapper {
  float: none;
  width: 100%;
  margin-left: 0;
}
.form-block .field.no-label .field-input-wrapper .field-input {
  border-radius: 0.5rem;
}
.form-block .field.long-label .field-label-wrapper {
  width: 35%;
}
.form-block .field.long-label .field-input-wrapper {
  width: 65%;
  margin-left: 35%;
}
.form-block .field.very-long-label .field-label-wrapper {
  width: 80%;
}
.form-block .field.very-long-label .field-input-wrapper {
  width: 20%;
  margin-left: 80%;
}
.form-block .field.select-field .field-input-wrapper .field-input {
  height: 5rem;
}
.form-block .field.select-field .field-input-wrapper .field-input option {
  height: 5rem;
  line-height: 4rem;
  padding: 0.5rem 1rem;
}
.form-block .field.select-field .field-input-wrapper .field-input.dropdown {
  max-height: 5rem;
  min-height: auto;
  width: 100%;
  border: 1px solid #e5e5e5;
}
.form-block .field.select-field .field-input-wrapper .field-input.dropdown .search {
  padding: 0.5rem 1rem;
  line-height: 4rem;
}
.form-block .field.select-field .field-input-wrapper .field-input.dropdown .text {
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
}
.form-block .field.small-field.checkbox-field .field-input-wrapper {
  font-size: 1.4rem;
}
.form-block .field.small-field.checkbox-field .field-input-wrapper .field-input {
  line-height: 2rem;
  height: 3rem;
}
.form-block .field.small-field.checkbox-field .field-input-wrapper .field-input[for="newsletter"],
.form-block .field.small-field.checkbox-field .field-input-wrapper .field-input[for="gtc_accept"] {
  height: auto;
}
.form-block.vertical .field .field-label-wrapper {
  width: 50%;
}
.form-block.vertical .field .field-label-wrapper .field-label {
  border-radius: 0.5rem 0.5rem 0 0;
  line-height: 2rem;
}
.form-block.vertical .field .field-input-wrapper {
  width: 100%;
  float: none;
  margin-left: 0;
}
.form-block.vertical .field .field-input-wrapper .field-input {
  border-radius: 0 0.5rem 0.5rem 0.5rem;
}
.form-block.vertical .field .field-input-wrapper .field-input:focus {
  border-radius: 0 0.5rem 0.5rem 0.5rem;
}
.form-block.vertical .field .prompt.label {
  position: absolute;
  top: 0;
  left: 30%;
}
.form-block.vertical .field.wide-label .field-label-wrapper {
  width: 100%;
}
.form-block.vertical .field.wide-label .field-input-wrapper .field-input {
  border-top-right-radius: 0;
}
.form-block.vertical .field.login-field {
  font-size: 1.75rem;
}
.form-block.vertical .field.login-field .field-label-wrapper .field-label {
  background-color: transparent;
  text-transform: none;
  text-align: center;
  color: #333333;
  font-weight: normal;
}
.form-block.vertical .field.login-field .field-input-wrapper .field-input {
  border-radius: 0.5rem;
}
.form-block.vertical .field.login-field .field-input-wrapper .field-input::placeholder {
  font-size: 1.75rem;
  text-align: left;
}
.form-block.vertical .field.login-field .prompt.label {
  position: absolute;
  top: 0;
  left: 1rem;
}
.form-block.vertical .field.login-field.small-field .prompt.label {
  top: -2rem;
}
.field.autocomplete-field .field-input-wrapper {
  position: relative;
}
.field.autocomplete-field .field-input-wrapper .field-input {
  padding-right: 4rem;
}
.field.autocomplete-field .field-input-wrapper .search.icon {
  position: absolute;
  right: 0;
  bottom: 0;
  line-height: 4rem;
  padding: 0.5rem 1rem;
  display: block;
  height: 5rem;
  width: 4rem;
  color: #999999;
}
.field.autocomplete-field .ui.search.dropdown {
  padding: 0.5rem 1rem;
  border: 0;
}
.field.autocomplete-field .ui.search.dropdown input.search {
  padding: 0.5rem 1rem;
  left: 0;
}
.field.autocomplete-field .ui.search.dropdown input.search,
.field.autocomplete-field .ui.search.dropdown .text {
  line-height: 4rem;
  padding-right: 4rem;
}
.field.autocomplete-field .ui.search.dropdown .default.text {
  font-size: 1.25rem;
  color: #b2b2b2;
  opacity: 1;
  text-align: right;
}
.field.autocomplete-field .ui.search.dropdown .search.icon {
  right: 1.75rem;
}
.field.checkbox-field .field-input-wrapper {
  width: 100%;
  margin-left: 0;
}
.field.checkbox-field .field-input-wrapper .checkbox-label {
  display: block;
  border-radius: 0.5rem;
  background-color: #ffffff;
}
.field.checkbox-field .field-input-wrapper .checkbox-label .field-label {
  padding: 0 0.5rem;
}
.field.checkbox-field .field-input-wrapper .checkbox-label.multiline {
  height: auto !important;
}
.field.checkbox-field .field-input-wrapper.right-switch .switch {
  float: right;
}
.field.checkbox-field.simple .field-input-wrapper .checkbox-label {
  background-color: transparent;
}
.field.checkbox-field.gray .field-input-wrapper .checkbox-label {
  background-color: #e5e5e5;
}
.field.textarea-field .field-label-wrapper {
  width: 100%;
  float: none;
}
.field.textarea-field .field-label-wrapper .field-label {
  border-radius: 0.5rem 0.5rem 0 0;
}
.field.textarea-field .field-input-wrapper {
  width: 100%;
  margin-left: 0;
}
.field.textarea-field .field-input-wrapper .field-input {
  border-radius: 0 0 0.5rem 0.5rem;
  min-height: 5rem;
  height: auto;
  line-height: 2rem;
}
.field.textarea-field .field-input-wrapper .field-input:focus {
  border-radius: 0 0 0.5rem 0.5rem;
}
#zip-code-form {
  display: none;
}
.form-block.active #zip-code-form {
  display: block;
}
.field.service-site-search-field {
  float: right;
}
.field.service-site-search-field .field-input-wrapper {
  width: 10rem;
  float: left;
  margin-left: 0;
}
.field.service-site-search-field .field-input-wrapper .field-input {
  border-radius: 0.5rem 0 0 0.5rem;
  line-height: 3rem;
  height: 4rem;
}
.field.service-site-search-field .field-input-wrapper .field-input:focus {
  border-radius: 0.5rem 0 0 0.5rem;
}
.field.service-site-search-field .field-button-wrapper {
  width: auto;
  float: left;
}
.field.service-site-search-field .field-button-wrapper .field-button {
  border-radius: 0 0.5rem 0.5rem 0;
  line-height: 3rem;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
}
.field.service-site-search-field .prompt.label {
  bottom: 3rem;
  left: -3rem;
  text-align: center;
  top: auto;
}
.form-block.mobile .field.service-site-search-field .field-input-wrapper {
  width: 50%;
  display: inline-block;
  float: none;
}
.form-block.mobile .field.service-site-search-field .field-input-wrapper .field-input {
  border-radius: 0.5rem 0 0 0.5rem;
}
.form-block.mobile .field.service-site-search-field .field-button-wrapper {
  width: 45%;
  display: inline-block;
  float: right;
}
.form-block.mobile .field.service-site-search-field .field-button-wrapper .field-button {
  width: 100%;
  text-align: center;
}
.form-block.mobile .field.service-site-search-field .field-button-wrapper .field-button .icon {
  font-size: 2rem;
  line-height: 2rem;
  margin-right: 0;
}
.form-block.mobile .field.service-site-search-field .prompt.label {
  bottom: 3rem;
  left: -3rem;
  text-align: center;
  top: auto;
}
.form-block .field.header-select .field-label-wrapper {
  float: left;
  width: 25%;
}
.form-block .field.header-select .field-label-wrapper .field-label {
  border-radius: 0.5rem 0 0 0.5rem;
  line-height: 2rem;
  font-size: 1.5rem;
}
.form-block .field.header-select .field-input-wrapper {
  width: 75%;
}
.form-block .field.header-select .field-input-wrapper .field-input {
  border-radius: 0 0.5rem 0.5rem 0;
  line-height: 2rem;
  font-size: 1.5rem;
  height: 3rem;
}
.form-block .field.header-select.no-label .field-label-wrapper {
  display: none;
}
.form-block .field.header-select.no-label .field-input-wrapper {
  float: none;
  width: 100%;
  margin-left: 0;
}
.form-block .field.header-select.no-label .field-input-wrapper .field-input {
  border-radius: 0.5rem;
}
.form-block .field .field-input-wrapper.with-info-box .info-box-switch {
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  width: 4.5rem;
  height: 4.5rem;
  border-top-right-radius: 0.5rem;
  text-align: right;
  z-index: 1;
}
.form-block .field .field-input-wrapper.with-info-box .info-box-switch:after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  z-index: -1;
  width: 0;
  height: 0;
  background-color: transparent !important;
  border-top: 0 solid transparent;
  border-right: 4.5rem solid #b2b2b2;
  border-bottom: 4.5rem solid transparent;
  border-left: 0 solid transparent;
}
.form-block .field .field-input-wrapper.with-info-box .info-box {
  position: absolute;
  top: 0;
  width: inherit;
  line-height: 4rem;
  padding: 0.5rem 1rem;
  background-color: #de3a3e;
  border-radius: 0 0.5rem 0.5rem 0;
  color: #ffffff;
}
.form-block.vertical .field .field-input-wrapper.with-info-box .info-box-switch {
  top: auto;
  bottom: 0.5rem;
}
.form-block.vertical .field .field-input-wrapper.with-info-box .info-box {
  bottom: 0;
  top: auto;
  border-radius: 0 0.5rem 0.5rem;
}
.field.file-field .field-input-wrapper {
  position: relative;
}
.field.file-field .field-input-wrapper input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.field.file-field .field-input-wrapper input + label {
  cursor: pointer;
  width: 100%;
  vertical-align: top;
  font-family: 'Exo 2', 'Lato', Arial, Helvetica, sans-serif;
  margin: 0em;
  outline: none;
  -webkit-appearance: none;
  line-height: 1.21428571em;
  padding: 1.1em 1.4em;
  font-size: 1em;
  background: #ffffff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: #b2b2b2;
  border-radius: 0.4rem;
  box-shadow: 0em 0em 0em 0em transparent inset;
  transition: color 0.1s ease, border-color 0.1s ease;
  display: inline-block;
  border-radius: 0 0.5rem 0.5rem 0;
  border: none;
  line-height: 4rem;
  padding: 0.5rem 1rem;
  height: 5rem;
}
.field.file-field .field-input-wrapper input + label.file-selected {
  color: #333333;
}
.scroller-rail .scroller-train.moving-train {
  position: fixed;
  top: 70px;
}
.scroller-rail .scroller-train.stopped-train {
  position: relative;
  top: 100%;
  transform: translateY(-100%);
}
.pager-wrapper > .column {
  background-color: #e5e5e5;
  padding: 0 1rem !important;
  border-radius: 0.5rem;
}
.pager-wrapper > .column .pager {
  border: none;
}
.pager-wrapper > .column .pager .button {
  padding: 0.5rem 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 2rem;
  background-color: #cccccc !important;
}
.pager-wrapper > .column .pager .button .icon {
  margin: 0 !important;
}
.pager-wrapper > .column .pager .button.active {
  background-color: #de3a3e !important;
  color: #ffffff !important;
}
.car-form .autocomplete-field .results {
  max-height: 200px;
  overflow-y: scroll;
}
.car-form .select-field .dropdown {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.car-form .select-field .dropdown:hover {
  border-color: #ffffff;
}
.car-form .select-field .dropdown .default.text {
  color: #b2b2b2;
}
.car-form .alcar-car-image > .grid {
  height: 100%;
  background-color: #ffffff;
}
.car-form .alcar-car-image .status-basic {
  display: block;
}
.car-form .alcar-car-image .status-basic .icon {
  font-size: 15rem;
}
.car-form .alcar-car-image .status-loading,
.car-form .alcar-car-image .status-image {
  display: none !important;
}
.car-form .alcar-car-image.waiting .status-loading {
  display: block !important;
}
.car-form .alcar-car-image.waiting .status-basic {
  display: none !important;
}
.car-form .alcar-car-image.waiting .status-image {
  display: none !important;
}
.car-form .alcar-car-image.ready .status-image {
  display: block !important;
}
.car-form .alcar-car-image.ready .status-basic {
  display: none !important;
}
.car-form .alcar-car-image.ready .status-loading {
  display: none !important;
}
.detailed-search-panel {
  padding-top: 3rem !important;
  padding-bottom: 6rem !important;
}
.detailed-search-panel .form-panel {
  padding-right: 1.5rem !important;
}
.detailed-search-panel .results-panel {
  border-left: 1px solid #cccccc;
  padding-left: 3rem !important;
}
.detailed-search-panel .results-panel .featured-manufacturers {
  padding-bottom: 3rem;
}
.ui.form.product-search-sidebar-form .attached-label-field-wrapper {
  padding: 0;
  border-radius: 0.5rem;
  box-shadow: none;
  border-color: #f0f0f0;
  border-width: 1px;
}
.ui.form.product-search-sidebar-form .attached-label-field-wrapper .select-field .dropdown {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.ui.form.product-search-sidebar-form > .ui.grid > .row {
  padding-top: 0.5rem;
}
.ui.form.product-search-sidebar-form > .ui.grid > .row.form-data {
  padding-top: 0;
}
.ui.form.product-search-sidebar-form > .ui.grid > .row.form-data .widths .dropdown,
.ui.form.product-search-sidebar-form > .ui.grid > .row.form-data .profiles .dropdown,
.ui.form.product-search-sidebar-form > .ui.grid > .row.form-data .diameters .dropdown,
.ui.form.product-search-sidebar-form > .ui.grid > .row.form-data .holes .dropdown,
.ui.form.product-search-sidebar-form > .ui.grid > .row.form-data .bolt-patterns .dropdown,
.ui.form.product-search-sidebar-form > .ui.grid > .row.form-data .center-bores .dropdown,
.ui.form.product-search-sidebar-form > .ui.grid > .row.form-data .wheel-offsets .dropdown {
  max-height: 3rem;
  border-color: #f0f0f0;
  font-size: 1.5rem;
  padding: 0.5rem;
}
.ui.form.product-search-sidebar-form > .ui.grid > .row.form-data .widths,
.ui.form.product-search-sidebar-form > .ui.grid > .row.form-data .profiles,
.ui.form.product-search-sidebar-form > .ui.grid > .row.form-data .holes,
.ui.form.product-search-sidebar-form > .ui.grid > .row.form-data .center-bores {
  padding-right: 1rem !important;
}
.ui.form.product-search-sidebar-form .header {
  margin-bottom: 0 !important;
}
.ui.form.product-search-sidebar-form .manufacturers {
  max-height: 250px !important;
}
.ui.form.product-search-form .search-status {
  display: none;
  position: fixed !important;
  bottom: 1rem;
  z-index: 10;
}
.ui.form.product-search-form .search-status.visible {
  display: block;
}
.ui.form.product-search-form .search-status .column {
  padding-right: 1.5rem;
}
.ui.form.product-search-form .sizes {
  padding-bottom: 3rem;
}
.ui.form.product-search-form .fuel-efficiencies {
  padding-top: 0.5rem;
}
.ui.form.product-search-form .wet-grip-classes {
  padding-top: 0.5rem;
}
.ui.form.product-search-form .header {
  font-weight: normal;
  font-size: 20px;
  border-color: #de3a3e;
  border-width: 2px;
  margin-bottom: 0.5rem;
}
.ui.form.product-search-form .show-results .results-text {
  font-size: 1.5rem;
  text-transform: lowercase;
}
.ui.form.product-search-form .select-field .dropdown.icon {
  padding: 1.5rem;
}
.ui.form.product-search-form .select-field .dropdown.selection {
  background-color: #f0f0f0;
  border-color: #f0f0f0;
}
.ui.form.product-search-form .select-field .default.text {
  color: #262626;
}
.ui.form.product-search-form .checkbox-field .checkbox-label {
  font-weight: normal;
  text-transform: uppercase;
  font-size: 1.4rem;
}
.ui.form.product-search-form .manufacturers {
  max-height: 600px;
  overflow-y: scroll;
  border-bottom: 1px solid #808080;
}
.ui.form.product-search-form .fuel-efficiencies .checkbox-label .off,
.ui.form.product-search-form .wet-grip-classes .checkbox-label .off,
.ui.form.product-search-form .fuel-efficiencies .checkbox-label .on,
.ui.form.product-search-form .wet-grip-classes .checkbox-label .on {
  padding-right: 10px;
}
.ui.form.product-search-form .fuel-efficiencies .checkbox-label:before,
.ui.form.product-search-form .wet-grip-classes .checkbox-label:before {
  color: #ffffff;
  padding-left: 10px;
  position: absolute;
  left: 25px;
  font-weight: bold;
}
.ui.form.product-search-form .fuel-efficiencies .checkbox-label:after,
.ui.form.product-search-form .wet-grip-classes .checkbox-label:after {
  position: absolute;
  content: "";
  left: 25px;
  padding-left: 10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 11px 0 11px 11px;
}
.ui.form.product-search-form .fuel-efficiencies .checkbox-label.value-A:before,
.ui.form.product-search-form .wet-grip-classes .checkbox-label.value-A:before {
  content: "A";
  width: 20%;
  background-color: #016701;
}
.ui.form.product-search-form .fuel-efficiencies .checkbox-label.value-A:after,
.ui.form.product-search-form .wet-grip-classes .checkbox-label.value-A:after {
  margin-left: 20%;
  border-color: transparent transparent transparent #016701;
}
.ui.form.product-search-form .fuel-efficiencies .checkbox-label.value-B:before,
.ui.form.product-search-form .wet-grip-classes .checkbox-label.value-B:before {
  content: "B";
  width: 25%;
  background-color: #0D9C0D;
}
.ui.form.product-search-form .fuel-efficiencies .checkbox-label.value-B:after,
.ui.form.product-search-form .wet-grip-classes .checkbox-label.value-B:after {
  margin-left: 25%;
  border-color: transparent transparent transparent #0D9C0D;
}
.ui.form.product-search-form .fuel-efficiencies .checkbox-label.value-C:before,
.ui.form.product-search-form .wet-grip-classes .checkbox-label.value-C:before {
  content: "C";
  width: 30%;
  background-color: #98CB00;
}
.ui.form.product-search-form .fuel-efficiencies .checkbox-label.value-C:after,
.ui.form.product-search-form .wet-grip-classes .checkbox-label.value-C:after {
  margin-left: 30%;
  border-color: transparent transparent transparent #98CB00;
}
.ui.form.product-search-form .fuel-efficiencies .checkbox-label.value-D:before,
.ui.form.product-search-form .wet-grip-classes .checkbox-label.value-D:before {
  content: "D";
  width: 35%;
  background-color: #E8E31F;
}
.ui.form.product-search-form .fuel-efficiencies .checkbox-label.value-D:after,
.ui.form.product-search-form .wet-grip-classes .checkbox-label.value-D:after {
  margin-left: 35%;
  border-color: transparent transparent transparent #E8E31F;
}
.ui.form.product-search-form .fuel-efficiencies .checkbox-label.value-E:before,
.ui.form.product-search-form .wet-grip-classes .checkbox-label.value-E:before {
  content: "E";
  width: 40%;
  background-color: #FFC001;
}
.ui.form.product-search-form .fuel-efficiencies .checkbox-label.value-E:after,
.ui.form.product-search-form .wet-grip-classes .checkbox-label.value-E:after {
  margin-left: 40%;
  border-color: transparent transparent transparent #FFC001;
}
.ui.form.product-search-form .fuel-efficiencies .checkbox-label.value-F:before,
.ui.form.product-search-form .wet-grip-classes .checkbox-label.value-F:before {
  content: "F";
  width: 45%;
  background-color: #F98605;
}
.ui.form.product-search-form .fuel-efficiencies .checkbox-label.value-F:after,
.ui.form.product-search-form .wet-grip-classes .checkbox-label.value-F:after {
  margin-left: 45%;
  border-color: transparent transparent transparent #F98605;
}
.ui.form.product-search-form .fuel-efficiencies .checkbox-label.value-G:before,
.ui.form.product-search-form .wet-grip-classes .checkbox-label.value-G:before {
  content: "G";
  width: 50%;
  background-color: #FE0000;
}
.ui.form.product-search-form .fuel-efficiencies .checkbox-label.value-G:after,
.ui.form.product-search-form .wet-grip-classes .checkbox-label.value-G:after {
  margin-left: 50%;
  border-color: transparent transparent transparent #FE0000;
}
.ui.form.product-search-form .noise-measurement-classes .checkbox-label {
  height: 50px;
  line-height: 50px;
}
.ui.form.product-search-form .noise-measurement-classes .checkbox-label.noise-1 {
  background: url(../images/noise-1.svg) no-repeat 25px center;
}
.ui.form.product-search-form .noise-measurement-classes .checkbox-label.noise-2 {
  background: url(../images/noise-2.svg) no-repeat 25px center;
}
.ui.form.product-search-form .noise-measurement-classes .checkbox-label.noise-3 {
  background: url(../images/noise-3.svg) no-repeat 25px center;
}
.autocomplete-field .results {
  display: none;
  padding: 5px 0;
  position: absolute;
  top: 50px;
  right: 1px;
  left: 1px;
  background-color: #ffffff;
  z-index: 999;
  box-shadow: 0px 4px 5px 0px rgba(38, 38, 38, 0.3), 0px 4px 5px 0px rgba(255, 255, 255, 0.3);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.autocomplete-field .results .message {
  padding: 5px 10px;
}
.autocomplete-field .results .result {
  color: #666666;
  cursor: pointer;
  padding: 5px 10px;
  display: block;
}
.autocomplete-field .results .result:hover {
  color: #333333;
  background-color: #f0f0f0;
}
.autocomplete-field .results .result .title {
  display: inline-block;
  padding-right: 10px;
}
.autocomplete-field .results .result .description {
  display: inline-block;
}
#manufacturer-treads-filter-menu .mainmenu,
#manufacturer-treads-filter-menu .submenu {
  line-height: 6rem;
  min-height: auto;
  padding: 0px 2rem;
  border: none;
  font-size: 2.5rem;
}
#manufacturer-treads-filter-menu .mainmenu .default.text,
#manufacturer-treads-filter-menu .submenu .default.text {
  color: #262626;
}
#manufacturer-treads-filter-menu .mainmenu .text .image,
#manufacturer-treads-filter-menu .submenu .text .image {
  display: none;
}
#manufacturer-treads-filter-menu .mainmenu .menu,
#manufacturer-treads-filter-menu .submenu .menu {
  box-shadow: 0px 2px 3px 0px rgba(38, 38, 38, 0.3);
  border: none;
  margin: 0px;
  width: 100%;
  min-width: 100%;
  max-height: none;
}
#manufacturer-treads-filter-menu .mainmenu .menu .item,
#manufacturer-treads-filter-menu .submenu .menu .item {
  border-color: #f0f0f0 !important;
  border-width: 2px;
  font-size: 2.2rem;
  line-height: 5rem;
  max-height: 6.2rem;
  padding: 0px 2rem 0px 0px !important;
}
#manufacturer-treads-filter-menu .mainmenu .menu .item .image,
#manufacturer-treads-filter-menu .submenu .menu .item .image {
  display: inline-block;
  min-width: 15rem;
  max-width: 15rem;
  height: 6rem;
  min-height: 6rem;
  max-height: 6rem;
  padding: 0.5rem 2rem;
  background-color: #cccccc;
}
#manufacturer-treads-filter-menu .mainmenu .menu .item .image img,
#manufacturer-treads-filter-menu .submenu .menu .item .image img {
  height: 5rem;
  max-height: 5rem;
  width: 100%;
}
#manufacturer-treads-filter-menu .mainmenu .menu .item .content,
#manufacturer-treads-filter-menu .submenu .menu .item .content {
  vertical-align: middle;
}
#manufacturer-treads-filter-menu .submenu .menu .item {
  padding: 0px 2rem !important;
}
#manufacturer-treads-filter-menu .dropdown.icon {
  font-size: 1.5rem;
  line-height: 6rem;
  margin: 0;
  padding: 0;
  right: 2rem;
  top: 0;
}
#manufacturer-treads-filter-menu > .container {
  padding: 3rem 0;
}
#manufacturer-treads-filter-menu > .container .title {
  font-size: 6rem;
  line-height: 6rem;
  font-weight: normal;
}
.manufacturer-page .manufacturer-description {
  padding: 3rem 0;
}
#content-manufacturers {
  padding-bottom: 3rem;
}
/* treads block */
.content-tread {
  position: relative;
  min-height: 38rem;
}
.content-tread > .image {
  float: right;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  max-height: 35rem;
  overflow: hidden;
}
.content-tread .product-name-wrapper {
  padding-top: 20%;
  height: 22rem;
}
.content-tread .product-name-wrapper .manufacturer-name {
  font-weight: bold;
}
.content-tread .product-name-wrapper .tread-name {
  text-shadow: 1px 0px 0px rgba(255, 255, 255, 0.95), 0px 1px 0px rgba(255, 255, 255, 0.95), -1px 0px 0px rgba(255, 255, 255, 0.95), 0px -1px 0px rgba(255, 255, 255, 0.95);
}
.content-tread .product-name-wrapper .name {
  font-weight: bold;
}
.content-tread .product-name-wrapper.big {
  font-size: 2rem;
  line-height: 3rem;
}
.content-tread .product-name-wrapper.huge {
  font-size: 3.5rem;
  line-height: 4rem;
}
.content-tread .description {
  background-color: #ffffff;
  border-top: 2px solid #e5e5e5;
  max-height: 17rem;
  min-height: 17rem;
  overflow: hidden;
  padding-top: 1rem;
}
.content-tread .description .more {
  display: none;
}
.content-tread .description.full {
  max-height: none;
  padding-bottom: 7rem;
}
.content-tread .description.full .less {
  display: none;
}
.content-tread .description.full .more {
  display: block;
}
.content-tread .description .button-more-less-switch {
  color: #19a531 !important;
}
.content-tread .description .button-more-less-switch.link {
  background-color: #ffffff !important;
  padding: 0px;
}
.content-tread .description .button-more-less-switch .icon {
  line-height: 1rem !important;
  width: 1.2rem !important;
  height: 1.2rem !important;
  font-size: 0.8rem;
  padding: 0.1rem 0.1rem 0.1rem 0.1rem !important;
  margin: 0 !important;
}
.content-tread .button-goto-products {
  box-shadow: 0px -2px 2px 0px rgba(38, 38, 38, 0.3);
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #e5e5e5;
  display: block;
  width: 100%;
  height: 4rem;
}
.content-tread .button-goto-products a {
  height: 4rem;
  line-height: 4rem;
  display: block;
  color: #333333;
  font-weight: bold;
  padding: 0 0.5rem 0 1rem;
  font-size: 1.8rem;
}
.content-tread .button-goto-products a .icon {
  float: right;
  color: #b2b2b2;
}
.news-page {
  padding-bottom: 5rem;
}
.news-page > .main {
  border-right: 1px solid #f0f0f0;
  padding-right: 0;
  padding-bottom: 5rem;
}
.news-page > .sidebar {
  padding-left: 1.5rem;
  padding-top: 2.5rem;
}
#content-news .cover-image .image,
#content-news .cover-image > picture {
  border-bottom: 2px solid #de3a3e;
  margin: 0 auto;
}
#content-news .cover-image {
  position: relative;
}
#content-news .meta-data {
  margin-bottom: 1rem;
}
#content-news .meta-data .label {
  font-size: 1.2rem;
  font-weight: normal;
}
#content-news .meta-data .label.dark-gray {
  font-weight: bold;
}
#content-news .meta-data .meta-date.column {
  padding-right: 2rem !important;
}
#content-news .body img {
  max-width: 100%;
}
.news .container.main {
  padding-top: 4rem;
  padding-bottom: 8rem;
}
.news .pager-wrapper:last-child {
  padding-top: 5rem;
}
.content-news.teaser {
  box-shadow: none;
  border-radius: 1rem;
  color: #333333;
}
.content-news.teaser > :first-child {
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}
.content-news.teaser:hover {
  box-shadow: none !important;
  transform: none !important;
}
.content-news.teaser:hover > .title {
  background-color: #de3a3e;
}
.content-news.teaser:hover > .title h2 {
  color: #ffffff;
}
.content-news.teaser:hover > .continue {
  color: #de3a3e;
}
.content-news.teaser > .image {
  border-bottom: 2px solid #333333;
}
.content-news.teaser > .image img {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}
.content-news.teaser > .title {
  border-bottom: 2px solid #333333;
  border-top: none;
}
.content-news.teaser > .title h2 {
  font-size: 3rem;
  font-weight: 600;
}
.content-news.teaser > .body-teaser {
  background-color: #f0f0f0;
  border-top: none;
}
.content-news.teaser > .continue {
  border-top: 2px solid #e5e5e5;
  background-color: #f0f0f0;
  border-bottom-left-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
  color: #999999;
}
#content-news .sidebar {
  margin-top: 4rem !important;
}
#content-news .sidebar .sidebar-block > .news-tags .tag {
  margin-bottom: 1rem;
  font-weight: normal;
}
.discounts-contents {
  padding-top: 3rem;
}
.discounts-contents .discounts-title {
  font-size: 8.4rem;
}
.discounts-contents .quicklinks {
  padding-top: 3rem;
}
.discounts-contents .quicklinks .quicklinks-menu {
  border-top: 2px solid #f0f0f0;
  border-bottom: 2px solid #f0f0f0;
}
.discounts-contents .quicklinks .quicklinks-menu > .column :before {
  content: "";
  display: block;
  border-right: 1px solid #666666;
  height: 2rem;
  position: absolute;
  top: 1.5rem;
  right: 0;
}
.discounts-contents .quicklinks .quicklinks-menu > .column:last-child :before {
  display: none !important;
}
.discounts-contents .quicklinks .quicklinks-menu a {
  display: block;
  height: 5rem;
  line-height: 5rem;
  color: #666666;
  font-size: 2rem;
}
.discounts-contents .quicklinks .quicklinks-menu a:hover {
  color: #de3a3e;
}
.discounts-contents .page-content .show-description {
  color: #de3a3e;
  padding: 0.5rem 0;
}
#discounts-discounts-section {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.discount-teaser .content .title {
  font-weight: bold;
  padding-bottom: 2rem;
  font-size: 2rem;
}
.discount-teaser .content .title a {
  color: #262626;
}
.discount-teaser .content .title a:hover {
  color: #de3a3e;
}
.discount-teaser .content .continue {
  font-weight: bold;
  color: #333333;
}
.discount-teaser .content .continue:hover {
  color: #de3a3e;
}
.content-discount .content-panel {
  position: relative;
  padding-bottom: 3rem;
}
.content-discount .content-panel > .goto-down {
  position: relative;
  text-align: center;
  clear: both;
}
.content-discount .content-panel > .goto-down > .icons {
  cursor: pointer;
  position: absolute;
  top: -6.4rem;
  width: 6.4rem;
  height: 6.4rem;
  margin: 0 auto 0 auto;
  left: 0;
  right: 0;
  padding: 0;
  background-color: #de3a3e;
  box-shadow: 0px 0px 10px 3px rgba(51, 51, 51, 0.5);
  border-radius: 100%;
}
.content-discount .content-panel > .goto-down > .icons > .icon {
  width: 6.4rem;
  height: 6.4rem;
  font-size: 3rem;
  line-height: 6.4rem;
  vertical-align: middle;
  margin: 0;
  padding: 0;
}
.content-discount .content-panel .title {
  text-align: center;
  font-size: 4rem;
  font-weight: bold;
  line-height: 1.8em;
  padding: 3rem 1rem;
  border-bottom: 2px solid #f0f0f0;
}
.content-discount .content-panel .faq-list .row {
  border-bottom: 2px solid #f0f0f0;
}
.content-discount .content-panel .faq-list .question {
  color: #de3a3e;
  font-size: 2.25rem;
  line-height: 4rem;
  padding: 1rem;
}
.content-discount .content-panel .faq-list .answer {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.content-discount .content-panel .faq-list .answer a {
  color: #de3a3e;
}
.content-discount .content-panel .description-wrapper {
  padding: 3rem 0;
}
.content-discount .content-panel .description-wrapper .description {
  padding-bottom: 2rem;
  border-bottom: 2px solid #f0f0f0;
}
.content-discount.landing-page .landing-header {
  min-height: 60rem;
}
.content-discount.landing-page .landing-header .landing-page-title {
  padding-top: 3rem;
  font-size: 7rem;
  font-weight: bold;
  color: #ffffff;
  line-height: 1.5em;
  text-shadow: 5px 1px 0px rgba(38, 38, 38, 0.95), -1px -1px 5px rgba(38, 38, 38, 0.9);
}
.content-discount.landing-page .landing-header .landing-page-title .center-title {
  font-size: 15rem;
  line-height: 1.6em;
}
.content-discount.landing-page .landing-header .landing-page-title-placeholder {
  min-height: 60rem;
}
.content-discount.landing-page .landing-header .highlight-box {
  padding: 3rem 0 6rem 0;
}
.content-discount.landing-page .landing-header .highlight-box .text-panel {
  min-height: 6rem;
  background-color: #19a531;
  color: #ffffff;
  padding: 3rem 10rem;
  border-radius: 15rem;
}
.content-discount.landing-page .landing-header .highlight-box .text-panel .description-text {
  font-size: 2.8rem;
  line-height: 3.5rem;
}
.content-discount.landing-page .landing-header .highlight-box .text-panel .description-text strong {
  display: block;
}
.content-discount.landing-page .landing-header .highlight-box.with-image .image-panel {
  position: relative;
  float: left;
  min-height: 10rem;
  min-width: 10rem;
}
.content-discount.landing-page .landing-header .highlight-box.with-image .image-panel .image {
  position: absolute;
  top: -20%;
  left: 0;
  width: 20rem;
}
.content-discount.landing-page .landing-header .highlight-box.with-image .text-panel {
  margin-left: 10rem;
  padding-left: 15rem;
}
.content-discount.landing-page .content-panel {
  padding: 2rem;
  background-color: #ffffff;
  border-top-right-radius: 2rem;
  border-top-left-radius: 2rem;
  box-shadow: 0px 0px 10px 5px rgba(51, 51, 51, 0.5);
}
#discounts-top-sizes-section {
  padding-top: 3rem;
  padding-bottom: 2rem;
}
#discounts-top-sizes-section .bg-colored .grided > .column {
  border-left: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
  padding: 1rem 0 0.5rem !important;
}
#discounts-top-sizes-section .bg-colored .grided > .column:first-child {
  border-left: 2px solid #f0f0f0;
}
#discounts-top-sizes-section .bg-colored .grided > .column:last-child {
  border-right: 2px solid #f0f0f0;
}
#discounts-top-sizes-section .bg-colored.title {
  background-color: #e5e5e5;
}
#discounts-top-sizes-section .bg-colored.title .page-title {
  padding: 3rem;
  margin: 0;
}
#discounts-top-sizes-section .bg-colored.sizes {
  background-color: #e5e5e5;
  font-weight: bold;
}
#discounts-top-sizes-section .bg-colored.car {
  background-color: #e5e5e5;
}
#discounts-top-sizes-section .bg-colored.car .grided > .column {
  min-height: 15rem;
  padding: 1rem 0 !important;
}
#discounts-top-sizes-section .bg-colored.car .grided > .column a {
  display: block;
  color: #333333;
}
#discounts-top-sizes-section .bg-colored.suv-4x4 {
  background-color: #cccccc;
}
#discounts-top-sizes-section .bg-colored.suv-4x4 .grided > .column {
  min-height: 15rem;
  padding: 1rem 0 !important;
}
#discounts-top-sizes-section .bg-colored.suv-4x4 .grided > .column a {
  display: block;
  color: #333333;
}
#discounts-top-sizes-section .bg-colored.van {
  background-color: #b2b2b2;
}
#discounts-top-sizes-section .bg-colored.van .grided > .column {
  min-height: 15rem;
  padding: 1rem 0 !important;
}
#discounts-top-sizes-section .bg-colored.van .grided > .column a {
  display: block;
  color: #333333;
}
#discounts-top-sizes-section .bg-colored.line {
  background-color: #f0f0f0;
  height: 2px;
}
#discounts-top-sizes-section .bg-colored.bottom {
  background-color: #e5e5e5;
  height: 1rem;
}
#discounts-top-sizes-section .bg-colored .column-header-image {
  min-width: 18rem;
}
#discounts-description-section {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
#discount-products-section-wrapper {
  margin: 0 -2.1rem;
  min-height: 50rem;
}
#discount-products-section-wrapper .ui.dimmer {
  z-index: 10;
  justify-content: normal;
  vertical-align: auto;
}
#discount-products-section-wrapper .ui.dimmer .content {
  padding: 3rem 0;
}
#discount-products-section-wrapper .dimmer-icons .icon {
  font-size: 10rem;
}
#discounts-discount-products-section {
  padding: 3rem 2rem 9rem 2rem;
}
.content-discount .counters-block {
  border-bottom: 2px solid #f0f0f0;
}
.content-discount .countdown-wrapper {
  padding: 2rem 1rem;
  height: 10rem;
}
.countdown-wrapper {
  height: 8rem;
  padding: 1rem;
  display: flex;
}
.countdown-wrapper .countdown-icon {
  width: 6rem;
  height: 6rem;
  padding: 0.5rem;
  line-height: 1;
  vertical-align: middle;
}
.countdown-wrapper .countdown-icon .icon {
  color: #de3a3e;
  font-size: 4.5rem;
}
.countdown-wrapper .countdown {
  padding-left: 1rem;
}
.countdown-wrapper .countdown .countdown-counters {
  height: 4rem;
  width: 22.6rem;
  display: flex;
}
.countdown-wrapper .countdown .countdown-counters .countdown-number {
  display: inline-block;
  width: 3rem;
  height: 4rem;
  text-align: center;
  font-size: 3rem;
  font-weight: bold;
  padding-top: 0.5rem;
  background-color: #cccccc;
  background: linear-gradient(to bottom, #e5e5e5 0%, #e5e5e5 50%, #cccccc 50%, #cccccc 100%);
}
.countdown-wrapper .countdown .countdown-counters .countdown-number.left {
  border-radius: 0.5rem 0 0 0.5rem;
}
.countdown-wrapper .countdown .countdown-counters .countdown-number.right {
  border-radius: 0 0.5rem 0.5rem 0;
  margin-left: 0.2rem;
}
.countdown-wrapper .countdown .countdown-counters .countdown-divider {
  display: inline-block;
  width: 2rem;
  height: 4rem;
  text-align: center;
  font-size: 3rem;
  font-weight: bold;
  padding-top: 0.5rem;
}
.countdown-wrapper .countdown .countdown-labels {
  height: 2rem;
  width: 22.6rem;
  display: flex;
}
.countdown-wrapper .countdown .countdown-labels .countdown-label {
  display: inline-block;
  width: 6.2rem;
  text-align: center;
}
.countdown-wrapper .countdown .countdown-labels .countdown-divider {
  display: inline-block;
  width: 2rem;
  text-align: center;
  font-size: 3rem;
  font-weight: bold;
  padding-top: 0.5rem;
}
.countdown-wrapper .countdown-text {
  padding: 0.5rem 1rem;
  font-size: 2rem;
}
.countdown-wrapper.black-friday {
  color: #fff;
  align-items: center;
  justify-content: center;
}
.countdown-wrapper.black-friday .countdown-counters {
  height: 3rem;
}
.countdown-wrapper.black-friday .countdown-counters .countdown-number {
  background: none;
  color: #de3a3e;
}
.countdown-wrapper.black-friday .countdown-labels .countdown-label {
  font-weight: bold;
}
.product-descriptions .discount-url {
  position: relative;
  display: block;
}
.product-descriptions .discount-url .details-button {
  display: block;
  position: absolute;
  right: 15%;
  bottom: 10%;
  text-transform: uppercase;
}
.rim-search .rim-search-container {
  padding-bottom: 3rem;
}
.rim-search .car-form .select-field .dropdown.search,
.rim-search .car-form .select-field .dropdown.input {
  max-height: 5rem;
  line-height: 3rem;
}
.rim-search .car-form .select-field .dropdown.search > .text,
.rim-search .car-form .select-field .dropdown.input > .text {
  max-width: 100%;
}
.rim-search .car-form .select-field .dropdown.search.loading .dropdown.icon {
  top: 3rem !important;
  right: 3rem !important;
}
.rim-search .car-form .select-field .dropdown.search.loading .dropdown.icon:before {
  top: 2rem !important;
  left: 1rem !important;
  height: 2rem;
  width: 2rem;
}
.rim-search .car-form .select-field .dropdown.search.loading .dropdown.icon:after {
  top: 2rem !important;
  left: 1rem !important;
  height: 2rem;
  width: 2rem;
}
.rim-search .car-form .select-field .dropdown.icon {
  padding: 1rem !important;
  line-height: 2.5rem;
  top: 2.5rem;
  right: 2.5rem;
}
.rim-search .car-form .alcar-model-field .input .text,
.rim-search .car-form .alcar-type-field .input .text {
  overflow: hidden;
  white-space: nowrap;
}
.rim-search .rim-results-section {
  background-color: #e5e5e5;
  color: #333333;
  padding: 3rem 0;
}
.rim-search .rim-results-section .empty-message {
  font-size: 2.4rem;
}
.rim-search .car-image-section {
  padding: 3rem 0;
  background-color: #999999;
  color: #ffffff;
}
.rim-search .car-image-section .empty-message {
  font-size: 2.4rem;
}
.rim-search .car-image-section .alcar-car-image {
  background-color: #ffffff;
}
.rim-search .car-image-section .rim-container {
  position: relative;
  background-color: #ffffff;
  color: #333333;
  width: 100%;
  height: 100%;
  z-index: 5;
}
.rim-search .car-image-section .rim-container #request-send-form .field .prompt {
  position: absolute;
  z-index: 3;
  right: 20px;
  top: -30px;
}
.rim-search .car-image-section .rim-container #add-to-cart-form .quantity-wrapper .quantity-stepper-wrapper {
  margin-right: 5rem;
}
.rim-search .car-image-section .rim-container .request-send-success,
.rim-search .car-image-section .rim-container .shop-success {
  padding: 10px;
}
.rim-search .car-image-section .rim-container .request-send-success a,
.rim-search .car-image-section .rim-container .shop-success a {
  color: #de3a3e;
}
.rim-search .car-image-section .rim-container::before {
  position: absolute;
  content: " ";
  display: block;
  right: -36px;
  top: 89px;
  background-color: transparent;
  border-top: 36px solid transparent;
  border-right: none;
  border-left: 36px solid #333333;
  border-bottom: 36px solid transparent;
  z-index: 1;
  opacity: 0.4;
}
.rim-search .car-image-section .rim-container::after {
  z-index: 2;
  position: absolute;
  content: " ";
  display: block;
  right: -35px;
  top: 90px;
  background-color: transparent;
  border-top: 35px solid transparent;
  border-right: none;
  border-left: 35px solid #ffffff;
  border-bottom: 35px solid transparent;
}
#rim-search-results {
  background-color: #e5e5e5;
}
#rim-search-results .controls .hs-left-button .icon {
  padding-right: 4px;
}
#rim-search-results .controls .hs-right-button .icon {
  padding-left: 4px;
}
#rim-search-results .rim-search-results {
  white-space: nowrap;
  padding: 0;
  overflow: hidden;
}
#rim-search-results .rim-search-results .rim-search-result {
  position: relative;
  width: 15rem;
  display: inline-block;
  margin: 1rem;
}
#rim-search-results .rim-search-results .rim-search-result .storage-status {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  width: 25px;
}
#rim-search-results .rim-search-results .rim-search-result .sizes {
  text-align: center;
  width: 100%;
  color: #262626;
  background-color: #ffffff;
}
#rim-search-results .rim-search-results .rim-search-result .button {
  cursor: pointer;
  border: 3px solid #ffffff;
}
#rim-search-results .rim-search-results .rim-search-result .button:hover {
  border: 3px solid #b2b2b2;
}
#rim-search-results .rim-search-results .rim-search-result .button.active {
  border: 3px solid #de3a3e;
}
/*
 * service site
 */
#service-site #service-site-header {
  position: relative;
}
#service-site #service-site-header > .title.container {
  position: relative;
}
#service-site #service-site-header > .title.container .header {
  position: absolute;
  bottom: 20px;
  color: #ffffff;
  text-shadow: 0px 0px 8px #000000;
}
#service-site #service-site-header > .title.container .header.noimage {
  position: static;
  padding-top: 2rem;
}
#service-site #service-site-image {
  margin: auto;
}
#service-site #service-site-images {
  padding-top: 3rem;
}
#service-site #service-site-images .list .item {
  margin-left: 30px;
  padding: 0;
  position: relative;
}
#service-site #service-site-images .list .item .overlay {
  display: none;
}
#service-site #service-site-images .list .item.active .overlay {
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
#service-site #service-site-images .list .item.active .overlay:before {
  content: "";
  position: absolute;
  left: 85px;
  bottom: 0px;
  width: 10px;
  height: 10px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #ffffff transparent;
}
#service-site .contact-informations {
  padding-top: 3rem;
  font-size: 2rem;
}
#service-site .contact-informations .contact-informations-list {
  padding-right: 3rem;
}
#service-site .contact-informations .contact-informations-list .item {
  border-top: 1px solid #cccccc;
  padding: 1.5rem 0;
  margin: 0;
  line-height: 5rem;
}
#service-site .contact-informations .contact-informations-list .item:last-child {
  border-bottom: 1px solid #cccccc;
}
#service-site .contact-informations .contact-informations-list .item.openings {
  line-height: 2rem;
}
#service-site .contact-informations > .column {
  padding-bottom: 0;
}
#service-site .contact-informations > .column.line {
  padding-top: 0;
}
#service-site #available-services {
  padding-top: 8rem;
}
#service-site #available-services header {
  padding-bottom: 3rem;
}
#service-site #available-services .service-type-block {
  margin-top: 3rem !important;
  border-radius: 4px;
  background-color: #e5e5e5;
}
#service-site #available-services .service-type-block .service-type-icon.column {
  background-color: #333333;
  color: #ffffff;
  border-right: 4px solid #de3a3e;
  text-align: center;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  min-height: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
#service-site #available-services .service-type-block .service-type-title.column .header {
  padding-left: 2rem;
  font-size: 2rem;
}
#service-site #available-services .service-type-block .service-type-services > .grid {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
#service-site #available-services .service-type-block .service-type-services .service a {
  display: block;
  background-color: #ffffff;
  border-radius: 4px;
  color: #262626;
  margin: 0.5rem;
  padding: 1rem 1rem;
}
#service-site .column {
  position: relative;
}
#service-site .column #map {
  background-color: #f0f0f0;
  width: 100%;
  height: 100%;
}
#service-site .column #map .loading.icon {
  position: absolute;
  /*******************************
             Icon
*******************************/
  /*-------------------
   Icon Variables
--------------------*/
  /* Variations */
  top: calc(50% -  10px);
  left: calc(50% -  10px);
}
#service-site .column #map #map-gui {
  width: auto;
}
#service-site .service-site-description {
  padding-top: 3rem;
  padding-bottom: 6rem;
  font-size: 2rem;
}
.service-sites > div {
  position: relative;
}
.service-sites > div #map {
  height: 700px;
  background-color: #f0f0f0;
}
.service-sites > div #map .loading.icon {
  position: absolute;
  top: calc(50% -  7px);
  left: calc(50% -  7px);
}
.service-sites .page-teaser {
  padding-top: 3rem;
}
.service-sites .page-attachments {
  padding-top: 3rem;
}
.service-sites .page-content {
  padding-top: 3rem;
}
.service-sites .services-table {
  padding-top: 3rem;
}
.account-page .avatar {
  margin-bottom: 2rem;
}
.account-page .ui.vertical.menu > .active.item {
  background-color: #de3a3e;
  color: #ffffff;
}
.account-page .ui.vertical.menu > .active.item:hover {
  background-color: #e31b20;
  color: #ffffff;
}
.account-page .orders-table tbody tr {
  cursor: pointer;
}
.account-page .basic.button {
  box-shadow: none;
  font-weight: bold;
}
.account-page .basic.button:hover {
  background: none !important;
  box-shadow: none;
}
#order-view-modal label {
  font-weight: bold;
}
#order-view-modal > .content,
#address-edit-modal > .content,
#cars-edit-modal > .content {
  padding: 0 !important;
}
#order-view-modal > .content .form-block,
#address-edit-modal > .content .form-block,
#cars-edit-modal > .content .form-block {
  margin-bottom: 0;
}
#order-view-modal > .content .form-block.active > .top.attached.header,
#address-edit-modal > .content .form-block.active > .top.attached.header,
#cars-edit-modal > .content .form-block.active > .top.attached.header {
  border-radius: 0;
}
#order-view-modal > .content .form-block > .content,
#address-edit-modal > .content .form-block > .content,
#cars-edit-modal > .content .form-block > .content {
  padding: 1rem;
}
.login-page .login-block .ui.form ::-webkit-input-placeholder {
  color: #666666;
}
.login-page .login-block .ui.form :-moz-placeholder {
  color: #666666;
  opacity: 1;
}
.login-page .login-block .ui.form ::-moz-placeholder {
  color: #666666;
  opacity: 1;
}
.login-page .login-block .ui.form :-ms-input-placeholder {
  color: #666666;
}
.login-page .login-block .ui.form ::-ms-input-placeholder {
  color: #666666;
}
.login-page .login-block .ui.form ::placeholder {
  color: #666666;
  opacity: 1;
}
.login-page .login-block .form-block.active {
  border-radius: 10px;
  padding: 1rem;
}
.login-page .login-block .reset-group {
  display: none;
}
.login-page .login-block .login-group .button.login-forgot,
.login-page .login-block .reset-group .button.login-forgot,
.login-page .login-block .login-group .login-remembered,
.login-page .login-block .reset-group .login-remembered {
  box-shadow: none;
}
.login-page .login-block .login-group .button.login-forgot:hover,
.login-page .login-block .reset-group .button.login-forgot:hover,
.login-page .login-block .login-group .login-remembered:hover,
.login-page .login-block .reset-group .login-remembered:hover {
  background: none !important;
}
.login-page .login-block #login-forgot-password {
  box-shadow: none;
}
.login-page .login-block #login-forgot-password:hover {
  background: none !important;
}
.login-page .lead-text {
  font-size: 2rem;
  line-height: 5rem;
  margin-bottom: 1rem;
}
.login-page .lead-text .handwritten {
  display: block;
  color: #de3a3e;
  font-size: 3.75rem;
}
.login-page .ui.grid .social-login-row {
  margin-bottom: 2rem;
}
.login-page .ui.grid .social-login-row .button.social-login {
  width: 100%;
}
.login-page .ui.grid .registrationless-checkout,
.login-page .ui.grid .registration-block {
  padding-left: 0;
  padding-right: 0;
}
.login-page .ui.grid .registrationless-checkout .ui.grid > .column,
.login-page .ui.grid .registration-block .ui.grid > .column {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.login-page .ui.grid .registrationless-checkout .ui.form input,
.login-page .ui.grid .registration-block .ui.form input {
  background-color: #e5e5e5;
  border-color: #e5e5e5;
  color: #333333;
}
.login-page .ui.grid .registrationless-checkout .ui.form input::placeholder,
.login-page .ui.grid .registration-block .ui.form input::placeholder {
  color: #666666;
  opacity: 1;
}
.login-page .ui.grid .registrationless-checkout .basic.button,
.login-page .ui.grid .registration-block .basic.button {
  font-weight: bold;
}
.login-page .appreciation {
  background: #f0f0f0;
  padding-top: 2rem;
}
.login-page .appreciation .lead-text {
  font-size: 2.75rem;
  margin-bottom: 0;
}
.login-page .appreciation .lead-text .handwritten {
  font-size: 6rem;
  line-height: 6rem;
}
.login-page .appreciation .appreciation-image-block {
  min-height: 320px;
}
.login-page .appreciation .appreciation-image {
  position: absolute;
  bottom: 0;
  left: 1rem;
}
@media all and (max-width: 767px) {
  .login-page .appreciation .appreciation-image {
    display: none;
  }
}
.login-page .appreciation ul {
  position: relative;
  padding-left: 3rem;
}
.login-page .appreciation ul li {
  list-style-type: none;
  padding-bottom: 1rem;
}
.login-page .appreciation ul li:before {
  display: inline-block;
  opacity: 1;
  margin: 0.2em 0.25rem 0em 0em;
  width: 1.18em;
  height: 1em;
  font-family: 'Icons';
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  text-align: center;
  speak: none;
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  font-size: 1.8rem;
  color: #19a531 !important;
  content: "\f058";
  position: absolute;
  left: 0;
}
.ui.social-login {
  overflow: hidden;
}
.ui.google.plus.social-login {
  background: #ffffff;
  border: 1px solid #DD4B39;
  color: #DD4B39;
}
.ui.google.plus.social-login.button > .icon {
  color: #ffffff;
  background: #DD4B39;
  border-radius: 0;
}
.ui.facebook.social-login {
  background: #ffffff;
  border: 1px solid #3B5998;
  color: #3B5998;
}
.ui.facebook.social-login.button > .icon {
  color: #ffffff;
  background: #3B5998;
  border-radius: 0;
}
.ui.apple.button {
  background-color: #000000;
  color: #ffffff;
  text-shadow: none;
  background-image: none;
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}
.ui.apple.button:hover {
  background-color: #1a1a1a;
}
.ui.apple.social-login {
  background: #ffffff;
  border: 1px solid #000000;
  color: #000000;
}
.ui.apple.social-login.button > .icon {
  color: #000000;
  background: #ffffff;
  border-radius: 0;
}
.ui.apple.social-login.button:hover {
  background-color: #000000;
  color: #ffffff;
  text-shadow: none;
}
.ui.apple.social-login.button:hover > .icon {
  color: #ffffff;
  background-color: #1a1a1a;
}
.unique-contents .unique-contents-background {
  min-height: 30rem;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.unique-contents .header.hero {
  line-height: 30rem;
  margin: 0;
  font-size: 12rem;
}
.unique-contents .subcategories-menu {
  padding-top: 3rem;
  color: #ffffff;
}
.unique-contents .subcategories-menu .column a {
  display: block;
  height: 12rem;
  background-color: #808080;
  border-radius: 1rem;
  color: #ffffff;
  font-weight: bold;
  padding: 1.5rem;
}
.unique-contents .subcategories-menu .column a img {
  display: block;
  max-height: 8rem;
}
.unique-contents .subcategories-menu .column a .title {
  display: block;
}
.unique-contents .subcategories-menu .column.label .arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 12rem;
  font-size: 2.2rem;
  font-weight: bold;
  background-color: #333333;
  margin-right: 3rem;
  padding-left: 2rem;
  position: relative;
  border-right: 0.5rem solid #de3a3e;
}
.unique-contents .subcategories-menu .column.label .arrow:before {
  content: "";
  position: absolute;
  right: -2.5rem;
  top: 0px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6rem 0 6rem 2rem;
  border-color: transparent transparent transparent #de3a3e;
}
.unique-contents .subcategories-menu .column.label .arrow:after {
  content: "";
  position: absolute;
  right: -2rem;
  top: 0px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6rem 0 6rem 2rem;
  border-color: transparent transparent transparent #333333;
}
.unique-contents .highlighted-offers {
  padding-top: 3rem;
}
.unique-contents .news {
  padding-top: 3rem;
}
.unique-contents .news .box-title {
  margin-bottom: 0;
}
.unique-contents .news > .ui.grid {
  padding-top: 3rem;
}
.unique-contents .page-content {
  padding-top: 3rem;
}
.page-unique-content .page-content {
  padding-bottom: 3rem;
}
.highlighted-offers-panel {
  padding-top: 6rem;
}
.highlighted-offers-panel .products {
  padding-top: 3rem;
}
.news-box-panel {
  padding-top: 6rem;
}
.news-box-panel .news > .ui.grid {
  padding-top: 6rem;
}
.home .main.container {
  padding-bottom: 6rem;
}
#map-gui {
  display: none;
}
#map #map-gui {
  display: block;
  position: absolute !important;
  top: 2rem !important;
  right: 2rem !important;
  width: 50%;
  max-width: 300px;
  border-radius: 5px;
}
#map #map-gui .field {
  width: 100%;
}
#map #map-gui .field input {
  box-shadow: 1px 1px 5px #cccccc;
}
#map #map-gui .field input::placeholder {
  color: #666666;
  opacity: 1;
}
#map #map-gui .checkbox-field .ui.segment {
  height: 40px;
}
#map #map-gui .checkbox-field .ui.segment label {
  position: absolute;
  top: 50%;
  transform: perspective(1px) translateY(-50%);
  padding-top: 2px;
}
#map #map-gui .checkbox-field .ui.segment label .on,
#map #map-gui .checkbox-field .ui.segment label .off {
  vertical-align: text-bottom;
  margin-right: 0.5rem;
  margin-top: -1px;
  font-size: 1.4rem;
}
.si-float-wrapper .si-wrapper-top .si-content-wrapper {
  border-radius: 25px;
  border-top-right-radius: 0;
  padding: 0;
  overflow: visible;
}
.si-float-wrapper .si-wrapper-top .si-content-wrapper .si-close-button {
  top: -15px;
  right: -15px;
  background-color: #fff;
  color: #333;
  opacity: 1;
  box-shadow: 0 1px 3px 0 #000;
  border-radius: 30px;
  width: 30px;
  height: 30px;
}
.si-float-wrapper .si-wrapper-top .si-content-wrapper .si-content {
  border-radius: 25px;
  border-top-right-radius: 0;
}
.si-float-wrapper .si-wrapper-top .si-content-wrapper .si-content .cover-image {
  max-width: 400px;
  max-height: 91px;
}
.si-float-wrapper .si-wrapper-top .si-content-wrapper .si-content .wrapper {
  padding: 20px;
  width: 400px;
}
.si-float-wrapper .si-wrapper-top .si-content-wrapper .si-content .wrapper .title {
  color: #de3a3e;
  margin-bottom: 5px;
}
.si-float-wrapper .si-wrapper-top .si-content-wrapper .si-content .wrapper .content {
  font-size: 1.35rem;
}
.si-float-wrapper .si-wrapper-top .si-content-wrapper .si-content .wrapper .content ul {
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;
  padding-left: 0;
}
.si-float-wrapper .si-wrapper-top .si-content-wrapper .si-content .wrapper .content a {
  color: #de3a3e;
  font-weight: bold;
}
.si-float-wrapper .si-wrapper-top .si-frame.si-shadow-frame {
  border-radius: 25px;
  border-top-right-radius: 0;
  box-shadow: 0px 5px 10px 2px #000000;
}
#banners-home-slider {
  position: relative;
  overflow: hidden;
  height: 23.4375vw;
}
#banners-home-slider .control-panel {
  position: absolute;
  bottom: 0;
  width: 100%;
}
#banners-home-slider .control-panel .previous-button,
#banners-home-slider .control-panel .next-button {
  position: absolute;
  text-shadow: 1px 0 2px #000000, -1px 0 2px #000000;
  bottom: 8.71875vw;
  margin: 0;
}
#banners-home-slider .control-panel .previous-button {
  left: -4rem;
}
#banners-home-slider .control-panel .next-button {
  right: -4rem;
}
#banners-home-slider .control-panel .direct-buttons {
  padding-bottom: 1.5rem;
}
#banners-home-slider .control-panel .direct-buttons .button {
  box-shadow: 0px 0px 0.4rem #000000;
  background-color: #ffffff;
  padding: 0.5rem;
  border-radius: 50%;
  font-size: 0;
  margin-left: 1rem;
  margin-right: 1rem;
}
#banners-home-slider .control-panel .direct-buttons .button span {
  display: block;
  padding: 0.5rem;
  background-color: transparent;
  border-radius: 0.5rem;
}
#banners-home-slider .control-panel .direct-buttons .button.active span {
  background-color: #000000;
}
#banners-home-slider .banner {
  position: absolute;
  top: 0;
  display: block;
  width: 100%;
  background-position: center top;
  overflow: hidden;
}
#banners-home-slider .banner img {
  width: 100%;
  height: 23.4375vw;
}
#banners-home-slider .banner .content-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
#banners-home-slider .banner.hidden {
  display: none;
}
#banners-home-slider .banner .text {
  padding-top: 1.5625vw !important;
  height: 23.4375vw;
}
#banners-home-slider .banner .text .header,
#banners-home-slider .banner .text .subheader,
#banners-home-slider .banner .text .description,
#banners-home-slider .banner .text .button {
  margin: 0 1.5625vw 1.5625vw 1.5625vw;
}
#banners-home-slider .banner .text .header {
  min-height: 2.9296875vw;
  font-style: italic;
  color: #ffffff;
  font-size: 2.9296875vw;
  text-shadow: #000 1px 1px 0;
}
#banners-home-slider .banner .text .subheader {
  min-height: 1.5625vw;
  display: inline-block;
  font-weight: normal;
  font-size: 1.5625vw;
  line-height: 1.5625vw;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.175);
  color: #ffffff;
  background-color: #808080;
  padding: 1rem 2rem;
}
#banners-home-slider .banner .text .description {
  max-width: 80%;
  color: #ffffff;
  font-size: 1.37867647vw;
  line-height: 1.37867647vw;
  text-shadow: #000 1px 1px 0;
}
#banners-home-slider .banner .text .button {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.175);
  color: #333333;
  border: 2px solid #808080;
  border-radius: 0.5rem;
  font-size: 1.171875vw;
  background-color: #ffffff;
}
#banners-home-slider .banner .text .button .icon {
  font-size: 1.5625vw;
  border-radius: 0;
  opacity: 1;
  background-color: #808080;
}
#banners-home-slider .banner.style-red .text .subheader {
  background-color: #de3a3e;
}
#banners-home-slider .banner.style-red .text .button {
  color: #ffffff;
  border-color: #de3a3e;
  background-color: #3f51b5;
}
#banners-home-slider .banner.style-red .text .button .icon {
  background-color: #de3a3e;
}
#banners-home-slider .banner.style-green .text .subheader {
  background-color: #19a531;
}
#banners-home-slider .banner.style-green .text .button {
  color: #ffffff;
  border-color: #19a531;
  background-color: #d77c30;
}
#banners-home-slider .banner.style-green .text .button .icon {
  background-color: #19a531;
}
#banners-home-slider .banner.style-blue .text .subheader {
  background-color: #3f51b5;
}
#banners-home-slider .banner.style-blue .text .button {
  color: #ffffff;
  border-color: #3f51b5;
  background-color: #de3a3e;
}
#banners-home-slider .banner.style-blue .text .button .icon {
  background-color: #3f51b5;
}
#banners-home-slider .banner.style-black .text .subheader {
  background-color: #000000;
}
#banners-home-slider .banner.style-black .text .button {
  color: #ffffff;
  border-color: #000000;
  background-color: #808080;
}
#banners-home-slider .banner.style-black .text .button .icon {
  background-color: #000000;
}
.fake-product-banner {
  height: 100%;
  width: 100%;
}
.fake-product-banner .banner.url {
  display: grid;
  width: 100%;
  height: 100%;
  align-content: center;
}
.fake-product-banner .banner.image {
  width: 100%;
}
.in-news-banner {
  margin-bottom: 2rem;
}
#banners-bottom-popup {
  position: fixed;
  top: 0;
  height: 100%;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
}
#banners-bottom-popup > .wrapper {
  padding: 2rem 0;
  position: absolute;
  bottom: -500px;
  left: 0;
  right: 0;
  background-color: #ffffff;
  transition: all 300ms ease-in;
}
#banners-bottom-popup > .wrapper.show {
  bottom: 0;
}
#banners-bottom-popup > .wrapper > .ui.container {
  max-width: 600px !important;
}
#banners-bottom-popup > .wrapper .ui.unstackable.items > .item {
  align-items: center;
}
#banners-bottom-popup > .wrapper .main-image {
  padding: 0 2rem 0 0;
}
#banners-bottom-popup > .wrapper .cab-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
#banners-bottom-popup > .wrapper .cab-wrapper > a {
  display: block;
}
#banners-bottom-popup > .wrapper .cab-wrapper > a > img {
  max-height: 8rem;
}
#banners-bottom-popup > .wrapper .cab-wrapper.no-os-info > a > img {
  max-height: 5.5rem;
}
#banners-bottom-popup > .wrapper .cancel-button {
  margin: 2rem 0 4rem 0;
}
#banners-home-secondary {
  padding-top: 6rem;
  margin: auto;
  max-width: 970px;
}
#banners-home-secondary .banner.image {
  width: 100%;
  text-align: center;
  display: block;
}
.services .ui.container .content {
  padding-bottom: 3rem;
}
.services .services-background {
  position: relative;
  overflow: hidden;
  min-height: 30rem;
  margin-bottom: 3rem;
  background-color: #ffffff;
  background-position: center center;
  background-repeat: no-repeat;
}
.services .services-background picture {
  position: absolute;
  margin: auto 50vw;
  transform: translateX(-50%);
}
.services .services-background > .container {
  position: relative;
}
.services .header.hero {
  line-height: 30rem;
}
.services .ui.card.content-service .continue {
  background-color: #333333;
  color: #ffffff;
  font-weight: bold;
  font-size: 1.8rem;
  padding-top: 1rem;
}
.services .ui.image.service {
  background-color: #f0f0f0;
  position: absolute;
  top: -75px;
}
.services article {
  position: relative;
}
.services article .header {
  min-height: 15rem;
  max-height: 17rem;
}
.services article .header .title {
  font-size: 4rem;
}
.services .offer-request-button-wrapper {
  margin-top: 3rem;
}
.services .offer-request-button-wrapper .offer-request-button {
  margin-top: 3rem;
}
.services .sidebar {
  border-left: 1px solid #cccccc;
}
#marso-services-table {
  border-radius: 0;
  border: none;
}
#marso-services-table thead th {
  background-color: #333333;
  color: #ffffff;
}
#marso-services-table tr td {
  border-top: 0;
}
#marso-services-table.striped.table > tr:nth-child(2n),
#marso-services-table.striped.table tbody tr:nth-child(2n) {
  background-color: #e5e5e5;
}
.services-box > .message {
  box-shadow: none;
  border-radius: 10px;
  background-color: #e5e5e5;
  margin: 3rem 0 !important;
}
.services-box .content-service.teaser > .image {
  position: relative;
  border-bottom: 4px solid #333333;
}
.services-box .content-service.teaser > .image .image {
  margin: auto;
}
.services-box .content-service.teaser > .image .name {
  width: 100%;
  position: absolute;
  bottom: 10px;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 1px 1px 2px rgba(38, 38, 38, 0.95), -1px -1px 2px rgba(38, 38, 38, 0.9);
}
.services-box .content-service.teaser .description-block {
  background-color: #e5e5e5;
}
.services-box .content-service.teaser .description-block .marso-icon-info {
  color: #b2b2b2;
  margin-right: 0;
}
.services-box .content-service.teaser .description-block .description {
  background-color: #ffffff;
  padding: 0.5rem !important;
  font-size: 1.5rem;
}
.services-box .content-service.teaser .description-block .description .text {
  max-height: 10rem;
  overflow: hidden;
}
.services-box .content-service.teaser .description-block .price {
  font-weight: bold;
  font-size: 2.75rem;
  line-height: 2.4rem;
  padding-top: 10px;
}
.services-box .content-service.teaser .cart-button {
  border-top: none;
  padding: 1rem 0;
}
.services-box .content-service.teaser .cart-button .service-request-form {
  width: 100%;
  padding: 0;
  display: block;
}
.services-box .content-service.teaser .cart-button .service-request-form .add-to-cart-button {
  padding: 1.3rem 1rem;
}
.informations-menu-panel {
  padding-top: 6rem;
}
.informations-menu-panel .menu {
  border-top: 2px solid #f0f0f0 !important;
  border-bottom: 2px solid #f0f0f0 !important;
}
.informations-menu-panel .menu .item {
  padding: 1.5rem 0;
  width: 25%;
  font-size: 1.7rem;
}
.informations-menu-panel .menu .item .icon {
  font-size: 3.6rem;
}
#contact-page .data-panel {
  padding-top: 2rem;
  padding-bottom: 4rem;
  font-size: 2.1rem;
}
#contact-page .data-panel .item {
  height: 5rem;
  line-height: 3rem;
  padding: 1rem 0 !important;
  border-top: 2px solid #f0f0f0;
}
#contact-page .data-panel .item .icon {
  font-size: 3rem;
  margin-top: -0.7rem;
}
#contact-page .data-panel .item .content {
  padding-left: 1rem;
}
#contact-page .data-panel .item .icon-content {
  float: left;
  padding-left: 0;
  padding-right: 1rem;
}
#contact-page .data-panel .item:last-child {
  border-bottom: 2px solid #f0f0f0;
}
#contact-page .data-panel .company-name {
  font-weight: bold;
}
#contact-page .data-panel .phone a {
  color: #de3a3e;
}
#contact-page .data-panel .email a {
  color: #de3a3e;
}
#contact-page .service-site-search-panel {
  background-color: #e5e5e5;
  padding: 2.5rem 0 2.5rem;
}
#contact-page .service-site-search-panel .question h1 {
  font-size: 4.2rem;
}
#contact-page .service-site-search-panel .answer .button {
  font-size: 2.5rem;
  line-height: 4rem;
  padding: 2rem 1rem 2rem 2rem !important;
}
#contact-page .form-block .close-button {
  display: none;
}
.contact-form-panel {
  background-color: #de3a3e;
  padding-bottom: 2rem;
  padding-top: 4rem;
}
.contact-form-panel .description {
  font-size: 1.6rem;
}
.contact-form-panel .callcenter {
  margin-top: 2rem;
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
}
.contact-form-panel .callcenter .item.address {
  font-weight: bold;
}
.contact-form-panel .callcenter .item.address .icon {
  font-size: 4.5rem;
}
.contact-form-panel .callcenter .item.address:first-child {
  padding-top: 1rem !important;
}
.contact-form-panel .callcenter .item.address:last-child {
  padding-bottom: 1rem !important;
}
.contact-form-panel .callcenter .item.address .content {
  font-size: 1.8rem;
}
.contact-form-panel .callcenter .item.address .icon-content {
  float: left;
  padding-right: 1rem;
}
.contact-form-panel .question {
  color: #ffffff;
}
.contact-form-panel .question h2 {
  margin-bottom: 2rem;
  font-size: 3.75rem;
  line-height: 3.75rem;
  font-weight: normal !important;
}
#contact-modal .contact-form-panel {
  padding: 4rem 3rem 2rem 3rem;
}
#contact-form .field-label-wrapper .field-label {
  background-color: #f0f0f0;
  color: #262626 !important;
  font-weight: normal;
  text-transform: none;
}
.ui.modal.cart-exit-intent-modal {
  font-size: 2rem;
  padding: 3rem;
  border-radius: 2.5rem;
}
.ui.modal.cart-exit-intent-modal .header .h1 {
  color: #de3a3e;
}
.ui.modal.cart-exit-intent-modal .header .subheader {
  font-weight: normal;
  font-size: 2rem;
}
.ui.modal.cart-exit-intent-modal .ui.form .field-textarea .field-input {
  height: auto;
  line-height: 1.75rem;
  border: 1rem solid #e5e5e5;
}
.ui.modal.cart-exit-intent-modal .content {
  padding-top: 3rem;
}
.ui.modal.cart-exit-intent-modal .description strong {
  color: #de3a3e;
}
.ui.modal.cart-exit-intent-modal .actions {
  padding-top: 3rem;
  font-size: 1.5rem;
}
.ui.modal.cart-exit-intent-modal .actions .button {
  margin-left: 0;
}
.sidebar .coupon-form {
  border: 2px solid #de3a3e;
  border-radius: 0.5rem;
  padding: 3rem;
  margin-bottom: 3rem;
}
.sidebar .coupon-form .title {
  font-size: 1.8rem;
}
.sidebar .coupon-form .error-label {
  left: 0 !important;
}
.sidebar .coupon-form input {
  border-radius: 0.5rem;
}
.sidebar .coupon-form input::placeholder {
  color: #b2b2b2;
}
.sidebar .coupon-form input:focus {
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}
.donate-coupon-form .items-container {
  margin: 0 auto;
  display: grid;
  grid-gap: 1rem;
}
.donate-coupon-form .item {
  padding: 7px;
  position: relative;
  width: 100%;
  display: inline-block;
}
.donate-coupon-form #donationHeader {
  text-transform: uppercase;
}
.donate-coupon-form img {
  object-fit: scale-down;
  max-width: 100%;
}
.donate-coupon-form .foundation-name {
  position: absolute;
  z-index: 1;
  bottom: 28%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  background-color: white;
  font-weight: bold;
  padding: 5px 10px;
  opacity: 0.8;
}
.donate-coupon-form button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
@media (min-width: 600px) {
  .donate-coupon-form .items-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 900px) {
  .donate-coupon-form .items-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
.mobile-layout .donate-coupon-form #donationHeader {
  padding: 0 24px;
}
.mobile-layout .donate-coupon-form #donationSubtitle {
  padding: 0 24px;
}
.tab-menu-panel {
  padding-top: 3rem;
}
.tab-menu-panel .tab-menu-panel-header > .column {
  border-left: 1px solid #ffffff;
}
.tab-menu-panel .tab-menu-panel-header > .column a.button {
  padding-top: 2.4rem;
}
.tab-menu-panel .tab-menu-panel-header > .column .button {
  padding: 1rem 1rem;
  height: 10rem;
  font-weight: normal;
}
.tab-menu-panel .tab-menu-panel-header > .column .button .icon {
  opacity: 1;
}
.tab-menu-panel .tab-menu-panel-header > .column .button .label {
  padding-top: 0.5rem;
  font-size: 1.7rem;
  line-height: 1.8rem;
  display: block;
}
.tab-menu-panel .tab-menu-panel-header > .column .button.active {
  font-weight: bold;
  position: relative;
  background-color: #e5e5e5 !important;
  box-shadow: 0 0 0 10px #e5e5e5;
  color: #808080;
  z-index: 1;
}
.tab-menu-panel .tab-menu-panel-header > .column .button.active:hover {
  color: #666666;
}
.tab-menu-panel .tab-menu-panel-header > .column .button.active:after {
  content: "";
  position: absolute;
  bottom: -30px;
  left: 50%;
  border-bottom: 20px solid transparent;
  border-right: none;
  border-left: 20px solid #e5e5e5;
  border-top: none;
}
.tab-menu-panel .tab-menu-panel-header > .column .button.active:before {
  content: "";
  position: absolute;
  bottom: -30px;
  right: 50%;
  border-bottom: 20px solid transparent;
  border-right: 20px solid #e5e5e5;
  border-left: none;
  border-top: none;
}
.tab-menu-panel .tab-menu-panel-contents {
  padding-top: 4rem;
  padding-bottom: 3rem;
}
.eu-tyre-label-container .tyre-label-image {
  width: 338px;
  height: 495px;
  margin: auto;
  position: relative;
  font-family: 'Verdana', 'sans-serif';
}
.eu-tyre-label-container .tyre-label-image #qr-code-panel {
  position: absolute;
  right: 0;
  top: 0;
}
.eu-tyre-label-container .tyre-label-image .product-information-panel {
  position: absolute;
  top: 68px;
  left: 15px;
  width: 100%;
}
.eu-tyre-label-container .tyre-label-image .product-information-panel .manufacturer-name {
  padding-top: 2px;
  font-weight: bold;
}
.eu-tyre-label-container .tyre-label-image .product-information-panel .manufacturer-name #eprel-id {
  position: absolute;
  font-weight: normal;
  right: 30px;
  top: 3px;
  float: right;
}
.eu-tyre-label-container .tyre-label-image .product-information-panel .size {
  padding-top: 8px;
  font-weight: lighter;
}
.eu-tyre-label-container .tyre-label-image .product-information-panel #tyre-class {
  position: absolute;
  right: 30px;
  top: 35px;
  float: right;
}
.eu-tyre-label-container .tyre-label-image .fuel-panel,
.eu-tyre-label-container .tyre-label-image .wet-grip-panel {
  position: absolute;
  top: 83px;
  left: 125px;
  width: 37px;
  height: 120px;
}
.eu-tyre-label-container .tyre-label-image .fuel-panel .value,
.eu-tyre-label-container .tyre-label-image .wet-grip-panel .value {
  width: 37px;
  height: 25px;
  position: absolute;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  padding-left: 10px;
  padding-top: 2px;
}
.eu-tyre-label-container .tyre-label-image .fuel-panel .value .value-absolute,
.eu-tyre-label-container .tyre-label-image .wet-grip-panel .value .value-absolute {
  position: absolute;
  left: 0;
}
.eu-tyre-label-container .tyre-label-image .fuel-panel .value .value-relative,
.eu-tyre-label-container .tyre-label-image .wet-grip-panel .value .value-relative {
  position: relative;
  z-index: 1;
  top: 2px;
}
.eu-tyre-label-container .tyre-label-image .fuel-panel .value-a,
.eu-tyre-label-container .tyre-label-image .wet-grip-panel .value-a {
  top: 124px;
}
.eu-tyre-label-container .tyre-label-image .fuel-panel .value-b,
.eu-tyre-label-container .tyre-label-image .wet-grip-panel .value-b {
  top: 159px;
}
.eu-tyre-label-container .tyre-label-image .fuel-panel .value-c,
.eu-tyre-label-container .tyre-label-image .wet-grip-panel .value-c {
  top: 196px;
}
.eu-tyre-label-container .tyre-label-image .fuel-panel .value-d,
.eu-tyre-label-container .tyre-label-image .wet-grip-panel .value-d {
  top: 232px;
}
.eu-tyre-label-container .tyre-label-image .fuel-panel .value-e,
.eu-tyre-label-container .tyre-label-image .wet-grip-panel .value-e {
  top: 269px;
}
.eu-tyre-label-container .tyre-label-image .wet-grip-panel {
  left: 290px !important;
}
.eu-tyre-label-container .tyre-label-image .product-pictogram-panel {
  position: absolute;
  top: 393px;
  width: 94%;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
}
.eu-tyre-label-container .tyre-label-image .product-pictogram-panel .value {
  height: 100%;
  width: 100%;
}
.eu-tyre-label-container .tyre-label-image .noise-measurement-value-panel {
  position: absolute;
  top: 236px;
  left: 140px;
  width: 50px;
  height: 26px;
}
.eu-tyre-label-container .tyre-label-image .noise-measurement-value-panel .value {
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  line-height: 26px;
}
.eu-tyre-label-container .tyre-label-image .noise-measurement-class-panel {
  position: relative;
  display: inline-block;
  left: 10px;
  width: 100px;
  height: 76px;
  margin-left: 10px;
  margin-right: 10px;
}
.eu-tyre-label-container .tyre-label-image .noise-measurement-class-value-panel {
  position: absolute;
  bottom: -4px;
  left: 40px;
}
.eu-tyre-label-container .tyre-label-image .noise-measurement-class-value-panel .noise-measurement-class-value {
  font-weight: bold;
  padding: 1px;
}
.eu-tyre-label-container .tyre-label-image .noise-measurement-value {
  position: absolute;
  top: 19px;
  left: 26px;
  font-weight: bold;
}
.eu-tyre-label-container .tyre-label-image .pmsf-notation-panel {
  position: relative;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  width: 65px;
  height: 57px;
}
.eu-tyre-label-container .tyre-label-image .ig-notation-panel {
  position: relative;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  width: 65px;
  height: 69px;
}
.recommended-products-panel,
.similar-products-panel {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.recommended-products-panel .header-wrapper.ui.grid,
.similar-products-panel .header-wrapper.ui.grid {
  position: relative;
  height: 100%;
  background-color: #333333;
  color: #ffffff;
  margin-right: 3rem !important;
  padding: 3rem;
}
.recommended-products-panel .header-wrapper.ui.grid .arrow,
.similar-products-panel .header-wrapper.ui.grid .arrow {
  top: 0;
  padding: 0;
  position: absolute;
  right: -3rem;
  height: 100%;
  min-height: 100%;
  width: 3rem;
  display: block;
}
.recommended-products-panel .header-wrapper.ui.grid .arrow img,
.similar-products-panel .header-wrapper.ui.grid .arrow img {
  width: 100%;
  height: 100%;
}
.recommended-products-panel .header-wrapper.ui.grid .header .icon,
.similar-products-panel .header-wrapper.ui.grid .header .icon {
  font-size: 10rem;
}
.recommended-products-panel .header-wrapper.ui.grid .header .text,
.similar-products-panel .header-wrapper.ui.grid .header .text {
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid #de3a3e;
  font-size: 2.5rem;
}
.sibling-brands-products-panel {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.sibling-brands-products-panel .header-wrapper.ui.grid {
  position: relative;
  height: 100%;
}
.sibling-brands-products-panel .header-wrapper.ui.grid .header .logo-image {
  width: 100%;
  display: block;
}
.sibling-brands-products-panel .header-wrapper.ui.grid .header .text {
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 3px solid #de3a3e;
  font-size: 2.5rem;
}
.error-404-header-panel {
  min-height: 50rem;
  padding-top: 30rem;
  background: transparent url(../images/error-404.png) no-repeat center 4rem;
}
.error-404-header-panel h1 {
  color: #de3a3e;
  font-size: 11rem !important;
  line-height: 7rem;
}
.error-404-header-panel h1:after {
  display: none;
}
.error-404-header-panel h2 {
  color: #de3a3e;
  font-size: 6rem;
}
.menu-error-404 {
  padding-bottom: 6rem;
}
.menu-error-404 h1 {
  color: #de3a3e;
}
.menu-error-404 .grid {
  padding-top: 1rem;
}
.menu-error-404 .item {
  display: block;
  min-height: 20rem;
  background-color: #f0f0f0;
  padding: 2rem;
  border-radius: 1rem;
  color: #de3a3e;
}
.menu-error-404 .item .icons {
  display: block;
  margin: auto;
  background-color: #ffffff;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  line-height: 10rem;
}
.menu-error-404 .item .icons .icon {
  font-size: 7rem;
  vertical-align: middle;
}
.menu-error-404 .item .text {
  padding-top: 1.5rem;
  color: #262626;
  font-weight: bold;
  font-size: 1.8rem;
  display: block;
}
#error-404-page #contact-form .close-button {
  display: none;
}
.page-thank-you .main.container {
  padding-bottom: 3rem;
}
#quick-messages,
.quick-messages {
  display: none;
}
#quick-messages.active,
.quick-messages.active {
  display: block;
  position: fixed;
  bottom: 5rem;
  left: 50%;
  margin-left: -150px;
  max-width: 90%;
  z-index: 99999;
}
#quick-messages .message,
.quick-messages .message {
  width: 300px;
  margin: auto;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  box-shadow: none;
  font-weight: bold;
  color: #ffffff;
}
#quick-messages .message .icon,
.quick-messages .message .icon {
  opacity: 1;
}
#quick-messages .message .close.icon,
.quick-messages .message .close.icon {
  position: relative;
  bottom: 0;
  top: 0;
  margin-bottom: 0.5rem;
  right: 0;
}
#quick-messages .message.info,
.quick-messages .message.info {
  background-color: #3f51b5;
  border-color: #3f51b5;
}
#quick-messages .message.info .close.icon,
.quick-messages .message.info .close.icon {
  color: #de3a3e;
}
#quick-messages .message.error,
.quick-messages .message.error {
  background-color: #de3a3e;
  border-color: #de3a3e;
}
#quick-messages .message.error .close.icon,
.quick-messages .message.error .close.icon {
  color: #3f51b5;
}
#quick-messages .message.white,
.quick-messages .message.white {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #000000;
}
#quick-messages .message.white .close.icon,
.quick-messages .message.white .close.icon {
  color: #000000;
}
#quick-messages .message.info,
.quick-messages .message.info,
#quick-messages .message.error,
.quick-messages .message.error,
#quick-messages .message.white,
.quick-messages .message.white {
  border-radius: 0.5rem;
  border-width: 2px;
  border-style: solid;
}
.cetelem-box {
  margin-bottom: 5%;
  display: inline-block;
}
#cetelem-calculator {
  display: inline-block;
}
.cetelem-icon {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: none;
  background-image: url(../../../../resources/cetelem/cetelem-badge.png);
}
.product-list .cetelem-icon {
  margin-top: 5px;
  margin-left: -7px;
}
.product-page .cetelem-icon {
  position: absolute;
  top: 2px;
  left: 2px;
}
#cetelem-modal .modal-dialog {
  width: 980px;
  height: 95%;
}
#cetelem-modal .modal-content {
  height: 100%;
}
#cetelem-modal .modal-content .modal-body {
  height: 90%;
}
#cetelem-modal iframe {
  width: 100%;
  height: 100%;
  border: none;
}
#forgot-password-modal > .content {
  font-size: 2rem;
}
.pex-mounting-coupon-block {
  background-color: #f0f0f0;
  padding: 3rem 2rem;
  font-size: 2rem;
}
.pex-mounting-coupon-block .pex-button {
  background-color: #FFA500;
  font-weight: bold;
  display: inline-block;
  height: 5rem;
  font-size: 1.9rem;
  line-height: 3rem;
  padding: 1rem 2rem;
  border-radius: 2.5rem;
  color: #000;
  text-decoration: none;
}
.pex-mounting-coupon-block .pex-button .icon {
  font-weight: normal;
  font-size: 1.6rem;
}
html.desktop #ak-widget {
  z-index: 99998 !important;
}
html.desktop #ak-widget.akw-bottom-right {
  will-change: bottom;
}
html.desktop .cc_banner-wrapper {
  z-index: 99999;
  opacity: 0.95;
}
.hs-frame {
  position: relative;
  width: 100%;
  background-color: inherit;
}
.hs-frame .hs-button-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  z-index: 10;
  background-color: inherit;
}
.hs-frame .hs-button-wrapper .hs-shadow {
  height: 100%;
  width: 100%;
  position: relative;
  background-color: inherit;
}
.hs-frame .hs-button-wrapper .hs-button {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 4rem;
  height: 4rem;
  padding: 0;
  margin: 0;
}
.hs-frame .hs-button-wrapper .hs-shadow::before {
  content: "";
  position: absolute;
  z-index: -1;
  width: 1rem;
  top: 0.5rem;
  bottom: 0.5rem;
  border-radius: 5rem;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.3);
}
.hs-frame .hs-button-wrapper .hs-shadow::after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 1rem;
  top: 30%;
  bottom: 30%;
  border-radius: 5rem;
  box-shadow: 0 0 1.75rem rgba(0, 0, 0, 0.2);
}
.hs-frame .hs-button-wrapper.left {
  left: 0;
}
.hs-frame .hs-button-wrapper.left .hs-shadow::before {
  right: 0;
}
.hs-frame .hs-button-wrapper.left .hs-shadow::after {
  right: 0;
}
.hs-frame .hs-button-wrapper.right {
  right: 0;
}
.hs-frame .hs-button-wrapper.right .hs-shadow::before {
  left: 0;
}
.hs-frame .hs-button-wrapper.right .hs-shadow::after {
  left: 0;
}
.hs-frame .hs-items {
  white-space: nowrap;
  overflow: hidden;
  margin: 0 4rem;
  z-index: 0;
}
.hs-frame .hs-items .hs-item {
  white-space: normal;
  display: inline-block;
  position: relative;
  padding: 0 2%;
}
.hs-frame .hs-items .garage-list-app-icons .item {
  display: inline-block;
  padding-right: 10px;
}
.hs-frame .hs-items .garage-list-app-icons .item img {
  height: 50px;
}
.page-wheel-wizard .wheel-wizard {
  padding-top: 3rem;
}
.page-wheel-wizard .wheel-wizard .form-panel {
  padding-top: 3rem;
}
.page-wheel-wizard .wheel-wizard-steps .step {
  height: 33rem;
  display: block;
  border: 0.5rem solid #e5e5e5;
  border-radius: 1rem;
  text-align: center;
  color: #cccccc;
  padding: 1rem;
}
.page-wheel-wizard .wheel-wizard-steps .step .icon-wrapper {
  display: block;
  padding-top: 3rem;
}
.page-wheel-wizard .wheel-wizard-steps .step .icon-wrapper .icon.massive {
  font-size: 14rem !important;
}
.page-wheel-wizard .wheel-wizard-steps .step .label {
  display: block;
  font-size: 2rem;
  font-weight: bold;
  padding-top: 2rem;
}
.page-wheel-wizard .wheel-wizard-steps .step.completed {
  padding: 0;
}
.page-wheel-wizard .wheel-wizard-steps .step.completed .image-wrapper .image {
  margin: auto;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.page-wheel-wizard .wheel-wizard-steps .step.completed .label {
  font-weight: normal;
  padding: 1rem 1rem 0 1rem;
  font-size: 1.75rem;
  color: #262626;
  text-align: left;
}
.page-wheel-wizard .wheel-wizard-steps .step.completed .name {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
}
.page-wheel-wizard .wheel-wizard-steps .step.completed .sizes {
  font-size: 1.5rem;
  color: #666666;
  text-align: center;
}
.page-wheel-wizard .wheel-wizard-steps .step.completed .price {
  text-align: center;
  font-size: 2rem;
  line-height: 2.5rem;
  padding-top: 0.5rem;
  font-weight: bold;
}
.page-wheel-wizard .wheel-wizard-steps .step.completed .sub-label {
  display: block;
  padding: 0.5rem 1rem 0 1rem;
  text-align: left;
  text-transform: uppercase;
  font-size: 1.5rem;
  color: #666666;
}
.page-wheel-wizard .wheel-wizard-steps .step.active {
  border-color: #de3a3e;
  position: relative;
}
.page-wheel-wizard .wheel-wizard-steps .step.active .label {
  color: #de3a3e;
}
.page-wheel-wizard .wheel-wizard-steps .step.active .icon-wrapper .icon {
  color: #de3a3e;
}
.page-wheel-wizard .wheel-wizard-steps .step.active:after {
  position: absolute;
  bottom: -1.8rem;
  left: 50%;
  content: " ";
  width: 0;
  height: 0;
  border-style: solid;
  margin-left: -3rem;
  border-width: 1.5rem 3rem 0 3rem;
  border-color: #de3a3e transparent transparent transparent;
}
.page-wheel-wizard .wheel-wizard-steps .step.choose-rim,
.page-wheel-wizard .wheel-wizard-steps .step.choose-tyre {
  padding-top: 2rem;
}
.page-wheel-wizard .wheel-wizard-buttons .buttons {
  justify-content: center;
}
.page-wheel-wizard .wheel-wizard .car-form .select-field .dropdown.icon {
  padding: 1rem !important;
  line-height: 2.5rem;
  top: 2.5rem;
  right: 2.5rem;
}
.page-wheel-wizard #choose-rim-form-rims .rim {
  text-align: center;
  font-size: 2rem;
  cursor: pointer;
}
.page-wheel-wizard #choose-rim-form-rims .rim .inner {
  padding: 1rem;
  border: 0.4rem solid #ffffff;
}
.page-wheel-wizard #choose-rim-form-rims .rim .sizes {
  padding-top: 1rem;
  font-size: 1.75rem;
  white-space: nowrap;
}
.page-wheel-wizard #choose-rim-form-rims .rim .price {
  font-weight: bold;
  padding-top: 1rem;
}
.page-wheel-wizard #choose-rim-form-rims .rim.transition.hidden {
  display: none;
}
.page-wheel-wizard #choose-rim-form-rims .rim.selected .inner {
  border: 0.4rem solid #de3a3e;
}
.page-wheel-wizard #step-start {
  padding-top: 0;
}
.page-wheel-wizard #step-start .header-wrapper {
  color: #ffffff;
}
.page-wheel-wizard #step-start .header-wrapper .description-wrapper {
  position: relative;
}
.page-wheel-wizard #step-start .header-wrapper .description-wrapper .description {
  position: absolute;
  bottom: 0;
  padding: 3rem 3rem 18rem 3rem;
  font-size: 1.75rem;
}
.page-wheel-wizard #step-start .header-wrapper .description-wrapper .description .header {
  font-size: 6rem;
  font-weight: bold;
  text-transform: uppercase;
}
.page-wheel-wizard #step-start .steps-wrapper .steps {
  position: relative;
  top: -10rem;
}
.page-wheel-wizard #step-start .steps-wrapper .steps .wheel-wizard-steps .step {
  background-color: #ffffff;
}
.page-wheel-wizard #step-start .wheel-wizard-buttons {
  padding-bottom: 3rem;
}
.page-wheel-wizard #step-start .wheel-wizard-buttons .button {
  font-size: 4.5rem;
  padding: 2rem 2rem;
  border-radius: 1rem;
  text-align: left;
}
.page-wheel-wizard #step-choose-tyre #choose-tyre-form .tyre.transition.hidden {
  display: none;
}
.page-wheel-wizard #step-choose-tyre #choose-tyre-form .tyre.selected .product {
  border: 0.4rem solid #de3a3e;
}
.page-wheel-wizard #step-choose-tyre #choose-tyre-form .select-tyre-button .product {
  border-radius: 0;
  border: 0.4rem solid #ffffff;
}
.page-wheel-wizard #step-choose-tyre #choose-tyre-form .select-tyre-button .product.teaser:hover {
  box-shadow: none !important;
}
.page-wheel-wizard .wheel-wizard #add-to-cart-form {
  border: 0.5rem solid #e5e5e5;
  border-radius: 1rem;
  padding: 3rem;
}
.page-wheel-wizard .completed-form .header {
  font-size: 3rem;
  color: #de3a3e;
  border-bottom: 0.2rem solid #e46165;
  padding-bottom: 2rem !important;
}
.page-wheel-wizard .completed-form .coupon .price {
  color: #19a531;
  font-weight: bold;
}
.uvc-widget-container {
  display: block;
  position: fixed;
  top: 15%;
  right: 1rem;
  z-index: 10;
  width: 15rem;
  max-width: 90%;
}
.uvc-widget-container .uvc-widget {
  margin-top: 0 !important;
  max-width: 100%;
  padding: 1rem;
  padding-top: 4rem;
}
.uvc-widget-container .uvc-widget .uvc-header {
  background-color: transparent;
  width: 70%;
  height: 5rem;
  display: block;
  position: absolute;
  top: -1.5rem;
  left: 15%;
  background-image: url('../images/glasses.svg');
  background-repeat: no-repeat;
  background-position: center;
}
.uvc-widget-container .uvc-widget.message {
  border: 0;
  display: block;
  box-shadow: 0 0 2rem rgba(51, 51, 51, 0.5);
  font-weight: normal;
}
.uvc-widget-container .uvc-widget.message .counter-block {
  font-weight: bold;
  color: #de3a3e;
}
.uvc-widget-container .uvc-widget.message .uvc-counter {
  font-weight: bold;
  padding: 0 0.5rem;
}
body.product-page .product-image-wrapper .uvc-widget-container {
  position: absolute;
  top: 4rem;
  right: 4rem;
}
html.mobile-layout body.discount-page .content-discount .uvc-widget-container {
  position: initial;
  max-width: 100%;
  width: auto;
  margin: 4rem 0 2rem 0;
}
#newsletter-page .close,
#newsletter-subscribe-modal .close {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1.75rem;
  height: 3rem;
  padding: 0.75rem 0.5rem 0.5rem;
  width: 3rem;
  line-height: 2rem;
  background-color: #ffffff;
  color: #333333;
}
#newsletter-page .description-wrapper,
#newsletter-subscribe-modal .description-wrapper {
  padding-top: 3rem;
  padding-bottom: 6rem;
}
#newsletter-page .description-wrapper .description .title,
#newsletter-subscribe-modal .description-wrapper .description .title {
  font-size: 3rem;
  font-weight: normal;
}
#newsletter-page .description-wrapper .description .body,
#newsletter-subscribe-modal .description-wrapper .description .body {
  font-size: 2rem;
}
#newsletter-page .form-wrapper-panel,
#newsletter-subscribe-modal .form-wrapper-panel {
  background-color: #e5e5e5;
  margin-top: 10rem;
  margin-bottom: 1rem;
}
#newsletter-page .form-wrapper-panel .floating-icon-wrapper .floating-icon,
#newsletter-subscribe-modal .form-wrapper-panel .floating-icon-wrapper .floating-icon {
  position: absolute;
  top: -10rem;
}
#newsletter-page .form-wrapper-panel .floating-title-wrapper,
#newsletter-subscribe-modal .form-wrapper-panel .floating-title-wrapper {
  padding-bottom: 3rem !important;
}
#newsletter-page .form-wrapper-panel .floating-title-wrapper .floating-title,
#newsletter-subscribe-modal .form-wrapper-panel .floating-title-wrapper .floating-title {
  position: absolute;
  top: -10rem;
}
#newsletter-form {
  min-height: 15rem;
}
#newsletter-modal {
  padding: 1rem;
}
#newsletter-modal .form-block .checkbox-field .checkbox-label {
  height: auto;
}
#car-data-form .car-tyre-sizes-fields .field-label-wrapper {
  width: 100%;
}
#car-data-form .car-tyre-sizes-fields .field-label-wrapper .field-label {
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.5rem;
}
#car-data-form .car-tyre-sizes-fields .field-input-wrapper {
  width: 100%;
  margin-left: 0;
}
#car-data-form .car-tyre-sizes-fields .field-input-wrapper .field-input {
  border-bottom-left-radius: 0.5rem;
  border-top-right-radius: 0;
}
#car-data-form .question-box {
  background-color: rgba(255, 255, 255, 0.5);
  color: #333333;
}
#service-offer-request-page .ui.input.dropdown.field-input.selection .text {
  width: 90%;
  white-space: nowrap !important;
  overflow: hidden;
}
#service-offer-request-modal {
  border-radius: 25px;
  padding: 2rem;
}
#service-offer-request-modal .header {
  text-transform: uppercase;
  font-size: 3rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e5e5e5;
  font-weight: normal;
}
#service-offer-request-modal .content {
  font-size: 1.8rem;
}
#service-offer-request-modal .actions {
  text-align: center;
  border-top: 1px solid #e5e5e5;
  background: none;
}
#service-offer-request-modal .actions .button {
  text-transform: uppercase;
}
#dot-tyres-products-section-wrapper {
  min-height: 10rem;
}
#dot-tyres-modal {
  background-color: transparent;
  border-radius: 0.5rem;
}
#dot-tyres-modal > .content {
  padding: 0;
  border-radius: 0.5rem;
  background-color: transparent;
  border: 1px solid #333333;
}
#dot-tyres-modal > .content .form-block {
  margin-bottom: 0;
}
#dot-tyres-modal .field-input:disabled {
  color: #262626;
  font-weight: bold;
  opacity: 1;
}
#dot-tyres-modal .quantity-stepper-wrapper {
  padding-right: 0;
}
#dot-tyres-modal .quantity-stepper {
  position: relative;
  font-size: 1.4rem;
  height: 5rem;
}
#dot-tyres-modal .quantity-stepper .quantity {
  height: 5rem;
  border: none;
  text-align: right;
  padding: 0.5rem;
  padding-right: 5.5rem;
  font-weight: bold;
}
#dot-tyres-modal .quantity-stepper .quantity:focus {
  border-radius: 0.5rem;
}
#dot-tyres-modal .quantity-stepper .quantity-label {
  position: absolute;
  top: 0;
  right: 0;
  line-height: 5rem;
  padding-right: 3rem;
}
#dot-tyres-modal .quantity-stepper .quantity-label .buttons {
  width: 20px;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
}
#dot-tyres-modal .quantity-stepper .quantity-label .buttons .button {
  padding: 0;
  height: 2rem;
  line-height: 2.5rem;
  font-size: 2.5rem;
}
#dot-tyres-modal .quantity-stepper .quantity-label .buttons .button .icon {
  margin: auto;
  display: block;
  text-align: center;
  height: inherit;
  width: inherit;
}
#dot-tyres-modal .quantity-stepper .quantity-label .buttons .button.quantity-up {
  background-color: #cccccc;
  border-top-left-radius: 0;
  text-align: center;
}
#dot-tyres-modal .quantity-stepper .quantity-label .buttons .button.quantity-down {
  background-color: #b2b2b2;
  border-bottom-left-radius: 0;
}
.sidebar {
  margin-top: 4rem !important;
}
.sidebar .sidebar-block {
  margin-bottom: 3rem;
}
.sidebar .sidebar-block .header,
.sidebar .sidebar-block .sidebar-block-header {
  font-weight: normal;
  font-size: 2rem !important;
  border-bottom: 2px solid #e46165 !important;
}
.sidebar .sidebar-block .header a,
.sidebar .sidebar-block .sidebar-block-header a {
  color: #333333 !important;
}
.sidebar .sidebar-block .header a:hover,
.sidebar .sidebar-block .sidebar-block-header a:hover {
  color: #de3a3e !important;
}
.sidebar .sidebar-block .url-list .item,
.sidebar .sidebar-block .sidebar-block-menu .item {
  color: #333333 !important;
}
.sidebar .sidebar-block .url-list .item:hover,
.sidebar .sidebar-block .sidebar-block-menu .item:hover {
  color: #de3a3e !important;
}
.env-strip {
  position: fixed;
  z-index: 99999;
  right: 0;
  height: 100%;
  width: 3rem;
  background-color: #EC4E1C;
  color: #fff;
  writing-mode: vertical-lr;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  line-height: 2rem;
  padding: 0.5rem;
  opacity: 0.7;
}
.product-ratings-panel {
  width: 100%;
}
.product-ratings-panel .ui.segment {
  border: 0;
  border-radius: 0;
}
.product-ratings-panel .data-column {
  background-color: #333333;
  color: #ffffff;
  margin: 0;
}
.product-ratings-panel .data-column .rating-bottom {
  position: absolute;
  bottom: 1rem;
}
.product-ratings-panel .data-column .name {
  text-transform: uppercase;
  font-weight: bold;
}
.product-ratings-panel .data-column .empty-name {
  text-transform: uppercase;
  font-style: italic;
  color: #cccccc;
}
.product-ratings-panel .header {
  margin-bottom: 3rem;
}
.product-ratings-panel .span {
  display: inline-block;
}
.product-ratings-panel .rating-column {
  height: auto;
  background-color: #e5e5e5;
  margin: 0;
  padding: 1rem !important;
}
.product-ratings-panel .rating-column .rating {
  line-height: 2rem;
}
.product-ratings-panel .rating-column .rating-right {
  text-align: right;
}
.product-ratings-panel .comment-column {
  height: auto;
  background-color: #f0f0f0;
  margin: 0;
  padding: 1rem !important;
}
.product-ratings-panel .ui.button {
  width: 100%;
  margin: 0;
  padding: 1rem;
  margin-top: 1rem;
}
.product-ratings-panel .personal-rating {
  display: inline-block;
  padding-top: 2rem;
}
.rating {
  display: flex;
  justify-content: space-between;
  line-height: 3rem;
}
.rating .details {
  font-size: 2rem;
}
.rating .details .score {
  display: inline;
  font-size: 3rem;
  padding-inline: 1rem;
}
.rating .details .votes {
  font-size: 1.5rem;
}
.rating-stars {
  column-gap: 0.25rem;
  white-space: nowrap;
}
.rating-stars .icon {
  color: #b2b2b2;
  font-size: 1.5rem;
  width: 1.5rem;
  margin: 0;
}
.rating-stars .icon::before {
  width: 1.5rem;
  display: inline-block;
}
.rating-stars .filled {
  color: #ffb066;
}
.rating-stars .half-filled {
  position: relative;
}
.rating-stars .half-filled .half.star {
  position: absolute;
  left: 0;
}
.rating-stars .half-filled .half.star::before {
  position: absolute;
  left: 0;
  width: auto;
}
.score {
  display: inline-block;
  font-size: 1.5rem;
  width: 1rem;
}
.ui.grid.compact > .data-column {
  padding: 1rem !important;
}
.ui.grid.compact > .rating-column {
  padding: 1rem !important;
}
.ui.grid.compact > .comment-column {
  padding: 1rem !important;
}
