//
// product
//
.product {
    .product-name-wrapper {
        .manufacturer-name {
            font-weight: bold;
            color: @marso-gray-80;
        }

        .name {
            font-weight: bold;
            color: @marso-gray-80;
        }

        .tread-name {
            color: @marso-gray-50;
        }

        .size {
            color: @marso-gray-50;
        }

        .service-product-name {
            font-weight: bold;
        }

        &.big {
            .manufacturer-name {
                font-size: 2rem;
                line-height: 3rem;
            }

            .tread-name {
                font-size: 2rem;
                line-height: 3rem;
            }

            .size {
                font-size: 2rem;
                line-height: 3rem;
            }
        }

        &.huge {
            .manufacturer-name {
                font-size: 3.5rem;
                line-height: 4rem;
            }

            font-size: 2rem;
            line-height: 3rem;
        }
    }

    .storage-status {
        color: @marso-gray-50;
        font-size: 1.5rem;
        line-height: 2.5rem;
        position: relative;
        cursor: pointer;

        .icon {
            font-size: 2rem;
            line-height: 2.5rem;
        }

        &.small {
            font-size: 1.25rem;
            height: 2rem;
            line-height: 2rem;

            .icon {
                font-size: 1.5rem;
                line-height: 2rem;
            }
        }

        &.huge {
            font-size: 2rem;
            line-height: 3rem;

            .icon {
                font-size: 2.75rem;
                line-height: 3rem;
            }
        }
    }

    //  &.service {
    //    .service-attributes {
    //      font-size: 18px;
    //    }
    //  }
}

.product.teaser.rim {
    .product-name-wrapper.big {
        .size {
            font-size: 1.5rem;
        }
    }
}

.storage-status {
    &.in-stock>.icon {
        color: @marso-green;
    }

    &.low-stock>.icon {
        color: @marso-orange;
    }

    &.out-of-stock>.icon {
        color: @marso-red;
    }

    &.orderable>.icon {}

    &.request-to-stock>.icon {
        color: @marso-red;
    }
}

/* product page
*/
.product-main {
    padding-top: 3rem;

    .product-data-block {
        padding-left: 3rem;
        border-left: 1px solid @marso-gray-10;
    }

    .other-sizes {
        font-size: 1.4rem;
        float: right;
        text-transform: none;
        line-height: 3rem;

        .dropdown {
            border-radius: 0.5rem;
            margin-top: 0.6rem;
            padding: 0 0.5rem 0 1rem;
            color: @marso-gray-50;
            background-color: @marso-gray-10;

            .default.text {
                color: @marso-gray-80;
            }
        }
    }

    .image-illustrative-notice {
        position: absolute;
        right: -100%;
        margin-right: 1rem;
        bottom: 0;
        width: 100%;
        font-size: 1.25rem;
        transform: rotate(-90deg);
        transform-origin: left top;
    }

    .product-image-wrapper {
        padding: 4rem;

        >.image-link {
            display: block;
            text-align: center;
        }
    }

    .product-meta-wrapper {
        .product-tyre-data-wrapper {
            padding-top: 2rem;
        }

        .product-tyre-data {
            border-radius: 0.5rem;
            border: 1px solid @marso-gray-20;

            .column {
                padding: 0.5rem 0 !important;
                height: 5rem;

                .wrapper {
                    height: 4rem;
                    border-left: 1px solid @marso-gray-20;
                    padding: 0.5rem 1.5rem;
                    line-height: 3rem;
                    text-align: center;
                    white-space: nowrap;

                    .icon {
                        color: @marso-gray-50;
                        vertical-align: middle;
                        font-size: 2rem;
                    }

                    .value {
                        color: @marso-gray-50;
                        display: inline-block;
                        vertical-align: middle;
                    }
                }
            }

            .column:first-child {
                .wrapper {
                    border-left: none;
                }
            }
        }

        .product-tags-wrapper {
            padding-top: 1.5rem;

            .label {
                margin-bottom: 0.75rem;
            }
        }
    }

    .add-to-cart-button.ui.button {
        font-size: 3rem;
    }

    .product-tags-wrapper {
        padding-bottom: 1rem;

        .label {
            margin-bottom: 0.75rem;
        }
    }
}

.product-price-wrapper {
    padding-top: 2rem;

    .original-price {
        font-size: 20px;
        text-decoration: line-through;
    }
    .product-price {
        padding-top: 1rem;

        .retail-price-brutto {
            font-size: 5rem;
            line-height: 5rem;
            font-weight: bold;
        }

        .retail-price-brutto.highlighted {
            color: @marso-red;
        }

        .retail-price-brutto.small {
            font-size: 2rem;
        }

        .retail-price-netto {
            padding-top: 1rem;
        }
    }

    .product-price.dismissed {
        text-decoration: line-through;
    }
    .product-price.highlighted {
        color: @marso-red;
    }
}

.product-cart-wrapper {
    padding-top: 2rem;

    .total-price {
        font-size: 1.6rem;

        .label {
            padding: 0 0.5rem 0 1em !important;
            line-height: 4rem;
            font-weight: normal;
            background-color: @marso-gray-10;
        }

        input {
            padding: 0 1rem !important;
            //padding-left: 0.5rem;
            font-weight: bold;
        }
    }

    .add-to-cart-button.ui.button {
        color: @white;
        //padding: 1.5rem 0.5rem !important;
    }

    .quantity-wrapper {
        .quantity-stepper-wrapper {
            .quantity-stepper {
                font-size: 1.6rem;
                width: 100%;
            }

            input {
                background-color: @marso-gray-10  !important;
                border-color: @marso-gray-10  !important;
            }

            .product-quantity-input {
                font-weight: bold;
                text-align: right;
                height: 6rem;
                padding: 0.5rem 1rem;
                width: 5rem;
                max-width: 5rem;
                font-size: 2.5rem;
            }

            .product-quantity-input:focus {
                outline: none;
                border-color: @marso-gray-10  !important;
                border-top-left-radius: 0.5rem;
                border-bottom-left-radius: 0.5rem;
            }

            .quantity-label {
                position: relative;
                padding: 0.5rem 3rem 0.5rem 0 !important;
                line-height: 5rem;
                font-weight: normal;
                background-color: @marso-gray-10;
                width: 6rem;
                max-width: 6rem;
                font-size: 2rem;

                .buttons {
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 3rem;
                    padding: 0;

                    .button {
                        height: 3rem;
                        width: 3rem;
                        padding: 0;
                        background-color: @marso-gray-30;
                        font-weight: bold;

                        .icon {
                            margin: 0;
                            line-height: 3rem;
                            width: 3rem;
                            font-size: 3rem;
                        }

                        &.quantity-up {
                            background-color: @marso-gray-20;
                            border-top-left-radius: 0;
                            text-align: center;
                        }

                        &.quantity-down {
                            border-bottom-left-radius: 0;
                        }
                    }
                }
            }
        }
    }
}

//
// products
//
.products {
    .product.teaser {
        display: flex;
        flex-flow: column;
        height: 100%;
        padding: 1rem;
        color: @marso-gray-80;
        position: relative;

        &:hover {
            box-shadow:
                0px -1px 1px 3px fade(@marso-gray-50, 30%),
                0px 1px 1px 2px fade(@marso-gray-50, 30%) !important;
            border-radius: 1rem;
        }

        .image-wrapper {
            background-color: transparent;
            max-height: 155px;
            height: 155px;
            overflow: hidden;
            position: relative;

            .image {
                margin: auto;
            }

            &:before {
                content: "";
                display: block;
                position: relative;
                border: 1px solid @marso-gray-80;
                background-color: @marso-gray-80;
                border-radius: 99rem;
                height: 5px;
                top: 150px;
                z-index: 1;
            }
        }

        .data-wrapper {
            flex: 1;
            padding: 0;
            border-top: none;

            >.grid {
                height: 100%;
            }

            .product-name-wrapper {
                padding-top: 0.5rem !important;
            }

            .product-storage-status-wrapper {
                padding: 0.75rem 0;
                border-bottom: 2px solid @marso-gray-10;
            }

            .product-data-attribute-icons-wrapper {
                padding: 0.75rem 0;
                border-bottom: 2px solid @marso-gray-10;
            }

            .product-price-wrapper {
                padding: 1rem 0;
                flex: 1;

                .product-price {
                    font-weight: bold;
                    font-size: 3rem;
                }
                .product-price.dismissed {
                    font-size: 2.25rem;
                }
            }

            .vertical-data {
                padding-left: 1.5rem !important;

                >.grid {
                    height: 100%;
                }
            }

            .product-tyre-data-wrapper {
                background-color: @marso-gray-06;
                border-bottom-left-radius: 0.5rem;

                .grid {
                    .wrapper {
                        .icon {
                            color: @marso-gray-40;
                            font-size: 2.4rem;
                            line-height: 2.5rem;
                        }
                    }

                    .column:first-child {
                        .wrapper {
                            border-left: 0;
                            border-top: 0;
                        }
                    }
                }

                .horizontal {
                    .column {
                        .wrapper {
                            margin: 4px 4px 4px 0;
                            padding: 0 0 0 4px;
                            height: 42px;
                            border-left: 1px solid @marso-gray-20;
                            text-align: center;

                            .icon {
                                margin-right: 0;
                            }
                        }

                        .value {
                            display: block;
                            line-height: 15px;
                            font-size: 1.3rem;
                        }

                        &.noise {
                            .value {
                                white-space: nowrap;
                            }
                        }
                    }
                }

                .vertical {
                    //height: 100%;
                    min-height: 18rem;

                    .column {
                        height: 25%;

                        .wrapper {
                            height: 100%;
                            border-top: 1px solid @marso-gray-20;
                            margin: 0 1rem;
                            padding: 1rem 0;
                            color: @marso-gray-40;
                            white-space: nowrap;

                            .icon {
                                vertical-align: middle;
                                //float: left;
                                //font-size: 1.8rem;
                                //line-height: 3rem;
                            }

                            .value {
                                vertical-align: middle;
                                font-size: 1.75rem;
                                line-height: 2rem;
                                //float:right;
                            }
                        }

                        &.season {
                            .icon {
                                margin-right: 0;
                            }
                        }

                        &.noise {
                            .icon {
                                max-width: 40%;
                                font-size: 2.2rem;
                            }

                            .value {
                                font-size: 1.5rem;
                            }
                        }
                    }
                }
            }
        }

        .button-wrapper {
            padding: 1rem 0 0;
            border-top: none;
            flex: inherit;
        }

        &.tyre {}

        &.truck_tyre {
            .data-wrapper {
                .product-tyre-data-wrapper {
                    .vertical {
                        .column {
                            height: 33%;

                            .wrapper {

                                .icon,
                                .value {
                                    //line-height: 40px;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.service {
            .image-wrapper {
                position: relative;

                //background-color: @marso-gray-10;
                .image {
                    //max-width: 200px;
                }

                .name {
                    width: 100%;
                    position: absolute;
                    bottom: 10px;
                    font-weight: bold;
                    color: @white;
                    text-shadow: 1px 1px 2px fade(@marso-gray-85, 95%),
                        -1px -1px 2px fade(@marso-gray-85, 90%);
                }
            }

            .data-wrapper {
                height: 100%;
                background: linear-gradient(to right,
                        @marso-gray-10 18.75%,
                        @white 0px);

                .grid {
                    height: 100%;
                }

                .icon-wrapper {
                    color: @marso-gray-30;
                    margin-right: 0;

                    .icon {
                        margin-right: 0;
                    }
                }

                .description-wrapper {
                    padding: 0.5rem 0.5rem 0 0.5rem !important;
                }
            }
        }

    }
}

.products {
    .teaser.wide {
        .image-wrapper {
            max-height: 205px;
            height: 205px;

            &:before {
                top: 200px;
            }
        }
    }
}

/*
add to cart modal */
#add-to-cart-modal {
    border-radius: 25px;

    >.content {
        padding: 2rem;
        border-radius: inherit;
    }

    .header {
        text-transform: uppercase;
        font-size: 3rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid @marso-gray-10;
    }

    .footer {
        padding-top: 1rem;
        border-top: 1px solid @marso-gray-10;

        .question-box {
            color: @marso-gray-80;
            position: relative;
            border-top-left-radius: 7rem;
            border-bottom-left-radius: 7rem;
            background-color: @marso-gray-10;
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
            height: 11rem;

            .image {
                position: absolute;
                left: 0;
                top: 0;
                height: 11rem;
                line-height: 11rem;
                width: 11rem;
                display: block;
                background-color: @marso-green;

                .icon {
                    font-size: 9rem;
                    color: @white;
                    line-height: 11rem;
                }
            }

            .yes-or-no {
                min-width: 25rem;
                height: auto;

                .button {
                    text-transform: uppercase;
                    font-size: 2.2rem;
                    padding: 1.5rem 2.5rem;
                    margin: 0 0.8rem;
                }
            }

            .question {
                text-transform: uppercase;
                font-size: 2.5rem;
                margin-left: 0;
                padding-left: 12rem;
            }
        }

        .goto-cart-url {
            margin: auto;
            width: 20rem;
            text-align: center;
            display: block;
            padding: 2rem 0 0;
            text-transform: uppercase;
            color: @textColor;
            height: 4rem;
            line-height: 2rem;
            font-size: 1.5rem;

            .icon {
                font-size: 1.4rem;
                line-height: 2rem;
                height: 2rem;
                vertical-align: middle;
            }
        }
    }

    .product-price-wrapper {
        text-align: right;
        font-size: 2.5rem;
        line-height: 1.5em;

        .price-label {
            font-size: 1.5rem;
            line-height: 1.5rem;
        }

        .total-price-brutto {
            font-weight: bold;
        }

        .product-amount {
            padding-top: 1rem;
            padding-bottom: 1rem;

            .value {
                font-weight: bold;
            }
        }
    }
}

.product-descriptions {
    .tab-menu-panel {
        padding-top: 8rem;
    }
}

.product-data-attribute-icons-wrapper {
    .product-attribute-icon.icon {
        color: @white;
        background-color: @marso-gray-50;
        box-shadow: none !important;
        width: 3rem !important;
        height: 3rem !important;
        font-size: 2rem;
        line-height: 2rem !important;
        padding: 0.5rem !important;
    }

    .product-attribute-icon.big {
        float: left;
        clear: left;
        font-size: 3rem;
        line-height: 3rem !important;
        padding: 1rem !important;
        width: 5rem !important;
        height: 5rem !important;
        margin-bottom: 1rem;
    }
}

.product-main {
    .product-data-attribute-icons-wrapper {
        position: absolute;
        z-index: 50;
    }
}


.status-sold-out {
    .sold-out {
        position: absolute !important;
        z-index: 9;
        left: 0;
        top: 40%;
        transform: rotate(325deg);
        width: 100%;
        text-align: center;
        color: @marso-red;
        font-weight: bold;
        font-size: 8rem;
        text-transform: uppercase;
        opacity: 0.9;
    }

    &.teaser {
        .sold-out {
            font-size: 3.5rem;
        }
    }

    &.wide {
        .sold-out {
            font-size: 4rem;
        }
    }
}


.product-landing {

    position:relative;
    margin-top:0 !important;

    .product-box-wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .product-box {
        font-size: 1em; // MAIN SIZER

        position: sticky;
        left: 9em;
        top: 10em;
        width: 33em;
        height: auto;
        background-color: white;
        border-radius: 0.5em;
        margin: 2em;
        box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        float: left;


        .product-price-wrapper {
            padding-top: 0rem;
        }

        .product-main {
            padding-top: 0;
            padding-bottom: 3rem;
        }

        .product-main .ui.grid {
            margin: -1.8rem;
        }

        .product-image {
            width:max-content;
            height: 50%;
            // clip-path: polygon(0 0, 100% 0, 100% 50%, 0 50%);
        }

        .product-image-wrapper {
            top: 2rem;
            height: 50%;
            width: 100%;
            display: inline-block;
            box-sizing: content-box;
            padding: 0 !important;
        }

        .manufacturer-name {
            text-transform: uppercase;
        }


        .product-cart-wrapper {
            padding-right: 1rem;
        }

        .product.teaser .image-wrapper {
            height: 231px;
            max-height: 231px;
        }

        .products .product.teaser .image-wrapper:before {
            position: relative;
            border: none !important;
            border-bottom: 1px solid @marso-gray-70 !important;
            background-color: @marso-gray-70 !important;
            border-radius: 0rem;
            height: 1px;
            top: 230px;
            z-index: 1;
            width: 94%;
            left: 3%;
        }

        .product-price-wrapper .product-price {
            padding-top:0rem !important;
        }

        .product-price-wrapper .product-price .retail-price-netto {
            padding-top:0rem !important;
        }

        .product-data-attribute-icons-wrapper .product-attribute-icon.big {
            float: left;
            clear: left;
            font-size: 2em;
            line-height: 1em !important;
            padding: 0.2em !important;
            width: 1em !important;
            height: 1em !important;
            margin-bottom: 0.2em;
        }

        .status-sold-out .sold-out {
            top: 61%;
            font-size: 4em;
        }

        .product-main .product-data-attribute-icons-wrapper {
            left: 2em;
            top: 6em;
        }


        @media (max-width: 1560px) {

            top: 6em !important;
            left: 5em !important;

            .product-box-data {
                margin-top: -2em !important;
            }

        }
    }

    .product .product-name-wrapper.huge .manufacturer-size {
        font-size:3.5rem;
    }

    .product-main .product-data-block {
        border-left: none;
        padding-right: 3rem;
    }

    .products .product.teaser:hover {
        box-shadow:none !important;
    }

    .product-box .products .product.teaser .image-wrapper.image {
        padding-top:3rem;
    }

    .product-box .products .product.teaser .info-labels-wrapper {
        box-sizing: border-box;
        padding: 0 1rem;
    }

    .product-box .product-main .other-sizes {
        margin-top: 0.56rem;
    }


    .product-manufacturer-logo {
        height: 6rem;
        left: 1.2rem;
        top: 1.2rem;
        position: absolute;
        display: flex;
        align-items: center;
        overflow: hidden;
        width: 161px;
        justify-content: center;
        z-index:2;
    }
    .product-manufacturer-logo img {
        width:20rem;
    }

    .extra-content-container-wrapper {

        display: grid;
        grid-template-columns: 70rem 1fr;

        .type-video,.type-image,.type-text {
            padding:0px !important;
        }

        .type-text {
            font-family:'Exo 2', 'Lato', Arial, Helvetica, sans-serif;
            font-size: 24.0px;
            color: rgba(0,0,0,1.0);
            display: flex !important;
            align-items: center;
            justify-content: flex-start;
            padding: 2rem !important;
        }

        .type-text-body {
            width: 100%;
            box-sizing: border-box;
            padding: 2rem;
        }

        .type-image-body {
            margin-bottom: -5px;
        }

        .type-image-body img {
            width: 100%;
        }

        .extra-content-container {
            background-color:#ececec;
            margin-bottom:1rem;
        }

        .main-grid {
            margin:0px !important;
        }

        .extra-content-container{
            width:100%;
        }

        .ui.grid.main-grid {
            grid-column: 2;
        }
        .ui.grid>.column:not(.row) {
            padding-top: 0rem !important;
            padding-bottom: 0rem !important;
        }

    }


    .type-background-image-body {
        position: static;
        width:100%;
        z-index:-1;
    }

    .type-background-image-body img {
        width:100%;
        height:auto;
        display:block;
    }


    .video-box-embed {
        .video-container {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 56.25%;
        }
        .video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .video-background-wrapper {

        #video-background-embed {
            height:max-content;
            margin-bottom:1rem;
        }

        #video-background .youtube-background > iframe {
            transition: opacity 500ms ease-in-out;
            transition-delay: 3000ms;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        #video-background .buttons {
            position: absolute;
            right: 1%;
            top: 1%;
            z-index: 1;
        }
        #video-background .button {
            padding: 1rem;
            text-align: center;
            font-size: 2rem;
        }
        #video-background .buttons .button .icon {
            margin-right: 0;
        }
        #video-background #toggle-video-volume.on .up,
        #video-background #toggle-video.on .pause {
            display: inline-block;
        }
        #video-background #toggle-video-volume.on .off,
        #video-background #toggle-video-volume .up,
        #video-background #toggle-video .pause,
        #video-background #toggle-video.on .play {
            display: none;
        }

        #video-background {
            position: static !important;
        }

        #video-background .youtube-background {
            position:inherit !important;
            width: 100%;
            margin:0;
            overflow: inherit !important;
        }

        #video-background-embed .separator-line {
            height: 5px;
            width: 100%;
            opacity: 1.0;
        }

    }

}

.product .rating {
    display: inline;
    text-align: center;

    .icon {
        font-size: 3rem;
        width: 3rem;
    }

    .icon::before {
        width: 3rem;
    }
}

.product .rating.small {

    .icon {
        font-size: 2rem;
        width: 2rem;
    }

    .icon::before {
        width: 2rem;
    }

    .details {

        .score {
            font-size: 1.75rem;
            padding-inline: 0;
        }
    }
}
